.o-colorpicker {
  .react-colorful {
    width: 312px;
    height: 312px;
    overflow: hidden;
  }
  .react-colorful__pointer {
    width: 20px;
    height: 20px;
  }
  .react-colorful__saturation {
    border-bottom: 0;
    margin-bottom: 20px;
  }
  .react-colorful__last-control {
    border-radius: 16px;
  }
  .react-colorful__hue {
    width: 80%;
    margin-left: 10%;
  }
  .react-colorful__saturation {
    border-radius: 0;
  }
}