/**
 * File-input object
 */

.o-file-input {
  overflow: hidden;
}

.o-file-input--basic {
  position: relative;

  input {
    position: absolute;
    inset: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 2;
    margin: 0;
  }
}

.o-file-input__wrapper {
  align-items: center;
  background-color: map-get($colors, stable-lighter);
  border-radius: $border-radius;
  display: flex;
  min-height: $input-height;
  position: relative;
  width: calc(100% - 130px);

  &:before {
    content: '';
    background-color: map-get($colors, stable-lighter);
    border-radius: $border-radius;
    width: 15px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
  }

  &:after {
    @extend .o-button;
    @extend .o-button--secondary;
    content: 'Browse';
    position: absolute;
    left: calc(100% + 10px);
    top: 0;
    width: auto;
  }
}

.o-file-input__wrapper input {
  @include paragraph;
  color: map-get($colors, dark);
  margin: 0 0 0 -82px;

  &.is-invalid-input {
    background-color: map-get($colors, stable-light);
  }

  &:focus {
    outline: none;
  }
}

.o-file-input__wrapper input + .form-error {
  position: relative;
  top: 10px;
  right: 10px;
  width: 150px;
}

//Styling for Firefox
@-moz-document url-prefix() {
  .o-file-input__wrapper input {
    margin: 0 0 0 -80px;
  }
}

//Styling for Edge
@supports (-ms-ime-align: auto) {
  .o-file-input__wrapper input {
    background-color: transparent;
    margin: 0 0 0 15px;

    &::-ms-browse {
      display: none;
    }

    &::-ms-value {
      background-color: map-get($colors, stable-light);
      border: none;
    }
  }
}

::-webkit-file-upload-button {
  cursor: pointer;
}
