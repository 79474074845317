.o-draggable__list--container > div {
  position: absolute;
  display: inline-flex;
  align-items: center;
  height: 50px;
  width: 100%;
  user-select: none;
  transform-origin: 50% 50% 0px;
  border: 1px solid map-get($colors, stable-light);
  color: map-get($colors, dark-light);
  background-color: white;
  font-size: 14.5px;
  touch-action: none;
  border-radius: 5px;
}
.o-draggable__list--prefix {
  padding-left: 20px;
  display: grid;
  font-size: 20px;
  color: map-get($colors, stable);
  margin-right: 10px;
}
.o-draggable__list--container {
  position: relative;
  user-select: none;
  width: 100%;
}
.o-draggable__list--item {
  user-select: none;
  flex-grow: 1;
}
.o-draggable__list--suffix {
  cursor: pointer !important;
  display: flex;
  position: relative;
  color: map-get($colors, stable);
  justify-self: flex-end;
  align-content: center;
  justify-content: center;
  z-index: 3;
  transition: color 500ms ease-in-out;
  &:hover {
    color: map-get($colors, assertive);
    transition: color 500ms ease-in-out;
  }
}
