/**
 * Segments component
 */

.c-segments {
  border: 1px solid map-get($colors, stable-light);
  border-radius: $border-radius;
  display: inline-flex;
  overflow: hidden;
  white-space: nowrap;
}

/**
 * Item element
 */

.c-segments__item {
  border-right: 1px solid map-get($colors, stable-light);
  height: $input-height - 2px;
  display: flex;
  align-content: center;
  justify-content: center;

  &:first-child {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    margin-left: 0;
  }

  &:last-child {
    border-right: none;
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

.c-segments__item:hover span {
  @include transition(background-color .3s $smooth-ease, color .3s $smooth-ease);
  color: map-get($colors, dark);
  background-color: map-get($colors, light);
  cursor: pointer;
}

.c-segments__item span {
  @include paragraph;
  background-color: map-get($colors, stable-lightest);
  display: inline-block;
  line-height: $input-height - 2px;
  padding: 0 15px;
}

.c-segments__item span svg path{
  fill: map-get($colors, stable-dark);
}

.c-segments__item input {
  position: absolute;
  top: -100px;
  left: -100px;
  opacity: 0;
}

.c-segments__item input:checked + span {
  background-color: map-get($colors, light);
  color: map-get($colors, dark);
}

.c-segments__item input:focus + span {
  color: map-get($colors, assertive);
}

.c-segments__item input:checked + span svg path {
  fill: map-get($colors, dark);
}

.c-segments__item input:focus + span svg path {
  fill: map-get($colors, assertive);
}
