/**
 * Logo object
 */
.o-logo__container {
  display: flex;
  justify-items: center;
  align-items: center;
  height: 100%;
  padding-right: 20px;
  border-right: 1px solid map-get($colors, 'stable-light');
}
.o-logo {
  background-image: url('../../images/logo.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 14px;
  overflow: hidden;
  text-indent: -9999px;
  white-space: nowrap;
  width: 41px;
}
.o-logo__link {
  text-decoration: none;
  color: black;
  &:hover,
  &:active,
  &:visited {
    text-decoration: none;
    color: black;
  }
}
