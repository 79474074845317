.c-progress {
  background: map-get($colors, stable-light);
  height: 4px;
  border-radius: 4px;
  display: block;
}

.c-progress__bar {
  background: map-get($colors, assertive);
  height: 4px;
  display: block;
  border-radius: 4px;
  max-width: 100%;
}

.c-progress__completed {
  width: 100%;
  background: map-get($colors, energized);
}
