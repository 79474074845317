/**
 * Video component
 */

/**
 * Wrapper element
 */

.c-video__editor--layout {
  @include breakpoint(large up) {
    overflow: hidden;
  }
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr;
}

.c-video__editor--header {
  @include breakpoint(small up) {
    & .small-up {
      height: 140px;
      display: flex;
      width: 100%;
      background-color: white;
      justify-content: space-between;
      flex-direction: column;
      border-bottom: 1px solid map-get($colors, stable);
      & .top-row {
        padding: 0 30px;
        height: 60px;
        align-items: center;
        width: 100%;
        display: inline-flex;
        justify-content: space-between;
        border-bottom: 1px solid map-get($colors, stable);
      }
      & .bottom-row {
        padding: 0 30px;
        height: 80px;
        align-items: center;
        width: 100%;
        display: inline-flex;
        justify-content: space-between;
        border-bottom: 1px solid map-get($colors, stable);
      }
    }
    & .xlarge-up {
      display: none;
    }
  }
  @include breakpoint(xlarge up) {
    & .small-up {
      display: none;
    }
    & .xlarge-up {
      display: flex;
      width: 100%;
      height: 80px;
      background-color: white;
      justify-content: space-between;
      padding: 0 30px;
      align-items: center;
      border-bottom: 1px solid map-get($colors, stable);
    }
  }
  & .back-button {
    display: inline-flex;
    height: 100%;
    flex-shrink: 1;
  }
  & .center-piece {
    display: inline-flex;
    height: 100%;
  }
}

.c-video__editor--container {
  display: flex;
  flex: 1;
}

// Player
.c-video__editor--player {
  position: relative;
  flex: 0 1 100%;
  padding: 0 20px;
  @include breakpoint(large up) {
    padding: 0px;
    background-color: black;
  }
  height: 100%;
  display: grid;
  place-items: center;
  z-index: 1000;
}
.c-video__editor--player--video {
  width: 100%;
  margin: 0 auto;
  max-height: calc(100vh - 323px);
  aspect-ratio: 2/1;
  overflow: hidden;
  & .min-h {
    min-height: 250px;
  }

  .video-element--container {
    display: block;
    aspect-ratio: 2/ 1;
  }

  &.c-video__editor--player--no-video {
    max-height: 100%;
    height: 100%;
    .video-element--container {
      height: 100%;
    }
  }
}
.c-video__editor--player--video .c-video__editor--loading--page {
  display: grid;
  place-items: center;
  background-color: #111;
  color: white;
  width: 100vw;
  height: 100vh;
}

.c-video__editor--main {
  flex-grow: 1;
  flex-direction: column;
  align-items: start;
  @include breakpoint(large up) {
    display: grid;
    grid-template: 1fr auto / 1fr auto;
  }
}

.c-video__editor--sidebar {
  @include breakpoint(large up) {
    display: flex;
    flex-direction: column;
    width: 540px;
    grid-column: 2;
    grid-row: 1 / 3;
    border-left: 1px solid map-get($colors, stable);
  }
  
    .scroll-container {
      height: calc(100vh - 80px);
      overflow: auto;
      max-height: calc(100vh - 80px);
    }
}
.c-video__editor--elements--container {
  margin: 10px 0;
  &:first-of-type {
    margin-top: 0;
  }
}
.c-video__editor--elements--icon {
  cursor: pointer;
  color: map-get($colors, stable);
  &:hover {
    transition: color 300ms ease-in-out;
    color: map-get($colors, stable-dark);
  }
}
.c-video__editor--elements--select {
  padding: 5px 15px;
  background-color: map-get($colors, gray-background);
  transition: all 300ms ease-in-out;
  font-size: 14px;
  min-height: 30px;
}
.c-video__editor--elements--card {
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid map-get($colors, stable-light);
  transition: all 300ms ease-in-out;
  &.active {
    border: 1px solid map-get($colors, royal) !important;
    outline: 1px solid map-get($colors, royal);
    & * > .c-video__editor--elements--heading {
      color: map-get($colors, royal);
    }
  }
}
.c-video__editor--elements--card--answer-wrapper {
  &:first-child {
    border-top: 1px solid map-get($colors, stable-light);
  }
}
.c-video__editor--elements--card--answer-row {
  &:first-of-type {
    border-top: 1px solid map-get($colors, stable-light);
  }
  &:not(:last-of-type) {
    border-bottom: 1px solid map-get($colors, stable-light);
  }
  padding: 30px;
  & * > .map-marker,
  & * > .edit-button {
    border: 1px solid map-get($colors, stable-light);
    border-radius: 5px;
    padding: 5px 12px 2px;
    font-weight: 700;
    transition: all 300ms ease-in-out;
    color: map-get($colors, stable-dark);
    opacity: 0;
  }
  & .map-marker {
    margin-left: 20px;
    cursor: pointer;
  }
  & .edit-button {
    margin-left: 10px;
    cursor: pointer;
  }
  transition: all 300ms ease-in-out;
  &:hover {
    & .map-marker,
    & .edit-button {
      transition: all 300ms ease-in-out;
      opacity: 1;
      font-weight: 700;
    }
  }
  &.active {
    color: map-get($colors, royal);
  }
}
.c-video__editor--elements--card--content {
  padding: 30px;
  & * > .map-marker,
  & * > .edit-button {
    border: 1px solid map-get($colors, stable-light);
    border-radius: 5px;
    padding: 5px 12px 2px;
    font-weight: 700;
    transition: all 300ms ease-in-out;
    color: map-get($colors, stable-dark);
    opacity: 0;
  }
  & .map-marker {
    margin-left: 20px;
    cursor: pointer;
  }
  & .edit-button {
    margin-left: 10px;
    cursor: pointer;
  }
  transition: all 300ms ease-in-out;
  &:hover {
    & .map-marker,
    & .edit-button {
      transition: all 300ms ease-in-out;
      opacity: 1;
      font-weight: 700;
    }
  }
  &.active {
    color: map-get($colors, royal);
  }
}
.c-video__editor--elements--card--footer {
  padding: 10px 30px;
  border-top: 1px solid map-get($colors, stable-light);
}
.c-video__editor--elements--settings {
  padding: 30px;
  background-color: map-get($colors, gray-background);
  border-radius: 5px;
}
.c-video__editor--elements--settings--row {
  display: flex;
  align-items: center;
  &:not(:last-of-type) {
    margin-bottom: 8px;
  }
  &.switch-settings {
    padding: 5px 0px;
  }
}
.c-video__editor--elements--heading {
  display: inline-flex;
  align-items: center;
}
.c-video__editor--textarea {
  margin: 0;
  margin-bottom: 10px;
  resize: vertical;
  overflow: auto;
  min-height: 4em;
  max-height: 15em;
  &:hover {
    border-radius: 5px;
  }
  &:focus {
    background-color: #eee;
  }
}
.c-video__editor--bottom-controls {
  margin: 0 30px;
  margin-bottom: 20px;
}
.c-video__editor--bottom-controls--sliders {
  max-width: 1140px;
  margin: 0 auto;
}
.c-video__editor--slider-row {
  height: 60px;
  align-items: center;
  border-top: 1px solid map-get($colors, stable);
  &--large {
    align-items: center;
    border-top: 1px solid map-get($colors, stable);
    margin: 20px 0;
  }
}

.c-video__wrapper {
  position: relative;
  overflow: hidden;
}

.c-video__wrapper video {
  object-fit: fill;
  display: block;
  width: 808px;
  height: 404px;
}

.c-panel--video-fix {
  width: 870px;
}

.c-modal__content .c-video__description {
  padding-right: 100px;
}

/**
 * Aside element
 */

.c-video__aside {
  @include paragraph;
  color: map-get($colors, assertive);
  position: absolute;
  right: 0;
  top: 0;

  svg path {
    fill: map-get($colors, assertive);
  }
}

/**
 * Row element
 */

.c-video__row {
  border-bottom: 1px solid map-get($colors, stable-light);
  padding-bottom: $gutter * 0.5;

  &:last-child {
    border-bottom: 0;
  }
}

/**
 * Button element
 */

.c-video__button {
  @include transition(opacity 0.3s $smooth-ease);
  display: inline-block;
  background-color: trasnparent;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid map-get($colors, stable-light);
  border-radius: $border-radius;
  height: $input-height;
  outline: 0;
  overflow: hidden;
  text-indent: -9999px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.c-video__button--play {
  background-image: url('../../images/icon-play.svg');
  margin-right: 5px;
  min-width: 85px;
}

.c-video__button--pause {
  background-image: url('../../images/icon-pause.svg');
  margin-right: 5px;
  min-width: 85px;
}

.c-video__button--sound {
  background-image: url('../../images/icon-sound.svg');
  min-width: 42px;
}

.c-video__button--mute {
  background-image: url('../../images/icon-mute.svg');
  min-width: 42px;
}
.c-video__input--north-offset {
  padding: 0 10px;
  margin-bottom: 0;
}
.c-video__button--north-offset {
  width: 30px;
  height: 30px;
  &.with-margin {
    margin: 0 10px;
  }
}
.c-video__north-offset--container {
  width: 100%;
  height: 14px;
}
.c-video__north-offset {
  color: map-get($colors, stable-dark);
  width: 14px;
  height: 14px;
  position: relative;
  top: -407px;
}
.c-video__north-offset--line {
  position: relative;
  background-color: rgba(0, 0, 0, 0.4);
  height: 404px;
  display: flex;
  width: 1px;
  top: -404px;
}
.c-video__controls--input {
  margin: 0 10px;
  width: 60px;
  text-align: center;
  height: 30px;
}
.c-video__controls--time-input {
  margin: 10px 2px;
  width: 50px;
  text-align: center;
  height: 30px;
}
.c-video__controls--row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10px;
  &.info-row {
    margin-bottom: 0;
    justify-content: space-between;
    &:last-child {
      margin-bottom: 10px;
    }
  }
  &.time-control {
    justify-content: space-between;
  }
}
.c-video__controls--col {
  display: flex;
  flex-direction: column-reverse;
}
.c-video__controls--info {
  @include paragraph;
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 2px;
  color: map-get($colors, stable);
}
.c-video__controls--slider {
  display: flex;
  flex-grow: 1;
  width: 100%; // required for Firefox
  & input[type='range'] {
    width: 100%;
  }
}
.video-controls {
  & > * {
    outline: none;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 15px;
}

.volume-slider-container {
  position: relative;
  margin-bottom: 0;
}

.volume-slider {
  position: absolute;
  top: -200%;
  left: 50%;
  width: 12.5em !important;
  transform: translate(-50%, -50%) rotate(-90deg);
  & input[type='range'] {
    background-color: black !important;
    padding: 10px 5px;
    border-radius: 5px !important;
    transition: opacity 1s ease-in-out;
    width: 90px !important;
    &::-webkit-slider-thumb {
      margin-top: -3px !important;
      width: 10px !important;
      height: 10px !important;
    }
    &::-moz-range-thumb {
      margin: 12px 0 !important;
      width: 10px !important;
      height: 10px !important;
    }
    &::-ms-thumb {
      margin-top: -10px !important;
      width: 10px !important;
      height: 10px !important;
    }
  }
}
.progress-slider {
  display: flex;
  width: 100%;
  & input[type='range'] {
    &:focus {
      outline: none;
    }
    width: 100%;
    margin: 8px 0;
    border-radius: 2px;
    background-color: transparent;
    -webkit-appearance: none;

    &::-ms-track {
      background-color: map-get($colors, stable-light);
      border-radius: 5px;
      width: 98%;
      height: 4px;
    }

    &::-webkit-slider-runnable-track {
      background-color: map-get($colors, stable-light);
      border: 0;
      border-radius: 2px;
      width: 100%;
      height: 4px;
      cursor: pointer;
    }
    &::-webkit-slider-thumb {
      margin-top: -8px;
      width: 15px;
      height: 15px;
      background-color: map-get($colors, assertive);
      border: none;
      border-radius: 15px;
      cursor: pointer;
      -webkit-appearance: none;
    }
    &::-ms-thumb {
      margin-top: 0 !important;
      width: 15px;
      height: 15px;
      box-sizing: border-box;
      overflow: visible;
      &:hover {
        cursor: pointer;
      }
    }
    &::-moz-range-thumb {
      margin-top: -8px;
      width: 15px;
      height: 15px;
      background-color: map-get($colors, assertive);
      border: none;
      border-radius: 20px;
      cursor: pointer;
    }
    &::-moz-range-track {
      background-color: map-get($colors, stable-light);
      border: 0;
      border-radius: 2px;
      width: 100%;
      height: 4px;
      cursor: pointer;
    }
  }
}

.time-indicator {
  margin: 0 10px;
  min-width: 60px;
  color: map-get($colors, stable-dark);
}
.position-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 52px;
  border: 2px solid black;
}
.position-draggable {
  width: 50px;
  height: 50px;
  display: grid;
  place-items: center;
  cursor: pointer;
  z-index: 2;
}
.crosshair-dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  z-index: 2;
}
