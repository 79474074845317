$o-filter-h-padding: 15px;
$o-filter-v-padding: 0;

/**
 * Object filter
 */

.o-filter {
  border: 1px solid map-get($colors, stable-light);
  border-radius: $border-radius;
  white-space: nowrap;
  cursor: pointer;
  display: table;
  height: $input-height;
  margin-bottom: 15px;
  text-align: left;
  width: 100%;
  line-height: 1;

  @include breakpoint(medium) {
    width: 1%;
    margin-bottom: 0;
    float: left;
  }

  &:hover {
    border-color: map-get($colors, stable-dark);
  }
}

.o-filter--in-side-menu {
  @include transition(background-color 0.3s $smooth-ease);
  border-radius: 3px 3px 0 0;
  border: 0;
  width: 100%;
  float: none;

  :last-child & {
    border-radius: 3px;
  }

  @media #{$small-only} {
    margin-bottom: 0;
  }

  &:hover {
    border-color: map-get($colors, stable-light);
    background-color: map-get($colors, light);
  }
}

.o-filter--in-table {
  border: 0;
  float: right;
  width: inherit;
  text-align: right;
}

.o-filter--star {
  border: none;
  width: auto;

  svg {
    vertical-align: middle;
    margin-top: -2px;
  }
}

.o-filter ~ .o-filter {
  @media #{$medium-up} {
    margin-left: calc(
      #{$gutter*0.33}
    ); // default width between inline-block is one &nbsp; (1/5 of the with of 1em)
  }
}

/**
 * label element
 */

.o-filter__label {
  @include paragraph;
  margin: 0;
  white-space: nowrap;
  padding: $o-filter-v-padding 0 $o-filter-v-padding $o-filter-h-padding;
  display: table-cell;
  width: 1%;
  vertical-align: middle;
}

.o-filter--star .o-filter__label {
  border-spacing: 0 0 5px 0;
  color: map-get($colors, energized);
  margin-right: 10px;

  svg .o-stars__full-star {
    fill: map-get($colors, energized);
  }
}

.o-filter--in-table .o-filter__label {
  color: map-get($colors, stable);
}

/**
 * Select element
 */

.o-filter__value {
  background-image: url('../../images/chevron-down.svg');
  background-position: right 15px center;
  background-repeat: no-repeat;
  color: map-get($colors, dark);
  font-size: map-get($fonts, s);
  margin: 0 $o-filter-h-padding 0 0;
  padding: $o-filter-v-padding 40px $o-filter-v-padding 3px;
  letter-spacing: $letter-spacing;
  display: table-cell;
  vertical-align: middle;

  @media #{$small-only} {
    width: calc(60% - 65px);
  }
}

.o-filter__value--alt {
  background-position: left 15px center;
  margin-left: 5px;
  padding-right: 15px;
  text-align: right;
}

.o-filter__value--in-table {
  color: map-get($colors, dark);
  font-size: map-get($fonts, s);
  margin: 0 $o-filter-h-padding 0 0;
  padding: $o-filter-v-padding 0 $o-filter-v-padding 3px;
  letter-spacing: $letter-spacing;
  display: table-cell;
  vertical-align: middle;
}

.o-filter--in-side-menu .o-filter__value--alt {
  height: 45px;
}

.o-filter--star .o-filter__value {
  color: map-get($colors, stable-darker);
}

.o-filter--in-table .o-filter__value {
  color: map-get($colors, stable-dark);
}

/**
 * Counter element
 */

.o-filter__counter {
  display: inline-block;
  background-color: map-get($colors, assertive);
  color: map-get($colors, light);
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  border-radius: 25px;
  margin-top: -2px;
}

/**
 * Switch element
 */

.o-filter__switch {
  border: 1px solid transparent;
  border-left-color: map-get($colors, stable-light);
  padding: 0 15px 0 15px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.o-filter--in-table .o-filter__switch {
  border: 0;
  text-align: right;
  padding: 0 15px 0 5px;
}

.o-filter__switch input {
  position: absolute;
  top: -100px;
  left: -100px;
  opacity: 0;
}

.o-filter__switch input:focus + span {
  opacity: 0.5;
}

.o-filter__switch span {
  background-image: url('../../images/icon-descending.svg');
  background-position: center;
  background-size: cover;
  display: block;
  height: 14px;
  width: 14px;

  &:hover {
    cursor: pointer;
  }
}

.o-filter__switch--asc span {
  background-image: url('../../images/icon-ascending.svg');
}
