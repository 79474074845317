/**
 * Sub menu component
 */
.c-sub-menu {
  background-color: map-get($colors, light);
  position: relative;
  z-index: 3;
  border-bottom: 1px solid map-get($colors, stable-light);

  @media #{$small-only} {
    overflow: hidden;
  }
  @media print {
    display: none !important;
  }
}
/**
 * Inner element
 */

.c-sub-menu__inner {
  align-items: center;
  display: flex;
  height: $sub-menu-height;
  position: relative;
  width: 100%;

  @media #{$small-only} {
    display: inline-flex;
    overflow-x: scroll;
    margin: 0 -10px;
    padding: 0 0 0 15px;
    white-space: nowrap;
  }
}

/**
 * Filter element
 */

.c-sub-menu__filter {
  border-right: 1px solid map-get($colors, stable-light);
  padding-right: 20px;
  margin-right: 20px;

  @media #{$small-only} {
    display: inline-block;
  }
}

.c-sub-menu__filter p {
  @include heading3;
  color: map-get($colors, stable-dark);
  font-weight: 500;
  margin-bottom: 0;
  white-space: nowrap;
  max-width: 600px;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.6;
}

.c-sub-menu__filter p span {
  margin-left: 10px;
}

/**
 * list element
 */

.c-sub-menu__list {
  list-style: none;
  margin: 0;
  padding-left: 0;

  @media #{$small-only} {
    display: inline-block;
  }
}

.c-sub-menu__list-item {
  display: inline-block;
  margin: 0 30px 0 0;

  &:hover svg g {
    fill: map-get($colors, dark);
  }
}

.c-sub-menu__list-item.active .c-sub-menu__list-link {
  color: map-get($colors, dark);
}

.c-sub-menu__list-link {
  @include heading3;
  @include transition(color 0.3s $smooth-ease);
  color: map-get($colors, stable-dark);
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: map-get($colors, dark);
    text-decoration: none;
  }
}

.c-sub-menu__list-link svg {
  position: relative;
  top: -2px;

  g {
    @include transition(fill 0.3s $smooth-ease);
    fill: map-get($colors, stable-dark);
  }
}

/**
 * Action list element
 */

.c-sub-menu__action-list {
  list-style: none;
  margin: 0 0 0 auto;
  padding-left: 0;

  @media #{$small-only} {
    display: inline-block;
    border-left: 1px solid map-get($colors, stable-light);
    margin-left: 10px;
    padding-left: 20px;
  }
}

.c-sub-menu__action-list-item {
  display: inline-block;
  margin: 0 20px 0 0;

  @media #{$medium-up} {
    margin: 0 10px;
  }

  &:last-child {
    margin-right: 0;
  }
}

.c-sub-menu__action-list-link {
  @include paragraph;
  @include transition(color 0.3s $smooth-ease);
  align-items: center;
  display: flex;
  text-decoration: none;

  &:hover {
    color: map-get($colors, dark);
    cursor: pointer;
    text-decoration: none;

    svg path {
      fill: map-get($colors, dark);
    }
  }

  svg {
    margin-right: 10px;
  }

  svg path {
    @include transition(fill 0.3s $smooth-ease);
  }
}
