/**
 * Tabs component
 */

.c-tabs {
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin: 0 0 50px 0;
  padding-left: 0;
  border: 0;
}

/**
 * Item element
 */

.c-tabs__item {
  border-bottom: 1px solid map-get($colors, stable-light);
  border-left: 1px solid map-get($colors, stable-light);
  display: inline-flex;
  flex-grow: 1;

  &:first-child {
    border-left: none;
    margin-left: 0;

    a,
    span {
      padding-left: 0;
    }
  }
}

.c-tabs__item.is-active {
  border-bottom: 1px solid transparent;
}

.c-tabs__item.is-active .c-tabs__link {
  color: map-get($colors, dark);

  &:hover {
    color: map-get($colors, dark);
    cursor: default;
  }
}

/**
 * Link element
 */

.c-tabs__link {
  @include paragraph;
  display: block;
  padding: 0 0 20px 20px;
  text-decoration: none;
  outline: none;

  &:hover {
    color: map-get($colors, stable-dark);
    text-decoration: none;
  }
}
