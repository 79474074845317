/**
 * Checkbox object
 */

.o-checkbox {
  @include paragraph;
  color: map-get($colors, dark-light);
  cursor: pointer;
  display: block;
  margin-bottom: 10px;
  position: relative;
  padding-left: 30px;

  & input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    cursor: pointer;
  }

  & input:hover + span {
    cursor: pointer;
    border-color: map-get($colors, dark);
  }
  &:hover {
    transition: all 0.3s;
    color: map-get($colors, dark);
  }
}
.o-checkbox--label {
  font-weight: 500;
}
.o-checkbox--disabled {
  filter: grayscale(100);
  color: map-get($colors, stable-dark);
  cursor: default !important;
  & input:hover + span {
    cursor: default;
    border-color: map-get($colors, stable-light);
  }
  &:hover {
    color: map-get($colors, stable-dark);
  }
}
.o-checkbox--dark {
  color: map-get($colors, dark);
}

.o-checkbox span {
  background-color: map-get($colors, light);
  border: 1px solid map-get($colors, stable-light);
  border-radius: 3px;
  display: inline-block;
  height: 18px;
  position: absolute;
  left: 0;
  width: 18px;
  // transition: border-color 0.25s;
}

.o-checkbox input:checked + span:before {
  content: url('../../images/checkmark.svg');
  display: block;
  position: absolute;
  top: -1px;
  left: 1px;
  width: 12px;
}

.o-checkbox__tooltip {
  margin-left: 5px;
  position: relative;
  top: 3px;
}
