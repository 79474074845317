/**
 * Number-input object
 */

.o-number-input {
  display: flex;
  background-color: map-get($colors, stable-lighter);
  border-radius: $border-radius;

  & > * {
    width: 45px;
    height: 45px;
    font-weight: 500;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & button {
    cursor: pointer;
  }
}

label:has(> .o-number-input) {
  display: inline-block;
}
