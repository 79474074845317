/**
 * Drawboard block
 */

.c-drawboard {
  position: absolute;
  top: 10px;
  left: 10px;
  // `bottom: 0;` doesn't work
}

/**
 * Line element
 */

.c-drawboard__line {
  stroke-width: 2;
  stroke: #999;
  fill: none;
}

.c-drawboard__line--worst {
  stroke: map-get($colors, assertive);
}

.c-drawboard__line--bad {
  stroke: map-get($colors, royal);
}

.c-drawboard__line--normal {
  stroke: map-get($colors, stable-dark);
}

.c-drawboard__line--good {
  stroke: map-get($colors, royal);
}

.c-drawboard__line--best {
  stroke: map-get($colors, energized);
}

.c-drawboard__line--highlight {
  stroke: map-get($colors, dark) !important;
}

.c-drawboard__line--custom {
  stroke: map-get($colors, balanced);
}
