/**
 * Language switcher
 */

.o-locale {
  background-color: map-get($colors, light);
  border: 1px solid map-get($colors, stable-lighter);
  border-radius: $border-radius;
  white-space: nowrap;
  cursor: pointer;
  display: table;
  height: $input-height;
  margin-top: 38px;
  text-align: left;
  line-height: 1;

  width: 1%;
  margin-bottom: 0;
  float: right;

  &:hover {
    border-color: map-get($colors, stable);
  }
}

.o-locale__label {
  @include paragraph;
  margin: 0;
  white-space: nowrap;
  padding: 5px 10px 0 10px;
  display: table-cell;
  width: 1%;
  vertical-align: middle;
}

.o-locale__value {
  background-image: url('../../images/chevron-down.svg');
  background-position: right 15px center;
  background-repeat: no-repeat;
  color: map-get($colors, stable-dark);
  font-size: map-get($fonts, s);
  margin: 0 15px 0 0;
  padding: 0 40px 0 3px;
  letter-spacing: $letter-spacing;
  display: table-cell;
  vertical-align: middle;

  @media #{$small-only} {
    padding: 0 30px 0 3px;
    font-size: 0px;
  }
}
