/**
 * Mixins used as base for all typography in project.
 * Keeps all typography consistent accross project.
 */

@mixin heading1 {
  letter-spacing: $letter-spacing;
  font-family: $font-stack;
  font-size: map-get($fonts, l);
  font-weight: 500;
  line-height: 1.3;

  @media #{$medium-up} {
    font-size: map-get($fonts, xl);
    line-height: 1.2;
  }
}

@mixin heading2 {
  letter-spacing: $letter-spacing;
  font-family: $font-stack;
  font-size: map-get($fonts, m);
  font-weight: 500;
  line-height: 1.3;

  @media #{$medium-up} {
    font-size: map-get($fonts, l);
  }
}

@mixin heading3 {
  letter-spacing: $letter-spacing;
  font-family: $font-stack;
  font-size: map-get($fonts, s);
  font-weight: 700;
  line-height: 1.2;

  @media #{$medium-up} {
    font-size: map-get($fonts, m);
  }
}

@mixin heading4 {
  letter-spacing: $letter-spacing;
  font-family: $font-stack;
  font-size: map-get($fonts, xs);
  font-weight: 500;
  line-height: 1.3;

  @media #{$medium-up} {
    font-size: map-get($fonts, s);
  }
}

@mixin heading5 {
  letter-spacing: $letter-spacing;
  font-family: $font-stack;
  font-size: map-get($fonts, xs);
  font-weight: 500;
  line-height: 1.5;
}

@mixin paragraph {
  letter-spacing: $letter-spacing;
  color: map-get($colors, stable-dark);
  font-family: $font-stack;
  font-size: map-get($fonts, s);
  font-weight: 500;
  line-height: 1.5;
}

@mixin headingLarge {
  letter-spacing: $letter-spacing;
  font-family: $font-stack;
  font-size: map-get($fonts, xl);
  font-weight: 500;
  line-height: 1.3;

  @media #{$medium-up} {
    font-size: map-get($fonts, xxl);
    line-height: 1.2;
  }
}
