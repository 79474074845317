$connector-size: 24px;

.c-connector {
  @include noselect;
  width: $connector-size;
  height: $connector-size;
  border-radius: $connector-size*0.5;
  color: #fff;
  line-height: $connector-size - 2;
  font-size: 14px;
  text-align: center;
  position: relative;
  border-width: 1px;
  border-style: solid;
}

.c-connector--incoming {
  cursor: pointer;
  background: url('../../images/chevron-right-light.svg') no-repeat 50% 50%;
}

.c-connector--outgoing {
  cursor: pointer;
}

.c-connector--worst {
  border-color: map-get($colors, assertive);
}

.c-connector--bad {
  border-color: map-get($colors, royal);
}

.c-connector--normal {
  border-color: map-get($colors, stable-dark);
}

.c-connector--good {
  border-color: map-get($colors, royal);
}

.c-connector--best {
  border-color: map-get($colors, energized);
}

.c-connector--custom {
  border-color: map-get($colors, balanced);
}

.c-connector--worst.c-connector--filled {
  background-color: map-get($colors, assertive);
}

.c-connector--bad.c-connector--filled {
  background-color: map-get($colors, royal);
}

.c-connector--normal.c-connector--filled {
  background-color: map-get($colors, stable-dark);
}

.c-connector--good.c-connector--filled {
  background-color: map-get($colors, royal);
}

.c-connector--best.c-connector--filled {
  background-color: map-get($colors, energized);
}

.c-connector--custom.c-connector--filled {
  background-color: map-get($colors, balanced);
}

.c-connector--unfilled {
  background: map-get($colors, light);
}

.c-connector--worst.c-connector--unfilled {
  color: map-get($colors, assertive);
}

.c-connector--bad.c-connector--unfilled {
  color: map-get($colors, royal);
}

.c-connector--normal.c-connector--unfilled {
  color: map-get($colors, stable-dark);
}

.c-connector--good.c-connector--unfilled {
  color: map-get($colors, royal);
}

.c-connector--best.c-connector--unfilled {
  color: map-get($colors, energized);
}

.c-connector.c-connector--filled.c-connector--highlight {
  border-color: map-get($colors, dark);
  background-color: map-get($colors, dark);
}

.c-connector--unfilled.c-connector--highlight {
  border-color: map-get($colors, dark);
  color: map-get($colors, dark);
}

.c-connector--custom.c-connector--unfilled {
  color: map-get($colors, balanced);
}
