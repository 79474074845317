.o-file-upload {
  margin: 0;
  padding: 0;
  list-style: none;
  border-top: 1px solid map-get($colors, stable-light);

  li {
    padding: 20px 0;
    border-bottom: 1px solid map-get($colors, stable-light);
  }
}

.o-file-upload__title {
  font-weight: 500;
  color: map-get($colors, dark);
  margin: 0 0 5px;
  span {
    color: map-get($colors, stable-dark);
  }
}
.o-files-drop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.o-files-drop--overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1500;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  background-color: rgba(38, 39, 44, 0.9);
  transition: all 0.3s ease-in-out;
  cursor: 'not-allowed' !important;
}
.o-files-drop--icon {
  display: inline-block;
  width: 64px;
  height: 64px;
  padding: 20px;
  border-radius: 50%;
  background-color: white;
  color: map-get($colors, dark-light);
  margin-bottom: 20px;
}
@keyframes rotate-icon {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-360deg);
  }
}
.o-files-drop--content {
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 200px;
  align-items: center;
  justify-content: center;
  color: white;
}
.o-file-upload__error {
  margin: 5px 0 0;
  color: map-get($colors, assertive);
  font-size: 14px;

  a {
    color: map-get($colors, assertive);
    text-decoration: underline;
    &:hover {
      color: map-get($colors, stable-dark);
    }
  }
}

.o-file-upload__icon {
  width: 40px;
  height: 40px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 20px;
}

.video-icon-animated {
  animation: moveLtR 4s linear infinite 1s;
  opacity: 0;
}
@keyframes moveLtR {
  0% {
    transform: rotate(-160deg) translateX(150px) rotate(160deg);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  50%,
  100% {
    opacity: 0;
    transform: rotate(-20deg) translateX(150px) rotate(0deg);
  }
}

.o-file-upload__icon--image {
  background-image: url('../../images/icon-upload-image.svg');
}

.o-file-upload__icon--video {
  background-image: url('../../images/icon-upload-video.svg');
}

.o-file-upload__completed {
  width: 15px;
  height: 15px;
  background: url('../../images/checkmark-energized.svg') no-repeat center;
}
