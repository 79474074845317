/**
 * Tooltip
 */

.tooltip {
  max-width: 250px;
  text-align: center;
}

/**
 * Dropdown
 */

.dropdown-pane.auto-size {
  width: auto;
  max-width: none;
  white-space: nowrap;
}

/**
 * Form error
 */

.form-error {
  background-color: transparent;
  border-radius: none;
  min-height: 0;
  padding: 0;
}

small.error {
  color: map-get($colors, assertive);
  top: -10px;
  position: relative;
}

/**
 * Reveal
 */

.reveal {
  -webkit-overflow-scrolling: touch;
}

/**
 * Block grid, disable list style
 */

ul[class*='-up-'] {
  list-style: none;
}
