@use 'sass:color';
@import 'settings.variables';
// Global settings
$global-radius: 5px;

$foundation-palette: (
  primary: map-get($colors, assertive),
  secondary: map-get($colors, stable-light),
  success: #70c8b8,
  warning: #ffae00,
  alert: #cc4b37,
);

$breakpoint-classes: (
  small medium large xlarge xxlarge xxxlarge
);
$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1200px,
  xxlarge: 1700px,
  xxxlarge: 2000px,
);

$table-head-row-hover: 'transparent';

// -- Show content behind modal
html.is-reveal-open.zf-has-scroll {
  overflow-y: visible;
}

// Anchor
a.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.pointer-events-none {
  pointer-events: none;
}

// Background
.bg-light {
  background-color: map-get($colors, light) !important;
}

.bg-active-lighter {
  transition: background-color 300ms ease-in-out;
  background-color: #dcebf8 !important;
}

.box-shadow-inset {
  transition: box-shadow 200ms ease-in-out;
  box-shadow: 0px 0px 55px -22px rgba(0, 0, 0, 0.45) !important;
}

.bg-stable-lightest {
  transition: background-color 200ms ease-in-out;
  background-color: map-get($colors, stable-lightest) !important;
}

.bg-stable-lighter {
  transition: background-color 200ms ease-in-out;
  background-color: map-get($colors, stable-lighter) !important;
}

.bg-stable-light {
  transition: background-color 200ms ease-in-out;
  background-color: map-get($colors, stable-light) !important;
}

.bg-stable {
  transition: background-color 200ms ease-in-out;
  background-color: map-get($colors, stable) !important;
}

.bg-stable-dark {
  transition: background-color 200ms ease-in-out;
  background-color: map-get($colors, stable-dark) !important;
}

.bg-dark-light {
  transition: background-color 200ms ease-in-out;
  background-color: map-get($colors, dark-light) !important;
}

.bg-dark {
  transition: background-color 200ms ease-in-out;
  background-color: map-get($colors, dark) !important;
}

.bg-light {
  transition: background-color 200ms ease-in-out;
  background-color: map-get($colors, light) !important;
}

.bg-gray-background {
  transition: background-color 200ms ease-in-out;
  background-color: map-get($colors, gray-background) !important;
}

.bg-royal {
  transition: background-color 200ms ease-in-out;
  background-color: map-get($colors, royal) !important;
}

.bg-success {
  transition: background-color 200ms ease-in-out;
  background-color: map-get($colors, success) !important;
}

.bg-assertive {
  transition: background-color 200ms ease-in-out;
  background-color: map-get($colors, assertive) !important;
}

.bg-assertive-light {
  transition: background-color 200ms ease-in-out;
  background-color: rgba(map-get($colors, assertive), 0.05) !important;
}

.bg-energized {
  transition: background-color 200ms ease-in-out;
  background-color: map-get($colors, energized) !important;
}

.bg-balanced {
  transition: background-color 200ms ease-in-out;
  background-color: map-get($colors, balanced) !important;
}

.hover-bg-stable-lightest:hover {
  transition: background-color 200ms ease-in-out;
  background-color: map-get($colors, stable-lightest) !important;
}

.bg-transparent {
  background-color: transparent !important;
}

// Default borders
.border {
  border: 1px solid map-get($colors, stable) !important;
}

.border-assertive {
  border: 1px solid map-get($colors, assertive) !important;
}

.border-energized {
  border: 1px solid map-get($colors, energized) !important;
}

.border-royal {
  border: 1px solid map-get($colors, royal) !important;
}

.border-stable {
  border: 1px solid map-get($colors, stable) !important;
}

.border-stable-light {
  border: 1px solid map-get($colors, stable-light) !important;
}

.border-dark {
  border: 1px solid map-get($colors, dark-light) !important;
}

.border-left {
  border-left: 1px solid map-get($colors, stable) !important;
}

.border-right {
  border-right: 1px solid map-get($colors, stable) !important;
}

.border-top {
  border-top: 1px solid map-get($colors, stable) !important;
}

.border-bottom {
  border-bottom: 1px solid map-get($colors, stable) !important;
}

.border-light {
  border: 1px solid map-get($colors, stable-light) !important;
}

.border-light-left {
  border-left: 1px solid map-get($colors, stable-light) !important;
}

.border-light-right {
  border-right: 1px solid map-get($colors, stable-light) !important;
}

.border-light-top {
  border-top: 1px solid map-get($colors, stable-light) !important;
}

.border-light-bottom {
  border-bottom: 1px solid map-get($colors, stable-light) !important;
}

.border-dashed {
  border-style: dashed !important;
}

.border-inset-energized {
  box-shadow: inset 0 0 2px 0px map-get($colors, energized) !important;
}

.border-0 {
  border: 0 !important;
}

.border-2 {
  border-width: 2px !important;
}

// Buttons
$button-font-color: map-get($colors, light);
$button-font-weight: '700';
$button-font-color-alt: map-get($colors, dark);
$button-border-width: 1px;
$button-border-style: solid;
$button-radius: $global-radius;

.button {
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  height: 42px;
  letter-spacing: -0.7px;
  vertical-align: middle;

  &.small {
    height: 30px;
    padding: 8px;
  }

  &.wide {
    min-width: 120px;
  }

  &.primary {
    color: white !important;
    font-weight: 700;
  }

  &.royal {
    background-color: map-get($colors, royal) !important;
  }

  &.energized {
    background-color: map-get($colors, energized) !important;
  }

  &.dark {
    color: white;
    font-weight: 700;
    background-color: map-get($colors, stable-dark);

    &:hover,
    &:focus {
      background-color: color.adjust(map-get($colors, stable-dark), $lightness: -25%);
    }
  }

  &.dark-transparent {
    background-color: rgba(38, 39, 44, 0.8);
  }

  &.hollow.dark {
    color: map-get($colors, dark-light);
    border-color: map-get($colors, dark-light);

    &:hover,
    &:focus {
      color: map-get($colors, dark);
      border-color: map-get($colors, dark);
    }
  }

  &.hollow.secondary {
    color: map-get($colors, stable-dark) !important;

    &.button-alt {
      border-color: transparent;
      transition: border-color 250ms ease-in-out;
    }

    &:hover,
    &:focus {
      color: map-get($colors, dark-light) !important;
    }

    &.active {
      color: map-get($colors, assertive) !important;
      background-color: rgba(231, 70, 26, 0.05) !important;
      border-color: map-get($colors, assertive) !important;

      &:hover {
        color: map-get($colors, assertive) !important;
      }
    }
  }

  &.disabled {
    pointer-events: none;
  }
}

// Borders
.border-radius {
  border-radius: 5px;
}

.border-radius--rounded-full {
  border-radius: 100%;
}

.border-radius--none {
  border-radius: 0 !important;
}

// Button Groups
$buttongroup-radius-on-each: false;

// Cursor
.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.cursor-text {
  cursor: text !important;
}

// Dropdown

hr {
  border: 0;
  border-bottom: 1px solid map-get($colors, stable-light) !important;
}

.is-dropdown-submenu--custom {
  top: calc(100% + 3px) !important;
  padding: 12px 0px !important;
  border-radius: $global-radius;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);

  &::before {
    content: url('../../images/dropdown-arrow.svg');
    position: absolute;
    top: -15px;
  }

  & .custom--item {
    padding: 8px 20px;
    align-content: center;
    width: 100%;

    &:hover:not(.disabled) {
      cursor: pointer;
      background-color: rgba(231, 70, 26, 0.15);
    }

    & label {
      margin-bottom: 0px;
    }
  }

  &--scroll {
    max-height: 250px;
    right: 0 !important;
    overflow-y: scroll;
  }
}

// Grid

$grid-container-padding: (
  small: 40px,
  medium: 80px,
  large: 80px,
  xlarge: 120px,
  xxlarge: 140px,
);
$grid-margin-gutters: (
  small: 10px,
  medium: 40px,
);

.grid-center {
  display: grid;
  place-items: center;
}

.grid--overflow {
  overflow-y: auto;
  overflow-x: hidden;
}

.grid-card {
  $grid-margin-gutters: (
    medium: 30px,
  );
}

.hide-cursor {
  cursor: none !important;
}

// Height
.h-100 {
  height: 100% !important;
}

.max-h-100 {
  max-height: 100% !important;
}

.h-auto {
  height: auto !important;
}

// WIDTH
.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.min-w-0 {
  min-width: 0 !important;
}

// form
form {
  margin-top: 30px !important;
  letter-spacing: -0.7px !important;

  & label,
  *>label {
    line-height: 1;
    margin-bottom: 10px;
    letter-spacing: -0.7px;
  }

  & select,
  *>select {
    background-image: url('../../images/chevron-down.svg');
    background-position: right 0px center !important;
    background-repeat: no-repeat !important;
    background-size: auto !important;
    min-height: 42px !important;
    letter-spacing: -0.7px;
    padding: 0.5rem;
    padding-left: 16px;
  }

  & input,
  *>input,
  & textarea {
    padding: 12px 16px !important;
    min-height: 42px !important;
    letter-spacing: -0.7px;
    padding-right: 0px;

    &[type='checkbox'] {
      margin: 0;
      margin-right: 10px;
    }
  }
}

.input--disabled {
  cursor: forbidden;
  color: map-get($colors, stable) !important;
}

.input--required {
  &--force {
    border: 1px solid map-get($colors, assertive) !important;
  }

  &:invalid {
    border: 1px solid map-get($colors, assertive);
  }
}

textarea {
  padding: 0.5rem 16px;
  letter-spacing: -0.7px;
}

// Modal
$reveal-overlay-background: rgba(38, 39, 44, 0.8);
$reveal-padding: 0px;

.reveal {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);

  &:focus {
    outline: 0px;
  }
}

// Table
$table-is-striped: false;
$table-border: 0px;
$table-head-background: map-get($colors, light);

table tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

table tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

table tr:first-child td:first-child {
  border-top-left-radius: 10px;
}

table tr:first-child td:last-child {
  border-top-right-radius: 10px;
}

// border
.border-none {
  border: none !important;
}

// input group
.input-group> :last-child {
  margin-bottom: 0;
}

// outline
.outline-none {
  &:focus {
    outline: none;
  }
}

// Shadow
.shadow {
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}

// text
.text-white {
  color: map-get($colors, light) !important;
}

.text-dark {
  color: map-get($colors, dark-light) !important;
}

.text-danger {
  color: map-get($colors, assertive) !important;
}

.text-stable-dark {
  color: map-get($colors, stable-dark) !important;
}

.text-energized {
  color: map-get($colors, energized) !important;
}

.text-royal {
  color: map-get($colors, royal) !important;
}

.text-assertive {
  color: map-get($colors, assertive) !important;
}

.text-light-gray {
  color: map-get($colors, stable-light);
}

.text-stable {
  color: map-get($colors, stable);
}

.text-small {
  font-size: 12px !important;

  &>p {
    font-size: 12px !important;
    margin-bottom: 0.5rem;
  }
}

.text-normal {  
  font-weight: 500;
  font-size: 14px !important;
}

.text-large {
  font-size: 16px !important;
}

.text-xlarge {
  font-size: 18px !important;
}

.text-xxlarge {
  font-size: 20px !important;
}

.text-weight-normal {
  font-weight: normal !important;
}

.text-bold {
  font-weight: 700 !important;
}

.text-medium {
  font-weight: 600 !important;
}

.text-lighter {
  font-weight: 200 !important;
}

.text-underline {
  text-decoration: underline !important;
}

.text-no-decoration {
  text-decoration: none !important;
}

.text-no-selection {
  user-select: none !important;
}

.text-italic {
  font-style: italic !important;
}

.text-truncate {
  word-wrap: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-success {
  color: map-get($colors, success) !important;
}

.text-striped {
  text-align: center;

  &:before,
  &:after {
    background-color: map-get($colors, stable);
    content: '';
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 30%;
  }

  &:before {
    right: 0.5em;
    margin-left: -30%;
  }

  &:after {
    left: 0.5em;
    margin-right: -30%;
  }
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.break-words {
  word-break: break-word;
}

// Margins
.margin--footer {
  margin-bottom: 20px;
}

.mb- {
  &0 {
    margin-bottom: 0px !important;
  }

  &0-5 {
    margin-bottom: 5px !important;
  }

  &1 {
    margin-bottom: 10px !important;
  }

  &1-5 {
    margin-bottom: 15px !important;
  }

  &2 {
    margin-bottom: 20px !important;
  }

  &2-5 {
    margin-bottom: 25px !important;
  }

  &3 {
    margin-bottom: 30px !important;
  }

  &4 {
    margin-bottom: 40px !important;
  }

  &5 {
    margin-bottom: 50px !important;
  }

  &10 {
    margin-bottom: 100px !important;
  }

  &15 {
    margin-bottom: 150px !important;
  }

  &20 {
    margin-bottom: 200px !important;
  }
}

.mt- {
  &0 {
    margin-top: 0px !important;
  }

  &0-5 {
    margin-top: 5px !important;
  }

  &1 {
    margin-top: 10px !important;
  }

  &1-5 {
    margin-top: 15px !important;
  }

  &2 {
    margin-top: 20px !important;
  }

  &2-5 {
    margin-top: 25px !important;
  }

  &3 {
    margin-top: 30px !important;
  }

  &3-5 {
    margin-top: 35px !important;
  }

  &4 {
    margin-top: 40px !important;
  }

  &4-5 {
    margin-top: 45px !important;
  }

  &5 {
    margin-top: 50px !important;
  }
}

.ml- {
  &0 {
    margin-left: 0px !important;
  }

  &0-5 {
    margin-left: 5px !important;
  }

  &1 {
    margin-left: 10px !important;
  }

  &1-5 {
    margin-left: 15px !important;
  }

  &2 {
    margin-left: 20px !important;
  }

  &2-5 {
    margin-left: 25px !important;
  }

  &3 {
    margin-left: 30px !important;
  }

  &4 {
    margin-left: 40px !important;
  }
}

.-ml-1 {
  margin-left: -10px !important;
}

.mr- {
  &0 {
    margin-right: 0 !important;
  }

  &0-5 {
    margin-right: 5px !important;
  }

  &1 {
    margin-right: 10px !important;
  }

  &1-5 {
    margin-right: 15px !important;
  }

  &2 {
    margin-right: 20px !important;
  }

  &2-5 {
    margin-right: 25px !important;
  }

  &3 {
    margin-right: 30px !important;
  }

  &4 {
    margin-right: 40px !important;
  }
}

.m- {
  &0 {
    margin: 0 !important;
  }

  &0-5 {
    margin: 5px !important;
  }

  &1 {
    margin: 10px !important;
  }

  &1-5 {
    margin: 15px !important;
  }

  &2 {
    margin: 20px !important;
  }

  &2-5 {
    margin: 25px !important;
  }

  &3 {
    margin: 30px !important;
  }

  &4 {
    margin: 40px !important;
  }
}

// Padding
.p- {
  &0 {
    padding: 0 !important;
  }

  &0-5 {
    padding: 5px !important;
  }

  &1 {
    padding: 10px !important;
  }

  &1-5 {
    padding: 15px !important;
  }

  &2 {
    padding: 20px !important;
  }

  &2-5 {
    padding: 25px !important;
  }

  &3 {
    padding: 30px !important;
  }

  &4 {
    padding: 40px !important;
  }

  &4-5 {
    padding: 45px !important;
  }

  &5 {
    padding: 50px !important;
  }
}

.pl- {
  &0 {
    padding-left: 0 !important;
  }

  &0-5 {
    padding-left: 5px !important;
  }

  &1 {
    padding-left: 10px !important;
  }

  &1-5 {
    padding-left: 15px !important;
  }

  &2 {
    padding-left: 20px !important;
  }

  &2-5 {
    padding-left: 25px !important;
  }

  &3 {
    padding-left: 30px !important;
  }

  &4 {
    padding-left: 40px !important;
  }

  &4-5 {
    padding-left: 45px !important;
  }

  &5 {
    padding-left: 50px !important;
  }
}

.pr- {
  &0 {
    padding-right: 0 !important;
  }

  &0-5 {
    padding-right: 5px !important;
  }

  &1 {
    padding-right: 10px !important;
  }

  &1-5 {
    padding-right: 15px !important;
  }

  &2 {
    padding-right: 20px !important;
  }

  &2-5 {
    padding-right: 25px !important;
  }

  &3 {
    padding-right: 30px !important;
  }

  &4 {
    padding-right: 40px !important;
  }

  &4-5 {
    padding-right: 45px !important;
  }

  &5 {
    padding-right: 50px !important;
  }
}

.pt- {
  &0 {
    padding-top: 0px !important;
  }

  &0-5 {
    padding-top: 5px !important;
  }

  &1 {
    padding-top: 10px !important;
  }

  &1-5 {
    padding-top: 15px !important;
  }

  &2 {
    padding-top: 20px !important;
  }

  &2-5 {
    padding-top: 25px !important;
  }

  &3 {
    padding-top: 30px !important;
  }

  &3-5 {
    padding-top: 30px !important;
  }

  &4 {
    padding-top: 40px !important;
  }

  &4-5 {
    padding-top: 45px !important;
  }

  &5 {
    padding-top: 50px !important;
  }
}

.pb- {
  &0 {
    padding-bottom: 0 !important;
  }

  &0-5 {
    padding-bottom: 5px !important;
  }

  &1 {
    padding-bottom: 10px !important;
  }

  &1-5 {
    padding-bottom: 15px !important;
  }

  &2 {
    padding-bottom: 20px !important;
  }

  &2-5 {
    padding-bottom: 25px !important;
  }

  &3 {
    padding-bottom: 30px !important;
  }

  &4 {
    padding-bottom: 40px !important;
  }

  &4-5 {
    padding-bottom: 45px !important;
  }

  &5 {
    padding-bottom: 50px !important;
  }
}

// Aspect Ratio
.aspect-square {
  aspect-ratio: 1 / 1;
}

.aspect-auto {
  aspect-ratio: auto !important;
}

// display
[hidden] {
  display: none !important;
}

.inline-block {
  display: inline-block !important;
}

// letter spacing
.tracking-normal {
  letter-spacing: normal;
}

.tracking-tighter {
  letter-spacing: -0.7px;
}

// flex
.flex-wrap {
  flex-wrap: wrap;
}

// white space
.whitespace-nowrap {
  white-space: nowrap;
}

// overflow
.overflow-hidden {
  overflow: hidden;
}

// position
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}

// object-fit
.object-contain {
  object-fit: contain;
}

// visibility
.invisible {
  visibility: hidden;
}

// print
@media print {
  .no-print {
    display: none !important;
  }
}