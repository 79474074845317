@use 'sass:color';
/**
 * Draggable question panel
 */
.c-video-card {
  position: absolute;
}

.c-video-card--selected .c-video-card__inner {
  box-shadow: 0 0 0 1px map-get($colors, energized);
}

/**
 * Inner element
 */

.c-video-card__inner {
  padding: 1px 1px 5px 1px;
  left: 0;
  bottom: 0;
  position: absolute;
  background-color: none;
  transition: background-color 0.1s;
  cursor: pointer;
  border-radius: 2px;
  white-space: nowrap;

  .c-video-card &:hover {
    box-shadow: 0 0 0 1px map-get($colors, energized);
    background-color: color.adjust(map-get($colors, energized), $alpha: -0.2);
  }
}

/**
 * Information element
 */

.c-video-card--information .c-video-card__inner {
  padding: 2px;
}

.c-video-card--show .c-video-card__inner {
  transition: opacity 0.5s;
  opacity: 1;
}

.c-video-card--hide .c-video-card__inner {
  opacity: 0;
}

.c-video-card--information .c-video-card__question {
  display: block;
  max-width: 140px;
}

.c-video-card--information .c-video-card__answers {
  display: block;
  text-align: center;
  margin: 5px auto 0;
}

/**
 * Question element
 */

.c-video-card__question {
  white-space: normal;
  vertical-align: bottom;
  display: inline-block;
  background-color: color.adjust(map-get($colors, light), $alpha: -0.1);
  border-radius: 2px;
  color: map-get($colors, dark);
  font-size: 6px;
  margin: 0;
  padding: $gutter*0.16;
  text-align: center;
  min-width: 50px;
  position: relative;
  width: 70px;

  &:after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-top-color: color.adjust(map-get($colors, light), $alpha: -0.1);
    border-width: 3px;
    margin-left: -3px;
  }
}

/**
 * Answers element
 */

.c-video-card__answers {
  margin-left: 5px;
  white-space: normal;
  width: 70px;
  display: inline-block;
}

/**
 * Answer element
 */

.c-video-card__answer {
  background-color: color.adjust(map-get($colors, assertive), $alpha: -0.1);
  border-radius: 2px;
  color: map-get($colors, light);
  font-size: 5px;
  font-weight: 700;
  margin: 3px 0 0 0;
  padding: $gutter*0.16;

  &:first-child {
    margin: 0;
  }
}

/**
 * Direction element
 */

.c-video-card--direction .c-video-card__inner {
  width: 50px;
  padding-bottom: 32px;
  background: url('../../images/arrow.png') no-repeat 50% 100%;
  background-size: 38px;
}

.c-video-card--direction .c-video-card__question {
  min-width: 0;
  width: auto;
  display: block;
  margin-bottom: 5px !important;
}

/**
 * Hotspot element
 */

.c-video-card--hotspot-small .c-video-card__inner {
  min-width: 30px;
  padding-bottom: 30px;
  background: url('../../images/hotspot-small.png') no-repeat 50% 100%;
  background-size: 30px;
}

.c-video-card--hotspot-medium .c-video-card__inner {
  min-width: 50px;
  padding-bottom: 50px;
  background: url('../../images/hotspot-medium.png') no-repeat 50% 100%;
  background-size: 50px;
}

.c-video-card--hotspot-large .c-video-card__inner {
  min-width: 80px;
  padding-bottom: 80px;
  background: url('../../images/hotspot-large.png') no-repeat 50% 100%;
  background-size: 80px;
}

.c-video-card--hotspot-small .c-video-card__question,
.c-video-card--hotspot-medium .c-video-card__question,
.c-video-card--hotspot-large .c-video-card__question {
  min-width: 65px;
  width: auto;
  display: block;
  margin-bottom: 5px !important;
}

[class*='c-video-card--hotspot'] .c-video-card__question:after {
  bottom: auto;
  top: 100%;
  border: solid transparent;
  border-top-color: color.adjust(map-get($colors, light), $alpha: -0.1);
}
