/**
* Settings and libraries
*/

@use 'sass:color';
@import 'settings.variables';
@import 'settings.foundation';
@import '~foundation-sites/scss/foundation';
@import '~motion-ui/src/motion-ui';

$body-font-family: $font-stack;

$form-label-color: map-get($colors, dark-light);
$form-label-font-weight: 700;
$form-spacing: 20px;

$input-shadow: none;
$input-font-size: rem-calc(map-get($fonts, s));
$input-radius: $border-radius;
$input-border: none;
$input-border-focus: none;
$input-shadow: 0;
$input-shadow-focus: 0;
$input-background: map-get($colors, stable-lighter);
$input-background-focus: map-get($colors, stable-lighter);
$input-background-disabled: map-get($colors, stable-lighter);
$input-font-family: $font-stack;
$input-font-weight: 500;

$dropdownmenu-arrows: false;
$dropdown-padding: 0;
$dropdown-background: transparent;
$dropdown-border: 0;
$dropdown-sizes: (
  tiny: 100px,
  small: 230px,
  large: 400px,
); // small has increased value

$hr-border: 1px solid map-get($colors, stable);
$anchor-color-hover: map-get($colors, assertive);

$select-background: map-get($colors, stable-lighter);
$tooltip-background-color: color.scale(map-get($colors, dark), $alpha: -20%);
$tooltip-padding: 10px;
$tooltip-font-size: map-get($fonts, s);
$tooltip-pip-width: 0.75rem;
$tooltip-pip-height: $tooltip-pip-width * 0.866;
$tooltip-radius: $border-radius;

$tab-item-font-size: map-get($fonts, s);
$tab-background-active: transparent;
$tab-color: map-get($colors, stable-dark);
$tab-content-padding: 0;

// default for motion ui
$motion-ui-speeds: map-merge(
  $motion-ui-speeds,
  (
    'default': 250ms,
  )
);
$motion-ui-easings: map-merge(
  $motion-ui-easings,
  (
    'default': ease-in-out,
  )
);

@include foundation-global-styles;
@include foundation-grid;
@include foundation-typography;
@include foundation-forms;
@include foundation-button;
@include foundation-button-group;
@include foundation-accordion;
// @include foundation-accordion-menu;
// @include foundation-badge;
// @include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
// @include foundation-close-button;
@include foundation-menu;
// @include foundation-menu-icon;
// @include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-flex-grid;
@include foundation-flex-classes;
// @include foundation-responsive-embed;
// @include foundation-label;
// @include foundation-media-object;
// @include foundation-off-canvas;
// @include foundation-orbit;
// @include foundation-pagination;
// @include foundation-progress-bar;
// @include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
// @include foundation-thumbnail;
// @include foundation-title-bar;
@include foundation-tooltip;
// @include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;

@include motion-ui-transitions;
@include motion-ui-animations;

@include foundation-xy-grid-classes;
/**
  * Mixins
  */

@import 'mixins.aspect_ratio';
@import 'mixins.box_shadow';
@import 'mixins.transitions';
@import 'mixins.typography';
@import 'mixins.various';

/**
  * Base
  */

@import 'base.global';
@import 'object.locale';

/**
  * Objects
  */

@import 'object.accordion';
@import 'object.action_list';
@import 'object.alert';
@import 'object.avatar';
@import 'object.button';
@import 'object.bullet';
@import 'object.bar';
@import 'object.card';
@import 'object.callout';
@import 'object.checkbox';
@import 'object.color_picker';
@import 'object.dropdown';
@import 'object.draggable_list';
@import 'object.empty_state';
@import 'object.flag';
@import 'object.file-input';
@import 'object.file-upload';
@import 'object.filter';
@import 'object.htmlEditor';
@import 'object.label';
@import 'object.logo';
@import 'object.loading';
@import 'object.modal';
@import 'object.number-input';
@import 'object.radio';
@import 'object.range-input';
@import 'object.scenarioEditor';
@import 'object.select';
@import 'object.slider';
@import 'object.sortable';
@import 'object.stars';
@import 'object.stars-input';
@import 'object.stepper';
@import 'object.switch';
@import 'object.table';
@import 'object.tabs';
@import 'object.textarea';
@import 'object.text-input';
@import 'object.toast';

/**
  * Components
  */

@import 'component.body';
@import 'component.banner';
@import 'component.canvas';
@import 'component.container';
@import 'component.connector';
@import 'component.dark_card';
@import 'component.datepicker';
@import 'component.drawboard';
@import 'component.form';
@import 'component.flip';
@import 'component.launcher';
@import 'component.modal';
@import 'component.menu';
@import 'component.notice';
@import 'component.panel';
@import 'component.progress';
@import 'component.segments';
@import 'component.segments_nav';
@import 'component.side_menu';
@import 'component.sidebar';
@import 'component.snackbar';
@import 'component.sub_menu';
@import 'component.stats';
@import 'component.tabs';
@import 'component.textual_card';
@import 'component.visual_card';
@import 'component.video';
@import 'component.video_select';
@import 'component.video_card';
@import 'component.workarea';
@import 'component.zoom';

/**
 * Utilities
 */

@import 'utilities.helpers';
@import 'utilities.spacing';

/**
 * Vendor
 */

@import 'vendor.foundation';
@import 'vendor.react_draggable';
@import 'vendor.turbolinks';
@import 'vendor.frill';
