.c-video-select {
  margin: 0 0 $gutter*0.5;
}

.c-video-select__title {
  color: map-get($colors, stable);
}

.c-video-select__item {
  border: 1px solid map-get($colors, stable-lighter);
  border-radius: $border-radius;
  display: table;
  width: 100%;
  padding: $gutter*0.44 $gutter*0.5;
}

.c-video-select__item-select {
  display: table-cell;
  vertical-align: middle;
  height: 24px;

  svg {
    height: 13px;
    vertical-align: middle;
    position: relative;
    top: -1px;
  }
}

.c-video-select__item-form {
  min-height: 50px;
  display: table-cell;
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
}
