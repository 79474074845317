$scale: .5;

.c-zoom {
  transition: transform .5s linear;
}

.c-zoom--half {
  transform: scale($scale);
  transition: transform .5s linear;
}

.c-zoom__element {
 transition: transform .5s linear;
 transform: translateX(0);
 position: absolute;
 top: 0;
}

.c-zoom--half .c-zoom__element {
  transition: transform .5s linear;
  transform: translateX(-50vw);
}