$table-v-padding: 16px;
$table-h-padding: $gutter * 0.33;

/**
 * Table object
 */

.o-table {
  width: 100%;
  border-collapse: collapse;
}

.o-table--hide-scrollbars {
  &::-webkit-scrollbar {
    display: none;
  }
}

.o-table--overflow:not(.o-table--small) {
  & thead tr:nth-child(1) th {
    background: white;
    position: sticky;
    top: 0;
    z-index: 9;
  }

  min-width: 700px;
  overflow-x: scroll;
}

.o-table.o-table--small {
  thead th {
    padding-top: 0;
  }
}

.o-table--scroll {
  min-width: 100%;
  overflow-y: auto;
  padding-bottom: 100px;
  height: 100%;
}

.o-table--scroll:has(.o-table--small) {
  padding-bottom: 0;
}

.o-table--bt {
  border-top: 1px solid map-get($colors, stable);
}

.o-table__progress--container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding-right: 0px !important;
}

.o-table__progress {
  padding-left: 12px;
}

.o-table__empty--icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 170px;
  color: map-get($colors, stable-dark);
  padding: 40px;
  border-radius: 50%;
  background-color: map-get($colors, stable-lightest);
  margin-bottom: 20px;

  &.fixed-width {
    background-color: map-get($colors, stable-light);
    border-radius: 50%;
    width: 264px;
    height: 264px;
  }
}

/**
* Table Title Search / Button bar
*/
.o-table__title {
  display: flex;
  margin-bottom: 30px;

  & h2 {
    font-weight: 700;
  }
}

.o-table__title--right-bar {
  & span:not(:last-child) {
    margin-right: 10px;
  }
}

.o-table__heading--sub {
  font-weight: 400;
}

/**
 * Row element
 */

.o-table__row {
  border-bottom: 1px solid map-get($colors, stable);
  height: 50px;
}

.o-table__row--no-border {
  border-bottom: none;
}

.o-table__row--selected {
  background: rgba(map-get($colors, assertive), 0.1) !important;
}

/**
 * Header element
 */

.o-table__header {
  @include paragraph;
  color: map-get($colors, dark-light);
  text-align: left;
  min-height: 53px;
  vertical-align: middle;

  svg {
    vertical-align: middle;
  }
}

.o-table__header--nowrap {
  white-space: nowrap !important;

  &:not(:first-child) {
    padding-right: 35px;
  }
}

.o-table:not(.o-table--small) {
  .o-table__header--nowrap:last-child {
    padding-right: 0;
  }
}

.o-table__header:not(:first-child) {
  padding-left: $table-h-padding;
}

.o-table__header--sortable {
  cursor: pointer;
  transition: color 300ms ease-in-out;

  &:hover {
    color: map-get($colors, assertive);
    transition: color 300ms ease-in-out;
  }
}

.o-table__header--border::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  border-bottom: 1px solid map-get($colors, stable-dark);
}

.o-table__header--icon {
  margin-left: 5px;
}

/**
 * Data element
 */

.o-table__data {
  @include paragraph;
  color: map-get($colors, dark);
  padding: $table-v-padding 0;
}

.o-table__data--loading {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0), #fff, rgba(0, 0, 0, 0));
  background-size: 300% 100%;

  -webkit-animation: AnimationName 6s ease infinite;
  -moz-animation: AnimationName 6s ease infinite;
  animation: AnimationName 6s ease infinite;

  @-webkit-keyframes AnimationName {
    0% {
      background-position: 0% 0%;
    }

    50% {
      background-position: 100% 0%;
    }

    100% {
      background-position: 0% 0%;
    }
  }

  @-moz-keyframes AnimationName {
    0% {
      background-position: 0% 0%;
    }

    50% {
      background-position: 100% 0%;
    }

    100% {
      background-position: 0% 0%;
    }
  }

  @keyframes AnimationName {
    0% {
      background-position: 100% 0%;
    }

    50% {
      background-position: 0% 0%;
    }

    100% {
      background-position: 100% 0%;
    }
  }
}

.o-table__data--custom {
  padding: 0;
  white-space: nowrap !important;
}

.o-table:not(.o-table--small) {
  .o-table__data--first-item {
    min-width: 240px;
    transition: color 300ms ease-in-out;
    color: map-get($colors, dark-light);

    & a {
      color: inherit;
      display: flex;
      width: 100%;
      justify-content: space-between;
    }

    &:hover a {
      color: map-get($colors, assertive) !important;
      text-decoration: underline;
    }
  }

  &.o-table-fixed-width .o-table__data--first-item {
    min-width: 0;
  }

  /*------ drag and drop styles ------*/

  &:has(tr.dragging) {
    position: relative;

    td,
    td * {
      user-select: none;
    }
  }

  tr.dragging {
    position: absolute;
    width: 100%;
    left: 0;
    z-index: 10;
    outline: 1px solid map-get($colors, stable-light);
    border-radius: 5px;
    overflow: hidden;
    border-bottom-color: transparent;

    td {
      background-color: #fff;
    }
  }

  .o-table__drag-handle {
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }

  tr.o-table__row--placeholder {
    position: relative;
    border-bottom-color: transparent;

    &:before {
      content: '';
      position: absolute;
      height: 50px;
      width: 100%;
      inset: 0;
      background-color: map-get($colors, stable-light);
    }
  }

  tr.o-table__row--dragover {
    position: relative;
    border-bottom-color: transparent;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      bottom: 0;
      border-bottom: 2px solid map-get($colors, assertive);
    }
  }
}

.o-table__row--entire-clickable {
  transition: color 300ms ease-in-out;

  &:hover td,
  &:hover a {
    color: map-get($colors, assertive) !important;
    text-decoration: underline;
  }
}

.o-table__data--context {
  color: map-get($colors, stable-dark);
}

.o-table__data:not(:first-child) {
  padding-left: $gutter * 0.33;
}

.o-table__data[rowspan] {
  vertical-align: top;
}

.o-table__data--actions {
  padding: 0;
  text-align: right;
}

.o-table__data--checkbox {
  padding: 0 0 8px 8px;
  text-align: left;

  &.checkbox-fixed-width {
    padding-left: 20px;
    width: 30px;
  }
}

.o-table__data--hidden {
  display: none;
}

.o-table__data--truncated {
  max-width: 500px;
  overflow-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.o-table__data-link {
  @include transition(background-color 0.3s $smooth-ease);
  border: 1px solid map-get($colors, stable);
  border-radius: 6px;
  width: 32px;
  height: 32px;
  line-height: 34px;
  display: block;
  text-align: center;

  &:hover {
    background-color: map-get($colors, stable-light);
  }
}

.o-table__link {
  float: right;
  text-decoration: underline;
  color: map-get($colors, assertive);

  &:hover {
    text-decoration: underline;
    color: map-get($colors, balanced);
    cursor: pointer;
  }
}

/**
 * Cell element
 */

.o-table__cell--center {
  text-align: center;
}

/**
 * Action
 */

.o-table__action {
  opacity: 0;
}

table.o-table__selected .o-table__selection {
  opacity: 1;
}

.o-table--hover {
  & tbody>tr:not(.o-table__row--group):hover {
    background-color: map-get($colors, stable-lightest);
  }
}

tr:hover .o-table__action {
  opacity: 1;
}

.o-table__action--checked {
  opacity: 1;
}

/**
 * PDF modifier
 */

.o-table--pdf {
  page-break-inside: avoid;
}

.o-table--pdf .o-table__data,
.o-table--pdf .o-table__header {
  padding-top: 3mm;
  padding-bottom: 3mm;
  font-size: 8pt;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

.o-table--page-container {
  z-index: 0;
  position: absolute;
  top: 80px;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;

  @include breakpoint(small only) {
    position: relative;
    top: 0;
  }
}

.o-table__load-more {
  text-align: center;
  margin-top: 1rem;
}

.o-table__placeholder--cell {
  display: flex;
  height: 50px;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  padding: 10px;

  &>div {
    display: block;
    background-color: map-get($colors, stable-lighter);
    border-radius: 5px;
    width: 100%;
    height: 100%;
  }
}