/**
 * Textual card component
 */

.c-textual-card {
  @include box-shadow(0px 3px 0px 0px rgba(0, 0, 0, 0.08));
  @include transition(box-shadow 0.1s $smooth-ease);
  background-color: map-get($colors, light);
  border: 1px solid map-get($colors, stable);
  border-radius: $border-radius;
  display: block;
  padding: $gutter;
  position: relative;
}

.c-textual-card:not(.c-textual-card--not-clickable):not(.c-textual-card--no-hover):hover,
.draggable-scene:hover .c-textual-card {
  @include box-shadow(0px 3px 12px 0px rgba(0, 0, 0, 0.24));
}

.c-textual-card--not-clickable,
.c-textual-card--clickable,
.c-textual-card--no-padding {
  padding: 0;
}

.c-textual-card--not-clickable {
  pointer-events: none;
}

.c-textual-card--error {
  border: 1px solid map-get($colors, assertive);
  color: map-get($colors, assertive);
}

.c-textual-card--launcher {
  margin-top: 20px;
  @media #{$large-up} {
    width: 460px;
  }
}

.c-textual-card--empty {
  background-color: transparent;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  // margin-top: 10px !important;
}

/**
 * On diagram
 */

.c-flip--flipped .c-flip__front .c-textual-card--on-diagram,
.c-flip--flipped .c-flip__front .c-textual-card--on-diagram:hover {
  box-shadow: none;
}

.c-textual-card--on-diagram {
  @include noselect;
  min-height: 190px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

/**
 * Inverted modifier
 */

.c-textual-card--inverted {
  border-color: map-get($colors, dark-light);
  background-color: map-get($colors, dark-light);
}

.c-textual-card--inverted .c-textual-card__title {
  color: map-get($colors, light);
}

/**
 * Small modifier
 */

.c-textual-card--small {
  padding: 20px;
}

.c-textual-card--small .c-textual-card__title {
  height: calc(#{map-get($fonts, xs)} * 2 * 1.5);
}

/**
 * Title element
 */

.c-textual-card__title {
  height: calc(
    #{map-get($fonts, l)} * 2 * 1.3
  ); // font-size * number of lines * line-height
  overflow: hidden;
  color: map-get($colors, dark);
  margin: $gutter*0.33 0 $gutter*0.5;
}

.c-textual-card__title--auto-height {
  height: auto;
}

.c-textual-card__title--extended {
  height: calc(
    #{map-get($fonts, l)} * 4 * 1.3
  ); // font-size * number of lines * line-height
  margin-bottom: -5px;
}

/**
 * Content element
 */

.c-textual-card__content {
  padding: $gutter;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  table {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

/**
 * Selected state
 */

[type='radio']#value-1:checked ~ * .c-textual-card[for='value-1'],
[type='radio']#value-2:checked ~ * .c-textual-card[for='value-2'],
[type='radio']#value-3:checked ~ * .c-textual-card[for='value-3'] {
  border-color: map-get($colors, assertive);
  @include box-shadow(0 3px 0 0 map-get($colors, assertive));

  path {
    fill: map-get($colors, assertive);
  }
}
