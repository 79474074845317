.o-alert {
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 5px 18px;
  border: 1px solid map-get($colors, stable-dark);
  background-color: map-get($colors, stable);
}
.o-alert--danger {
  color: map-get($colors, assertive);
  border: none;
  background-color: rgba(231, 70, 26, 0.1);
}
.o-alert--warning {
  color: map-get($colors, royal);
  border: none;
  height: 60px;
  background-color: rgba(232, 198, 10, 0.1);
}
.o-alert--success {
  color: map-get($colors, success);
  border: none;
  height: 60px;
  background-color: rgba(112, 200, 184, 0.1);
}
.o-alert--info {
  color: map-get($colors, balanced);
  border: none;
  height: 60px;
  background-color: rgba(84, 138, 185, 0.1);
}
.o-alert--inner {
  max-width: 1400px;
  margin: 0 auto;
  align-items: center;
  height: 100%;
  font-weight: bold;
}
.alert-icon {
  margin-right: 10px;
  font-size: 18px;
}
.alert-container {
  position: fixed;
  top: 20px;
  min-width: 550px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1006;
}
.alert {
  background-color: map-get($colors, dark-light);
  position: relative;
  padding: 22px;
  padding-right: 32px;
  font-size: 14px;
  border-radius: 5px;
  margin-top: 10px;
  color: map-get($colors, light);
  & h2 {
    margin-bottom: 0;
  }
  &.alert {
    &-success {
      color: map-get($colors, success);
    }
    &-warning {
      color: map-get($colors, assertive);
    }
    &-info {
      color: map-get($colors, balanced);
    }
  }
  & .alert-subtitle {
    color: map-get($colors, stable-dark);
    font-size: 14px;
  }
  & .alert-close {
    font-size: 14px;
    color: map-get($colors, light);
    position: absolute;
    top: 22px;
    right: 22px;
  }
}
