/**
 * Side menu component
 */

.c-side-menu {
  background-color: map-get($colors, stable-lightest);
  border-radius: $border-radius;
  list-style: none;
  margin: 0 0 30px 0;
  padding-left: 0;

  @media #{$medium-up} {
    margin: 0;
  }
}

.c-side-menu--clean {
  background: none;
  border: 0;
  margin-bottom: 20px;
}

$side-menu-columns: 3;

.c-side-menu--with-group {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 60px);
}

/**
 * Group element
 */

.c-side-menu__group {
  max-height: calc(100vh); //this is also setted in JS
  overflow-y: auto;
  border-bottom: 1px solid map-get($colors, stable-light);
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  position: relative;
  transition: max-height $smooth-ease 0.1s;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .c-side-menu__group {
    max-height: calc(100vh - 250px) !important;
  }
}

.c-side-menu__group-inner {
  list-style: none;
  margin: 0;
}

/**
 * Item element
 */

.c-side-menu__item {
  @include transition(background-color 0.3s $smooth-ease);
  background-color: map-get($colors, light);
  border: 1px solid map-get($colors, stable-light);
  border-top-width: 0;
  padding: 20px 20px 15px 20px;
  position: relative;

  .c-side-menu > &:first-child {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    border-top-width: 1px;
  }

  .c-side-menu > &:last-child,
  .c-side-menu__group-inner > &:last-child {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    border-bottom-width: 1px;
  }

  &.active,
  &:hover {
    background-color: map-get($colors, stable-light);

    .c-side-menu__title {
      color: map-get($colors, dark);
    }
  }
}

.c-side-menu--clean .c-side-menu__item,
.c-side-menu--clean .c-side-menu__item:first-child,
.c-side-menu--clean .c-side-menu__item:last-child {
  padding: 20px 0;
  background: none;
  border-radius: 0;
  border-left-width: 0;
  border-top-width: 0;
  border-right-width: 0;
}

.c-side-menu--clean .c-side-menu__item:first-child {
  padding-top: 0;
  border-bottom: 1px solid map-get($colors, stable-dark);
}

.c-side-menu--clean .c-side-menu__item:last-child {
  border-bottom: 1px solid map-get($colors, stable-light);
}

.c-side-menu__item--with-label {
  padding-right: 50px;
}

.c-side-menu__item--with-double-label {
  padding-right: 80px;
}

.c-side-menu__item--more {
  padding: 10px;
}

/**
 * Load more element
 */

.c-side-menu__load-more {
  display: block;
  text-align: center;
  font-size: map-get($fonts, s);
  padding: 10px;
  border-top: 1px solid map-get($colors, stable-light);
  margin-top: -1px;

  &,
  &:active,
  &:focus {
    color: map-get($colors, stable-dark);
  }

  &:hover {
    color: map-get($colors, dark);
  }
}

/**
 * Label element
 */

.c-side-menu__label {
  position: absolute;
  top: 20px;
  right: 20px;
}

/**
 * Title element
 */

.c-side-menu__title {
  @include transition(color 0.3s $smooth-ease);
  @include paragraph;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  svg {
    margin-left: 15px;
  }
}

.c-side-menu__title--stable-dark {
  color: map-get($colors, stable-dark) !important;
}

/**
 * Subtitle element
 */

.c-side-menu__subtitle {
  @include paragraph;
  color: map-get($colors, stable);
  text-decoration: none;
}

/**
 * Points element
 */

.c-side-menu__points {
  @include paragraph;
  font-size: map-get($fonts, s);
}

.c-side-menu__points--score-1 {
  color: map-get($colors, royal);
}

.c-side-menu__points--score-2 {
  color: map-get($colors, assertive);
}

/**
 * Link element
 */

.c-side-menu__link {
  p:last-child {
    margin-bottom: 0;
  }

  &:hover {
    text-decoration: none;
  }
}

/**
 * Stars container
 */

.c-side-menu__stars {
  text-align: right;
  white-space: nowrap;
  position: absolute;
  bottom: 10px;
  right: 20px;
}
