/**
 * Visual card component
 */

.c-visual-card {
  @include box-shadow(0px 3px 0px 0px rgba(0, 0, 0, 0.08));
  @include transition(box-shadow .3s $smooth-ease);
  border: 1px solid map-get($colors, stable);
  border-radius: $border-radius;
  position: relative;
}

.c-visual-card:not(.c-visual-card--no-hover):hover {
  @include box-shadow( 0px 3px 12px 0px rgba(0,0,0,0.24) );

  > .o-action-list {
    @media #{$medium-up} {
      left: 100%;
      opacity: 1;
      z-index: 1;
    }
  }

  > .o-action-list--inner-hover {
    @media #{$medium-up} {
      left: calc(100% - 52px);
    }
  }
}


/**
 * Visual element
 */

.c-visual-card__visual {
  @include aspect-ratio(2, 1);
  background-position: center;
  background-size: cover;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  display: block;
}



.c-visual-card__visual--audio {
  background-color: map-get($colors, stable-light);
  background-image: url('../../images/icon-audio.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
}

.c-visual-card__visual--loading {
  background-color: map-get($colors, stable-light);
  background-image: url('../../images/load.gif');
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  height: 230px;
  display: block;
}

/**
 * Content element
 */

.c-visual-card__content {
  background-color: map-get($colors, light);
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  padding: 30px;
}

/**
 * Content hover
 */

.c-visual-card__content-hover {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: map-get($colors, dark-light);
  border-radius: $border-radius;
  transition: opacity $smooth-ease 0.25s;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 10px;
  text-align: center;

  :hover > & {
    opacity: 1;
    transition: opacity $smooth-ease 0.25s;
  }
}

/**
 * Content element
 */

.c-visual-card__title {
  color: map-get($colors, dark);
  overflow: hidden;
  text-overflow: ellipsis;
}

/**
 * New style
 */

.c-visual-card-new .c-visual-card__visual:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 50%;
  background-image: url('../../images/visual-card-overlay.png');
  background-position-y: bottom;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 5px;
}

.c-visual-card-new .c-visual-card__visual {
  border-radius: 5px;
}

.c-visual-card-new .c-visual-card__content {
  position: absolute;
  bottom: 0px;
  background: transparent;
  padding: 20px 20px 20px 25px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.c-visual-card-new .c-visual-card__content h2 {
  color: #fff;
  font-weight: 700;
  margin-bottom: 0px;
}

.c-visual-card__logo-container {
  width: 60px;
  height: 60px;
  min-width: 60px;
  min-height: 60px;
  background-color: #fff;
  border-radius: 30px;
  margin-right:15px;
}

.c-visual-card__logo-container .c-visual-card__logo {
  width: 42px;
  height: 42px;
  background-size: contain;
  margin-left: 9px;
  margin-top: 9px;
}

.c-visual-card__more {
  display: block;
  border: 1px solid map-get($colors, stable);
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  margin-top: -5px;
  padding-top: 15px;
  padding-bottom: 10px;

  font-weight: 700;
  letter-spacing: $letter-spacing;
  color: map-get($colors, stable-dark);

  &:hover, &:focus, &:active {
    color: map-get($colors, dark);
    cursor: pointer;
  }

}
