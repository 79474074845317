/**
 * Slider object
 */

.o-slider {
  position: relative;
}

.o-slider__label {
  @include paragraph;
  color: map-get($colors, dark);
  margin-top: 20px;
}

.o-slider__tooltip {
  display: inline;
  margin-left: 8px;
  vertical-align: middle;
  border: none;
}

.o-slider__time {
  @include paragraph;
  display: block;
  margin-top: 20px;
  &--underline {
    text-decoration: underline;
  }
}

.o-slider__start,
.o-slider__end {
  color: map-get($colors, stable);
  font-size: 11px;
  position: absolute;
  top: 0;
}

.o-slider__start {
  left: 0;
}

.o-slider__end {
  right: 0;
}

/**
 * Slider element
 */

.o-slider__slider {
  padding-top: 25px;
  position: relative;
}

.o-slider__slider .noUi-target {
  background: map-get($colors, stable-lighter);
  border: none;
  border-radius: 0;
  box-shadow: none;
  height: 4px;
}

.o-slider__slider .noUi-origin {
  background: map-get($colors, dark);
}

.o-slider__slider .noUi-handle {
  background: map-get($colors, dark);
  border: 1px solid map-get($colors, light);
  border-radius: 20px;
  box-shadow: none;
  height: 20px;
  width: 20px;
  left: -10px;
  top: -8px;

  &:before,
  &:after {
    background: map-get($colors, light);
    border-radius: 1px;
    height: 8px;
    width: 1px;
    top: 5px;
  }

  &:before {
    left: 7px;
  }

  &:after {
    left: 10px;
  }
}

/**
 * Video modifier for slider
 */

.o-slider__slider--video {
  padding-top: $gutter;
}

.o-slider__slider--video .noUi-origin {
  background: map-get($colors, stable-lighter);

  &:before {
    content: '';
    background: map-get($colors, assertive);
    border-radius: 2px;
    width: 2px;
    position: absolute;
    top: 0;
    left: -1px;
  }
}

.o-slider__slider--video--with-long-slider .noUi-origin {
  &:before {
    height: 135px;
  }
}

.o-slider__slider--video .noUi-handle {
  background: map-get($colors, assertive);
}

.o-slider__slider--video-simple .noUi-origin:before {
  display: none;
}

.o-slider__hover:hover {
  cursor: pointer;
  transition: background-color 300ms ease-in-out;
  margin-left: -0.5rem;
  padding-left: 0.5rem;
  border-radius: 5px;
  background-color: map-get($colors, stable-light);
}
.o-slider__invalid {
  animation: invalid 600ms;
  opacity: 1;
}
@keyframes invalid {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
