/**
 * Workarea component
 */

.c-workarea {
  margin-top: rem-calc(40);
  position: relative;
  flex-grow: 1;
}

.c-workarea--fit {
  padding: 0;
  margin-top: 0;
  position: relative;
  overflow: auto;
  height: 1px; // flexbox needs height to work properly
}

// view options on canvas view
.c-workarea__view-options {
  position: fixed;
  top: 180px;
  z-index: 2;
  right: 80px;
  @include noselect;
}

.c-workarea__view-options-descr {
  color: map-get($colors, stable);
  display: inline-block;
  vertical-align: middle;
  text-align: right;
  margin: 0 5px 0 0;
  font-size: map-get($fonts, xs);
}
