/**
 * Stats component
 */

.c-stats {
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin: 30px 0;
  padding: 0;
  width: 100%;
}

/**
 * Item element
 */

.c-stats__item {
  border-right: 1px solid map-get($colors, stable-light);
  flex-grow: 1;
  margin-right: 20px;
  min-height: 65px;
  position: relative;
}

.c-stats__item:last-child {
  border: none;
  margin-right: 0;
  max-width: 125px;
}

/**
 * Title element
 */

.c-stats__title {
  @include headingLarge;
}

/**
 * Subtitle element
 */

.c-stats__subtitle {
  margin-bottom: 0;
  position: absolute;
  bottom: 0;
  left: 0;
}
