@use 'sass:color';
/**
 * Draggable element
 * while not dragging
 */

.react-draggable  {
}

/**
 * Draggable element
 * while dragging a line end on it
 */

.react-draggable-dragging {

  .c-textual-card {
    box-shadow: 0 10px 20px rgba(0,0,0,.3) !important;
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }

  .o-action-list {
    opacity: .1 !important; // (almost) hide the action list while dragging
  }

  &.c-connector {
    opacity: 1 !important;
  }

}

/**
 * Selected element
 * if an element is selected
 */

.react-draggable-drop-on-active {
  
  .c-textual-card, .c-dark-card {
    border-color: map-get($colors, assertive);
    background-color: color.adjust(map-get($colors, assertive), $lightness: 47%);
  }
  
  .c-textual-card--inverted {
    background-color: map-get($colors, dark-light);
  }

  .c-dark-card {
    @include box-shadow( 0px 3px 0 0px map-get($colors, assertive) );
  }
}
