.c-flip {
  position: relative;
  perspective: 800px;
}

.c-flip--card {
  width: 270px;
}

.c-flip__holder {
  width: 100%;
  height: 100%;
  position: absolute;
  transform-style: preserve-3d;
  transition: transform 0.25s;
}

.c-flip--flipped .c-flip__holder {
  transform: rotateY(180deg);
}

.c-flip__front,
.c-flip__back {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  margin: 0;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: visibility 0.125s;
}

.c-flip--flipped .c-flip__front {
  visibility: hidden; // for IE11
}

.c-flip--flipped .c-flip__back {
  -webkit-backface-visibility: visible;
  -moz-backface-visibility: visible;
  -ms-backface-visibility: visible;
  backface-visibility: visible;
}

.c-flip__front {
  z-index: 2;
  transform: rotateY(0deg); /* for firefox 31 */
}

.c-flip__back {
  transform: rotateY(180deg);
}
