/**
 * Banner component
 */

.c-banner {
  align-items: center;
  background-image: url('../../images/banner-bg.jpg');
  background-position: center;
  background-size: cover;
  display: flex;
  height: 70px;
  justify-content: center;
  padding: 0 60px;
  position: relative;
  text-align: center;
  width: 100%;

  @media #{$medium-up} {
    height: 100vh;
    width: 35%;
  }

  @media #{$large-up} {
    width: 420px;
  }
}

/**
 * logo element
 */

.c-banner__logo {
  background-image: url('../../images/logo-light.svg');
  background-position: center;
  background-size: cover;
  height: 34px;
  width: 89px;
  position: absolute;
  top: 25px;

  @media #{$medium-up} {
    top: 60px;
  }
}

/**
 * Title element
 */

.c-banner__title {
  display: none;

  @media #{$medium-up} {
    @include heading1;
    color: map-get($colors, light);
    display: block;
    font-weight: 700;
  }
}
