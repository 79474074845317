@use 'sass:color';
$canvas-width: 20000px;
$canvas-height: 10000px;
.c-canvas {
  background-image: url('../../images/grid.svg');
  background-position: top left;
  background-color: map-get($colors, stable-lightest);
  padding: 10px;
  position: relative;
  cursor: default;
}

.c-canvas__select {
  border-radius: $border-radius;
  border: 1px solid map-get($colors, assertive);
  background: color.adjust(map-get($colors, assertive), $alpha: -0.9);
}
