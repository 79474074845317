.c-body {
  font-family: $font-stack;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
  overflow-x: hidden;
  min-width: 320px;
  min-height: 100vh;
  letter-spacing: $letter-spacing;
}

.c-body--test_scenarios {
  background: map-get($colors, light);
}

//shame
.c-body--test_scenarios .row {
  max-width: 56rem !important;
}

.c-body--draggable_cards {
  user-select: none;
}
