@use 'sass:color';
/**
 * Snackbar component
 */
.c-snackbar {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.25s;
}

.c-snackbar--in-content {
  padding: $gutter * 0.5 $gutter * 0.66;
  max-height: inherit;
  border-radius: $border-radius;
}

.c-snackbar--positive {
  background-color: color.adjust(map-get($colors, energized), $alpha: -0.9);

  .c-snackbar__content {
    padding: 20px 0;
    color: map-get($colors, energized);
  }
}

.c-snackbar--negative {
  background-color: color.adjust(map-get($colors, assertive), $alpha: -0.9);

  .c-snackbar__content {
    color: map-get($colors, assertive);
  }
}

.c-snackbar--loaded {
  max-height: 100px;
  transition: max-height 0.25s;
}

/**
 * Content element
 */

.c-snackbar__content {
  @include heading3;
  font-weight: 500;
  margin: 0;
  padding: 20px 0;
}

.c-snackbar--in-content .c-snackbar__content {
  @include heading4;
  margin: 0;
  padding: 0;
}
