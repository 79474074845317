.o-tabs--inline {
  display: flex;
  margin-left: 0;
  margin-bottom: 0;
  border: none;
  border-bottom: 1px solid map-get($colors, stable);
}
.o-tabs {
  border: none;
  margin-left: 0;
  display: flex !important;
  flex-direction: column;
}
.o-tabs-title--inline {
  display: flex;
  cursor: pointer;
  align-items: center;
  font-size: 14px;
  color: map-get($colors, stable-dark);
  font-weight: bold;
  height: 42px;
  margin-left: 30px;
  border-bottom: 2px solid transparent;
  &:hover {
    transition: all 600ms ease-in-out;
    color: map-get($colors, assertive);
    border-bottom: 2px solid map-get($colors, assertive);
  }
  &.is-active {
    transition: all 300ms ease-in-out;
    color: map-get($colors, assertive) !important;
    border-bottom: 2px solid map-get($colors, assertive);
  }
}
.o-tabs-title {
  display: flex;
  cursor: pointer;
  align-items: center;
  padding-left: 15px;
  font-size: 14px;
  letter-spacing: -0.7px;
  color: map-get($colors, stable-dark);
  height: 42px;
  width: 100%;
  border-radius: 5px;
  &:hover {
    transition: all 600ms ease-in-out;
    color: map-get($colors, dark-light) !important;
  }
  &.is-active {
    transition: all 300ms ease-in-out;
    background-color: map-get($colors, gray-background);
    color: map-get($colors, dark-light) !important;
    font-weight: bold;
  }
}
.o-tabs-content {
  border: none;
}
.o-tabs-panel {
  border: none;
  &.is-active {
    animation: fade-in 200ms ease-in-out;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
