h1 {
  @include heading1;
}
h2 {
  @include heading2;
}
h3 {
  @include heading3;
}
h4 {
  @include heading4;
}
h5 {
  @include heading5;
}
p {
  @include paragraph;
}

::-moz-selection {
  background: map-get($colors, assertive);
  color: #fff;
  text-shadow: none;
}

::selection {
  background: map-get($colors, assertive);
  color: #fff;
  text-shadow: none;
}

.row-fluid {
  @include clearfix;
  margin: 1.875rem -0.9375rem;
}

.column-fluid {
  max-width: 300px;
  float: left;
  width: 25%;
  margin-bottom: 1.875rem;

  &:last-child:not(:first-child) {
    float: left;
  }
}

g:focus, path:focus {
  outline: none;
}
