@use 'sass:color';

/**
 * Action list component
 */

.o-action-list {
  @include transition(left 0.15s $smooth-ease, opacity 0.1s $smooth-ease);
  list-style: none;
  margin: 0;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;

  @media #{$medium-up} {
    opacity: 0;
    padding-left: 10px;
    top: 0;
    left: calc(100% - #{$input-height});
    right: auto;
    z-index: 9;
  }

  &:hover,  {
    @media #{$medium-up} {
      left: 100%;
      opacity: 1;
      z-index: 9;
    }
  }
}

.o-action-list--top {
  @include transition(bottom 0.15s $smooth-ease, opacity 0.1s $smooth-ease);
  padding-left: 0;
  bottom: 80%;
  top: auto;
  left: 0;
  right: 0;
  text-align: center;
}

// always show (not only on hover)
.o-action-list--top-persistant,
.o-action-list--top-persistant:hover {
  left: 0;
  right: 0;
  bottom: 100%;
  opacity: 1;
  z-index: 99;
}

/**
 * Hover of wrapper
 */

.draggable-scene:not(.is-dragging),
.c-textual-card,
.c-dark-card {
  &:hover .o-action-list {
    @media #{$medium-up} {
      left: 100%;
      opacity: 1;
      z-index: 99;
    }
  }

  &:hover .o-action-list--top {
    @media #{$medium-up} {
      left: 0;
      right: 0;
      bottom: 100%;
      opacity: 1;
      z-index: 99;
    }
  }
}

/**
 * Item element
 */

.o-action-list__item {
  @media #{$small-only} {
    display: inline-block;
    margin-left: 10px;
  }

  @media #{$medium-up} {
    margin-bottom: $gutter*0.16;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.o-action-list--top .o-action-list__item {
  display: inline-block;
  margin: 0 5px 0 0;

  &:last-child {
    margin: 0;
  }
}

/**
 * Button element
 */

.o-action-list__button {
  background-color: color.adjust(map-get($colors, dark), $alpha: -0.2);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 40%;
  border-radius: $border-radius;
  display: block;
  height: calc(#{$input-height} - 10px);
  width: calc(#{$input-height} - 10px);

  @media #{$medium-up} {
    height: $input-height;
    width: $input-height;
  }

  &:hover {
    background-color: color.adjust(map-get($colors, dark), $alpha: -0.3);
  }
}

.o-action-list__button--edit {
  background-image: url('../../images/icon-edit.svg');
}

.o-action-list__button--eye {
  background-image: url('../../images/icon-eye.svg');
}

.o-action-list__button--shuffle {
  background-image: url('../../images/icon-shuffle.svg');
}

.o-action-list__button--more {
  background-image: url('../../images/icon-more.svg');
}

.o-action-list__button--test {
  background-image: url('../../images/icon-bug-light.svg');
}

.o-action-list__button--delete {
  background-image: url('../../images/icon-delete-light.svg');
}

.o-action-list__button--download {
  background-image: url('../../images/icon-arrow-down.svg');
}
