.loading__container {
  display: grid;
  place-items: center;
}
.loading__container--height {
  height: 80vh;
}
.loading__container--inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.loading-bars {
  position: relative;
  width: 80px;
  height: 80px;
}
.loading-bars div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: map-get($colors, stable-light);
  animation: loading-bars 1s ease infinite;
}
.loading-bars div:nth-child(1) {
  left: 0px;
  animation-delay: 0s;
}
.loading-bars div:nth-child(2) {
  left: 30px;
  animation-delay: 0.1s;
}
.loading-bars div:nth-child(3) {
  left: 60px;
  animation-delay: 0.2s;
}
@keyframes loading-bars {
  0% {
    top: 24px;
  }
  20% {
    top: 0px;
  }
  40%,
  100% {
    top: 24px;
  }
}

.loading-dual-ring-container {
  display: grid;
  place-items: center;
  color: map-get($colors, assertive);
  width: 100px;
  height: 100px;
}
.loading-dual-ring {
  position: relative;
  width: 80px;
  height: 80px;
  display: grid;
  place-items: center;
}
.loading-dual-ring:after {
  content: ' ';
  position: absolute;
  width: 82px;
  height: 82px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: map-get($colors, assertive) transparent
    map-get($colors, assertive) transparent;
  animation: loading-dual-ring 6s linear infinite;
}
@keyframes loading-dual-ring {
  0% {
    opacity: 0.2;
    transform: rotate(0deg);
  }
  20%,
  30% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  70%,
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
    transform: rotate(360deg);
  }
}

.alert__banner--loading {
  height: 100%;
  font-size: 14px;
  letter-spacing: -0.7px;
  width: 100%;
  color: map-get($colors, balanced);
  display: inline-flex;
  align-items: center;
}
.alert__banner--icon--animated {
  animation: rotate360 2s linear infinite;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes rotate360 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
