.o-accordion--container {
  display: flex;
  flex-direction: column;
}
.o-accordion--row {
  margin-bottom: 2px;
}
.o-accordion--header {
  width: 100%;
  cursor: pointer;
  padding: 0 20px;
  height: 60px;
  background-color: map-get($colors, gray-background);
  font-weight: bold;
  display: inline-flex;
  border-radius: 5px;
  transition: all 300ms ease-in-out;
  align-items: center;
  flex-direction: row;
  &:hover {
    background-color: map-get($colors, stable);
  }
}
.o-accordion--prefix {
  margin-right: 10px;
  height: 16px;
  width: 16px;
  transition: all 300ms ease-in-out;
  &.is-active {
    transform: rotate(-180deg);
  }
}
.o-accordion--title {
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 10px;
}
.o-accordion--suffix {
  color: map-get($colors, stable-dark);
  font-size: 14px;
  letter-spacing: -0.7px;
  white-space: nowrap;
  font-weight: normal;
}
.o-accordion--content {
  height: 0px;
  overflow: hidden;
  &.is-active {
    height: auto;
  }
}
