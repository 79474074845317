/**
 * Label object
 */

.o-label--item__disabled {
  margin-left: 20px;
  background-color: map-get($colors, stable-lighter);
  border-radius: 5px;
  border: 1px solid transparent;
  color: map-get($colors, stable-dark);
  font-size: 12px !important;
  font-weight: lighter;
  padding: 0 10px;
  display: inline-block;
}

.o-label--custom {
  border: 1px solid transparent;
  border-radius: 5px;
  color: map-get($colors, dark-light);
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;
  min-width: 25px;
  padding: 0 10px;
  white-space: nowrap;
  flex-shrink: 0;

  &:before {
    display: inline-block;
    position: relative;
    top: 2px;
    width: 15px;
  }
}

.o-label {
  border: 1px solid transparent;
  border-radius: 3px;
  color: map-get($colors, dark-light);
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;
  min-width: 25px;
  padding: 0 5px;
  white-space: nowrap;

  &:before {
    display: inline-block;
    position: relative;
    top: 2px;
    width: 15px;
  }
}

.o-label--trainees {
  font-weight: 400 !important;
  color: white;
}

a.o-label:hover {
  color: map-get($colors, light);
  opacity: 0.7;
}

.o-label+.o-label {
  margin-bottom: 5px;
}

.o-label svg {
  margin-left: 5px;
  margin-top: 5px;
  float: right;
}

.o-label svg.u-pull-left {
  margin-right: 5px;
  margin-left: 0;
  float: left;
}

.o-label svg path {
  fill: map-get($colors, light);
}

.o-label--centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/**
 * Color modifiers
 */

.o-label--energized {
  background-color: map-get($colors, energized) !important;
}

.o-label--stable-dark {
  background-color: map-get($colors, stable-dark) !important;
  color: white;
}

.o-label--stable-lighter {
  background-color: map-get($colors, stable-lighter) !important;
}

.o-label--stable {
  background-color: map-get($colors, stable) !important;
}

.o-label--no-background {
  background-color: transparent !important;
}

.o-label--dark {
  color: white;
  background-color: map-get($colors, dark) !important;
}

.o-label--dark-light {
  color: white;
  background-color: map-get($colors, dark-light) !important;
}

.o-label--balanced {
  background-color: map-get($colors, balanced) !important;
}

.o-label--assertive {
  background-color: map-get($colors, assertive) !important;
}

.o-label--royal {
  background-color: map-get($colors, royal) !important;
}

.o-label--danger {
  background-color: map-get($colors, danger) !important;
}

.o-label--success {
  background-color: map-get($colors, success) !important;
}

.o-label--grey {
  background-color: transparent;
  border-color: map-get($colors, stable);
  color: map-get($colors, stable);
}

.o-label--hollow {
  background-color: transparent;
  border-color: map-get($colors, assertive);
  color: map-get($colors, assertive);
}

a.o-label--hollow:hover {
  color: map-get($colors, assertive);
}

.o-label--transparent {
  color: map-get($colors, stable);
  padding: 2px 0;
}

a.o-label--transparent:hover {
  color: map-get($colors, stable);
}

.o-label--highscore {
  font-size: 12px;
  color: map-get($colors, success);
  background-color: rgba(map-get($colors, success), 0.1) !important;

  svg {
    path {
      fill: map-get($colors, success) !important;
    }
  }
}

.o-label--test {
  font-size: 12px;
  color: map-get($colors, royal);
  background-color: rgba(map-get($colors, royal), 0.1) !important;

  svg {
    path {
      fill: map-get($colors, royal) !important;
    }
  }
}

.o-label--scene-ending {
  padding-top: 2px;

  svg {
    float: none;
  }
}

.o-label--filming-group {
  font-size: 14px;
  font-weight: bold;
  color: map-get($colors, dark-light);
  background-color: rgba(map-get($colors, dark), 0.1);
  height: 32px !important;
  display: flex !important;
  align-items: center;
  padding: 10px !important;

  &.o-label--missing-location {
    color: map-get($colors, assertive);
    background-color: rgba(map-get($colors, assertive), 0.1) !important;
  }
  &.o-label--do-not-require-filming {
    color: map-get($colors, stable-dark);
    background-color: rgba(map-get($colors, stable-dark), 0.1) !important;
  }
}


/**
 * Icon modifiers
 */

.o-label--home:before {
  content: url('../../images/icon-home.svg');
  position: relative;
  top: 0;
  left: 0;
  margin-right: -1px;
}

.o-label--randomized:before {
  content: url('../../images/icon-shuffle-alt.svg');
  position: relative;
  top: 3;
  left: 0;
  margin-right: -1px;
}

.o-label--link:before {
  content: url('../../images/icon-link.svg');
}

.o-label--missing-link:before {
  content: url('../../images/icon-missing-link.svg');
}

.o-label--mpc:before {
  content: url('../../images/icon-list.svg');
  position: relative;
  top: 2px;
}

.o-label--direction:before {
  content: url('../../images/icon-arrow-up.svg');
  position: relative;
  top: 1px;
  margin-right: -1px;
}

.o-label--search:before {
  content: url('../../images/icon-viewer.svg');
}

.o-label--flag:before {
  content: url('../../images/icon-flag-light.svg');
}

.o-label--clock:before {
  top: 1px;
  content: url('../../images/icon-clock-light.svg');
}

.o-label--information:before {
  content: url('../../images/icon-information.svg');
  vertical-align: middle;
}

.o-label--transition:before {
  content: url('../../images/icon-transition.svg');
  vertical-align: middle;
}

.o-label--cup:before {
  content: url('../../images/icon-cup.svg');
  position: relative;
  top: 1px;
}

.o-label--hotspot:before {
  content: url('../../images/icon-scene-hotspot-alt.svg');
}

.o-label--video:before {
  content: url('../../images/icon-video.svg');
  position: relative;
  top: 0;
  left: 1px;
  margin-right: -1px;
}

.o-label--progress:before {
  content: url('../../images/icon-progress.svg');
  position: relative;
  margin-right: 2px;
  margin-top: 1px;
}

.o-label--testing:before {
  content: url('../../images/icon-bug.svg');
  position: relative;
  top: 1px;
}

/**
 * Content element
 */

.o-label__content {
  margin-left: 5px;
  letter-spacing: $letter-spacing;
  max-width: 150px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
}

/**
 * Status Labels
 */

.o-label--status {
  padding: 0px 16px;
  height: 30px;
  line-height: 30px;
  border-radius: 15px;
  color: white;
  display: inline-block;
  z-index: 1;
  font-weight: bold;

  &:not(:last-child) {
    margin-right: 5px;
  }

  &:disabled {
    cursor: not-allowed;
  }
}