.Frill_Badge {
  right: 12px !important;
  top: 13px !important;
  background-color: #e7461a !important;
  cursor: pointer;
}

.Frill_Trigger {
  cursor: pointer;
}
