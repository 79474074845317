/**
 * Radio object
 */

.o-radio {
  @include paragraph;
  color: map-get($colors, dark);
  display: block;
  margin-bottom: 10px;
  overflow: hidden;
  position: relative;
}

.o-radio--disabled {
  pointer-events: none;
  opacity: 0.35;
}

.o-radio input {
  position: absolute;
  top: -100px;
  left: -100px;
}

.o-radio span:not([data-tooltip]) {
  border: 1px solid map-get($colors, stable-light);
  border-radius: 18px;
  display: inline-block;
  height: 18px;
  margin-right: 10px;
  position: relative;
  top: 4px;
  width: 18px;
}

.o-radio em {
  font-style: normal;
}

.o-radio input:focus + span {
  border-color: map-get($colors, stable-dark);
}

.o-radio input:checked + span:before {
  content: '';
  background-color: map-get($colors, assertive);
  border-radius: 10px;
  display: block;
  height: 10px;
  position: absolute;
  top: 3px;
  left: 3px;
  width: 10px;
}

// new radio component
$spacing-unit: 20px;
$half-spacing-unit: $spacing-unit * 0.5;
$quarter-spacing-unit: $spacing-unit * 0.25;

.o-radio__custom {
  margin: 0 !important;
  min-height: 0 !important;
  visibility: hidden;
  width: 0px;
}
.o-radio__custom--label {
  line-height: 2 !important;
  font-weight: normal !important;
  color: map-get($colors, dark);
  transition: all 300ms ease-in-out;
  display: inline-block;
  position: relative;
  margin-right: $spacing-unit;
  padding-left: $spacing-unit + $quarter-spacing-unit;
  cursor: pointer;

  &.checked {
    color: map-get($colors, dark);
    &:before {
      border-color: map-get($colors, stable);
    }
    &:after {
      height: 8px;
      width: 8px;
      margin-top: -4px;
      left: 4px;
    }
  }
  &.disabled {
    cursor: not-allowed !important;
    color: map-get($colors, stable-dark);
  }
  &:hover:not(.disabled) {
    color: map-get($colors, dark-light);
    &:before {
      transition: border-color 300ms ease-in-out;
      border-color: map-get($colors, stable-dark);
    }
  }
  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    left: 0;
    top: 50%;
    margin-top: -8px;
    border: 1px solid map-get($colors, stable);
    border-radius: 50%;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    top: 50%;
    left: 8px;
    margin-top: 0;
    background-color: map-get($colors, assertive);
    border-radius: 50%;
    transition: 200ms ease-in-out;
  }
}
