.range__input {
  display: flex;
  width: 100%;
  height: 48px;
  position: relative;
  align-items: center;
  & .track {
    position: absolute;
    left: 0;
    right: 0;
    height: 5px;
    width: 100%;
    background-color: map-get($colors, stable);
    z-index: 1;
    border-radius: 5px;

    & .red-range {
      height: 5px;
      position: absolute;
      z-index: 2;
      border-radius: 5px;
      background: #f00;
    }
  }
  &.no-container {
    position: absolute;
    top: 0px;
    height: auto;
  }
}
.range__input--thumb {
  cursor: pointer;
  &:before {
    content: url('../../images/icon-thumb-slider.svg');
    position: absolute;
    top: calc(50% - 13px);
    left: calc(50% - 2px);
  }
  z-index: 2;
  width: 20px;
  height: 20px;
  background-color: map-get($colors, dark);
  border: 1px solid white;
  border-radius: 50%;
  position: absolute;
  transform: translate(-50%);
  transition: transform 200ms ease-in-out;
  &.red-thumb {
    background-color: map-get($colors, assertive) !important;
  }
}

.range__input--thumb-info {
  white-space: nowrap;
  position: absolute;
  top: -4px;
  transform: translate(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: map-get($colors, stable);
  text-align: center;
}
.range__input--range-info {
  white-space: nowrap;
  font-size: 12px;
  color: map-get($colors, stable);
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
  justify-content: space-between;
}
