/**
 * Stars object
 */

.o-stars {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

.o-stars--small svg {
  width: 14px;
  height: 14px;
}

/**
 * Star element
 */

.o-stars__star {
  display: inline;
  margin-right: -2px;
}

/**
 * Number modifiers
 */

.o-stars--5 .o-stars__star {
  &:nth-child(5),
  &:nth-child(4),
  &:nth-child(3),
  &:nth-child(2),
  &:nth-child(1) {
    svg .o-stars__full-star {
      fill: map-get($colors, energized);
    }
  }
}

.o-stars--45 .o-stars__star {
  &:nth-child(5) {
    svg .o-stars__half-star {
      fill: map-get($colors, energized);
    }
  }

  &:nth-child(4),
  &:nth-child(3),
  &:nth-child(2),
  &:nth-child(1) {
    svg .o-stars__full-star {
      fill: map-get($colors, energized);
    }
  }
}

.o-stars--4 .o-stars__star {
  &:nth-child(4),
  &:nth-child(3),
  &:nth-child(2),
  &:nth-child(1) {
    svg .o-stars__full-star {
      fill: map-get($colors, energized);
    }
  }
}

.o-stars--35 .o-stars__star {
  &:nth-child(4) {
    svg .o-stars__half-star {
      fill: map-get($colors, energized);
    }
  }

  &:nth-child(3),
  &:nth-child(2),
  &:nth-child(1) {
    svg .o-stars__full-star {
      fill: map-get($colors, energized);
    }
  }
}

.o-stars--3 .o-stars__star {
  &:nth-child(3),
  &:nth-child(2),
  &:nth-child(1) {
    svg .o-stars__full-star {
      fill: map-get($colors, energized);
    }
  }
}

.o-stars--25 .o-stars__star {
  &:nth-child(3) {
    svg .o-stars__half-star {
      fill: map-get($colors, energized);
    }
  }

  &:nth-child(2),
  &:nth-child(1) {
    svg .o-stars__full-star {
      fill: map-get($colors, energized);
    }
  }
}

.o-stars--2 .o-stars__star {
  &:nth-child(2),
  &:nth-child(1) {
    svg .o-stars__full-star {
      fill: map-get($colors, energized);
    }
  }
}

.o-stars--15 .o-stars__star {
  &:nth-child(2) {
    svg .o-stars__half-star {
      fill: map-get($colors, energized);
    }
  }

  &:nth-child(1) {
    svg .o-stars__full-star {
      fill: map-get($colors, energized);
    }
  }
}

.o-stars--1 .o-stars__star {
  &:nth-child(1) {
    svg .o-stars__full-star {
      fill: map-get($colors, energized);
    }
  }
}
