/**
 * Colors
 */

$colors: (
  light: white,
  stable-dark: #93969f,
  stable: #cccccc,
  stable-light: #dddddd,
  stable-lighter: #eeeeee,
  stable-lightest: #f8f8f8,
  gray-background: #f5f5f2,
  balanced: #548ab9,
  energized: #70c8b8,
  success: #70c8b8,
  assertive: #e7461a,
  royal: #e8c60a,
  dark-light: #26272c,
  dark: black,
);

:root{
  @each $key, $value in $colors {
      --#{$key} : #{$value};
  }
}

/**
 * Mailer settings
 */

$mail-width: 520px;
$mail-bg-color: map-get($colors, light);
$mail-font-size: 16px;
$mail-font-stack: Helvetica, Arial, sans-serif;
$mail-color: map-get($colors, dark);

/**
 * Pixel settings
 */

$border-radius: 5px;
$input-height: 42px;
$gutter: 30px;
$letter-spacing: -0.7px; // default letter-spacing, used on all texts

/**
 * Animation easing
 */

$smooth-ease: cubic-bezier(0.7, 0, 0.3, 1);

/**
 * Font variables used in project
 */

$font-stack: 'Montserrat', sans-serif;

$fonts: (
  xxl: 36px,
  xl: 28px,
  l: 18px,
  m: 16px,
  s: 14px,
  xs: 12px,
);

/**
 * query ranges
 */

$small-only: screen and
  (
    max-width: 39.9375em,
  );
$medium-only: screen and
  (
    min-width: 40em,
  )
  and
  (
    max-width: 63.9375em,
  );
$large-only: screen and
  (
    min-width: 64em,
  )
  and
  (
    max-width: 74.9375em,
  );

$medium-up: screen and
  (
    max-width: 40em,
  );
$large-up: screen and
  (
    min-width: 64em,
  );

/**
 * Sizes
 */

$sub-menu-height: 60px;
$menu-height-small: 60px;
$menu-height-medium-up: 60px;

/**
 * PDF
 */

$pdf-padding: 20mm;
$pdf-font-stack: $font-stack;

/**
 * Table
 */

$table-row-hover: #f8f8f8;
