.c-notice {
  margin-bottom: rem-calc(40);
  position: relative;

  @media #{$medium-up} {
    background-image: url('../../images/notice-bg.png');
    background-repeat: repeat-y;
    background-size: 450px auto;
    background-position: 130% 50%;
  }

  @media #{$large-up} {
    background-position: 100% 50%;
  }

  a {
    color: map-get($colors, assertive);

    &:hover {
      color: map-get($colors, balanced);
    }
  }
}

.c-notice__title {
  @include heading2;
  color: map-get($colors, dark);
  margin-bottom: 20px;
}

.c-notice__text {
  @include paragraph;
  color: map-get($colors, stable-dark);
  margin-bottom: 20px;
}

.c-notice__table {
  background: none;
}

.c-notice__row:not([style='display: none;']) + .o-empty-state {
  @include transform(translate(0%, 0%));
  left: auto;
  top: auto;
  position: relative;
}
