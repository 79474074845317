/**
 * Menu component
 */

.c-menu {
  border-bottom: 1px solid map-get($colors, stable-light);
  padding: 0px 15px 0px 20px;

  @media print {
    display: none !important;
  }
}

/**
 * Inner element
 */

.c-menu__inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: $menu-height-small;
  position: relative;

  @media #{$medium-up} {
    height: $menu-height-medium-up;
  }

  >.flex {
    display: none;
  }
}

/**
 * list element
 */

.c-menu__list {
  list-style: none;
  margin: 0 0 0 30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0;

  @media #{$small-only} {
    @include box-shadow(0px 3px 0px 0px rgba(0, 0, 0, 0.08));
    background-color: map-get($colors, light);
    border: 1px solid map-get($colors, stable);
    border-radius: $border-radius;
    display: none;
    margin: 0;
    padding: 20px;
    position: absolute;
    top: 65px;
    right: 27px;
    z-index: 99;

    &.open {
      display: block;
    }

    &:before {
      content: url('../../images/dropdown-arrow.svg');
      position: absolute;
      top: -17px;
      right: 25px;
    }
  }
}

.c-menu__list--right {
  margin-left: auto;
}

.c-menu__list-item {
  margin: 10px;
  padding: 4px 0px;
  height: 100%;
  display: flex;
  align-items: center;
  @include heading2;
  font-size: 16px !important;
  letter-spacing: -0.8px;

  & a {
    color: map-get($colors, stable-dark);
  }

  &.active {
    padding-bottom: 0px;
    border-bottom: 4px solid map-get($colors, assertive);
  }
}

.c-menu__list-item--descr {
  line-height: 1.3;
  border-right: 1px solid map-get($colors, stable-light);
  padding-right: $gutter * 1.5;
}

.c-menu__list-item+.c-menu__list-item {
  margin-left: 30px;
}

.c-menu__list-item.active-child {
  position: relative;
}

.c-menu__list-item.active .c-menu__list-link {
  color: map-get($colors, dark);
}

.c-menu__list-link {
  @include transition(color 0.3s $smooth-ease);
  color: map-get($colors, stable-dark);
  text-decoration: none;

  &:hover {
    @include transition(color 0.3s $smooth-ease);
    color: map-get($colors, dark);
    text-decoration: none;
  }
}

/**
 * Toggle element
 */

.c-menu__toggle {
  display: none;
  margin: 0 20px 0 auto;
  position: relative;

  &.active {
    svg g {
      fill: map-get($colors, dark);
    }
  }

  @media #{$small-only} {
    display: inline-block;
  }
}

/**
 * Last
 */

.c-menu__last {
  display: flex;
  margin-left: auto;
  height: 100%;
  justify-content: flex-end;
  align-items: center;

  .dropdown-pane {
    z-index: 20;
  }
}

/**
 * Action list element
 */

.c-menu__action-list {
  list-style: none;
  margin: 0 0 0 auto;
  padding-left: 0;
}

.c-menu__action-list-item {
  display: inline-block;
}

.c-menu__action-list-button svg {
  height: 16px;
  vertical-align: middle;
}