.o-card--image {
  border: 1px solid map-get($colors, stable-light);
  border-radius: 5px;
  &.container {
    position: relative;
    padding-top: calc(100% * (1 / 2)); // aspect ratio of 2:1
    &.with-ratio-4-3 {
      padding-top: calc(100% * (3 / 4));
    }
    overflow: hidden;
    & .aspect-ratio {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      & img {
        min-width: 100%;
        min-height: 100%;
        object-fit: cover;
      }
      & .image-overlay--top-left {
        position: absolute;
        display: flex;
        align-items: center;
        padding: 20px;
        top: 0;
        left: 0;
        right: 0;
        & .shadow-container--top {
          position: absolute;
          z-index: 2;
          top: 0px;
          width: 100%;
          left: 0;
          background-image: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0),
            rgba(0, 0, 0, 0.5)
          );
          height: 80px;
        }
      }
      & .image-overlay {
        position: absolute;
        cursor: pointer;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        bottom: 0px;
        height: 100%;
        padding-bottom: 20px;
        padding-right: 20px;
        text-align: end;
        vertical-align: bottom;
        right: 0px;
        left: 0px;
        & > label {
          cursor: pointer;
        }
        &.image-overlay--disabled {
          background-color: rgba(0, 0, 0, 0.5);
          cursor: not-allowed;
          & > label {
            cursor: not-allowed !important;
          }
        }
        & .shadow-container {
          background-image: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0),
            rgba(0, 0, 0, 0.5)
          );
          position: absolute;
          right: 0;
          left: 0;
          height: 80px;
          bottom: 0px;
        }
      }
    }
  }
}
.o-card {
  border: none;
  position: relative;
  &--overflow {
    overflow: visible;
  }
}
.emulated-flex-gap {
  --gap: 12px;
  display: inline-flex;
  flex-wrap: wrap;
  margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
  width: calc(100% + var(--gap));
}

.emulated-flex-gap > * {
  margin: var(--gap) 0 0 var(--gap);
}

.flex-gap {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 12px;
}

.o-card--title {
  & h2 {
    font-size: 16px;
    font-weight: bold;
  }
  background-color: white;
  padding-left: 0px;
  padding-bottom: 5px !important;
}
.o-card--link {
  &:hover {
    text-decoration: underline;
    color: map-get($colors, assertive);
  }
}
.o-card--content {
  padding-top: 0px !important;
  padding-left: 30px;
  border: none !important;
}
.o-card__company--image {
  width: 42px;
  cursor: pointer;
  height: 42px;
  border-radius: 50%;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 28px 28px;
  z-index: 3;
}
.o-card__company--name {
  cursor: pointer;
  color: white;
  font-size: 16px;
  letter-spacing: -0.7px;
  font-weight: 700;
  z-index: 3;
  white-space: nowrap;
  overflow: hidden;
}
.o-card__purchase {
  margin-bottom: 50px;
  outline: none;
}

.o-card__purchase--title {
  margin-top: 20px;
}

.o-card__purchase--license {
  border-radius: 5px;
  border: 1px solid map-get($colors, stable-light);
  padding: 16px 20px;
  transition: all 300ms ease-in-out;
  &:hover {
    background-color: #f5f5f2;
  }
}

.o-card__integration--icon {
  background-color: map-get($colors, gray-background);
  height: 60px;
  width: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-bottom: 5px;
  padding-left: 4px;
}

.o-card__integration--verified {
  background-color: map-get($colors, success);
  width: 16px;
  height: 16px;
  color: white;
  position: absolute;
  right: -8px;
  top: -8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.o-card__integration--kind {
  background-color: map-get($colors, royal);
  color: white;
  font-size: 0.5rem;
  padding: 0.125rem 0.35rem;
  font-weight: bold;
  border-radius: 18px;
  position: absolute;
  margin-top: 38px;
}
