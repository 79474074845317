/**
 * Empty state object
 */

.o-empty-state {
  text-align: center;
  margin: 0 auto;
  max-width: 370px;

  @include breakpoint(medium) {
    @include transform(translate(-50%, -50%));
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

.o-empty-state--fixed {
  position: fixed;
  pointer-events: none;
  z-index: 1;
  top: 50%;
  left: 50%;
}

/**
 * Visual
 */

.o-empty-state__visual {
  margin-bottom: 20px;

  svg {
    height: 150px;
  }
}

/**
 * Title element
 */

.o-empty-state__title {
  @include heading1;
}

/**
 * Text element
 */

.o-empty-state__text {
  @include paragraph;

  + .o-button {
    margin-top: 30px;
  }
}

.o-empty-state__text a {
  color: map-get($colors, stable-dark);
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}
