.o-toast {
  display: flex;
  position: fixed;
  bottom: 40px;
  min-width: 200px !important;
  height: 42px !important;
  background-color: map-get($colors, dark-light);
  z-index: 1;
  border-radius: 5px;
  align-items: center;
  justify-content: space-between;
  padding-right: 0px;
  animation: slide-in 0.3s ease-in-out;
  & button {
    transition: background-color 0.3s ease-in-out;
    &.danger {
      &:hover {
        background-color: map-get($colors, assertive);
      }
    }
    &.energized {
      &:disabled {
        cursor: not-allowed;
      }
      &:hover:not(:disabled) {
        background-color: map-get($colors, energized);
      }
    }
    &:hover,
    &:focus,
    &:active {
      background-color: map-get($colors, stable-dark);
    }
    width: 48px;
    cursor: pointer;
    color: white;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: 1px solid map-get($colors, stable-dark);
    height: 100%;
    &:not(:last-child) {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    & > svg {
      width: 14px;
      height: 14px;
    }
  }
  & p {
    margin-bottom: 0;
    font-weight: 700;
    padding-right: 18px;
    color: white;
    & svg {
      vertical-align: middle;
      font-weight: 100;
    }
  }
}
.o-toast--button {
  transition: background-color 0.3s ease-in-out;
  &.danger {
    &:hover {
      background-color: map-get($colors, assertive);
    }
  }
  &.energized {
    &:disabled {
      cursor: not-allowed;
    }
    &:hover:not(:disabled) {
      background-color: map-get($colors, energized);
    }
  }
  &:hover {
    background-color: map-get($colors, stable-dark);
  }
  width: 48px;
  cursor: pointer;
  color: white;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left: 1px solid map-get($colors, stable-dark);
  height: 100%;
  &:not(:last-child) {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  & > svg {
    width: 14px;
    height: 14px;
  }
}
.o-toast--shadow {
  box-shadow: 0px 0px 40px 0px rgba(54, 54, 54, 0.2);
}
.o-toast--light {
  background-color: white;
}
.o-toast--dropdown {
  & li {
    transition: background-color 0.3s ease-in-out;
    &:hover {
      background-color: map-get($colors, stable-dark);
    }
  }
}
.o-toast--text {
  display: inherit;
  align-items: center;
  height: 100%;
  padding-left: 18px;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
  transition: background-color 0.3s ease-in-out;
  &:hover {
    background-color: map-get($colors, stable-dark);
  }
}
.dismiss {
  animation: slide-out 0.3s ease-in-out;
  bottom: -60px;
}
@keyframes slide-in {
  0% {
    opacity: 0;
    bottom: -40px;
  }
  100% {
    opacity: 1;
    bottom: 40px;
  }
}
@keyframes slide-out {
  0% {
    opacity: 1;
    bottom: 40px;
  }
  100% {
    opacity: 0;
    bottom: -60px;
  }
}
.o-toast-container {
  transition: all 0.3ms ease-in-out;
  display: flex;
  justify-content: center;
}
