/**
 * Stars input object
 */

.o-stars-input {
  	display: inline-block;
  	overflow: hidden;
}

/**
 * Input element
 */

.o-stars-input__input {
	position: absolute;
	left: 0;
	top: -50px;
}

/**
 * Label element
 */

.o-stars-input__label {
	display: block;
	float: right;
	width: 11px;
	height: 22px;
	margin: 0!important;

	&:nth-of-type(even) {
		background: url('../../images/sprite-star.svg') 0px 0px;

		&:hover {
			background-position: 0px -22px;
		}
	}

	&:nth-of-type(odd) {
		background: url('../../images/sprite-star.svg') 11px 0px;
		padding-right: 2px!important;

		&:hover {
			background-position: 11px -22px;
		}
	}
}

/**
 * hover styles
 */

.o-stars-input__label:hover ~ .o-stars-input__label,
.o-stars-input__input:checked ~ .o-stars-input__label {
	&:nth-of-type(even) {
		background-position: 0px -22px;
	}
	&:nth-of-type(odd) {
		background-position: 11px -22px;
	}
}
