@use 'sass:color';
.o-bar {
  height: 20px;
  width: 'auto';
  position: relative;
}
.o-bar__row--title {
  font-size: 14px;
}
.o-bar__row--date {
  font-size: 12px;
}
.o-bar__row--subscription {
  font-size: 14px;
  color: map-get($colors, stable-dark);
}
.o-bar__row {
  display: flex;
  flex-direction: column;
}
.o-bar__item {
  display: inline-flex;
  font-size: 14px;
  transition: all 300ms ease-in-out;
  &:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  &:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  height: 12px;
}
.o-bar__count {
  z-index: -1;
  position: absolute;
  white-space: nowrap;
  font-size: 12px;
  top: 100%;
  &.balanced {
    color: map-get($colors, balanced);
  }
  &.energized {
    color: map-get($colors, energized);
  }
  &.royal {
    color: map-get($colors, royal);
  }
  &.stable-dark {
    color: map-get($colors, stable-dark);
  }
  &.stable-light {
    color: map-get($colors, stable-light);
  }
}
.o-bar__item--balanced {
  background-color: map-get($colors, balanced);
}
.o-bar__item--royal {
  background-color: map-get($colors, royal);
}
.o-bar__item--energized {
  background-color: map-get($colors, energized);
}
.o-bar__item--energized-dark {
  background-color: color.adjust(map-get($colors, energized), $lightness: -15%);
}
.o-bar__item--stable-dark {
  background-color: map-get($colors, stable-dark);
}
.o-bar__item--stable-light {
  background-color: map-get($colors, stable-light);
}
.o-bar__legenda {
  font-size: 14px;
}
