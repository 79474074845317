/**
 * Dark card component
 */

.c-dark-card {
  background-color: map-get($colors, light);
  border: 1px solid map-get($colors, dark);
  border-bottom-width: 3px;
  border-radius: $border-radius;
  padding: 25px 30px;

  &:hover > .o-action-list {
    @media #{$medium-up} {
      left: 100%;
      opacity: 1;
      z-index: 99;
    }
  }

  &:hover > .o-action-list--top  {
    @media #{$medium-up} {
      left: 0;
      right: 0;
      bottom: 100%;
      opacity: 1;
      z-index: 99;
    }
  }

  &:hover {
    @include box-shadow( 0px 3px 12px 0px rgba(0,0,0,0.24) );
  }
}

/**
 * On diagram
 */

.c-dark-card--on-diagram {
  @include noselect;
}

/**
 * Small modifier
 */

.c-dark-card--small {
  padding: 10px 15px;
}

/**
 * Title element
 */

.c-dark-card__title {
  margin: 0;

  &:before {
    content: url('../../images/icon-flag-checkered.svg');
    display: inline-block;
    margin-right: 5px;
    position: relative;
    width: 16px;
  }
}
