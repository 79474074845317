/**
 * Form component
 */

.c-form {
  width: 100%;

  @media #{$medium-up} {
    width: 550px;
  }
}

.c-form__devise {
  @media #{$medium-up} {
    width: 400px;
  }

  @media #{$large-up} {
    width: 530px;
  }
}

/**
 * Title element
 */

.c-form__title {
  @include heading1;
  margin-bottom: 20px;
  font-weight: 700;
  text-align: center;

  @media #{$medium-up} {
    margin-bottom: 40px;
  }
}

.c-form__subtitle {
  @include paragraph;
  color: map-get($colors, stable);
  margin-bottom: 20px;

  @media #{$medium-up} {
    margin-bottom: 40px;
  }
}

.c-form__subtitle-dark {
  color: map-get($colors, dark);
}

.c-form__title--inline {
  display: inline;
  margin-right: 20px;
}

.c-form__title--aside {
  float: right;
  margin-top: 10px !important;
}

/**
 * Row element
 */

.c-form__row--flex {
  display: flex;
  align-items: center;
}

/**
 * Label element
 */

a.c-form__label-side {
  text-decoration: underline;
  float: right;
  color: map-get($colors, stable-dark);
  font-weight: 400 !important;

  &:hover {
    color: map-get($colors, stable-dark);
    text-decoration: none;
  }
}

.c-form__row label[required='required']:after,
.c-form__label--required:after {
  content: ' *';
  color: map-get($colors, assertive);
}

/**
 * Button element
 */

.c-form__button {
  width: 100%;
  font-family: $font-stack;
}

/**
 * Button element
 */

.c-form__help-link {
  color: map-get($colors, stable-dark);
  font-size: map-get($fonts, xs);
  text-decoration: underline;
  margin-top: -3px;
  display: block;
}

.c-form__agree-link {
  @include paragraph;
  display: inline-block;
  color: map-get($colors, dark);
  text-decoration: underline;

  &:hover {
    color: map-get($colors, assertive);
  }
}

.c-form__info-link {
  @include paragraph;
  display: inline-block;
  color: map-get($colors, stable-dark);
  text-decoration: underline;

  &:hover {
    color: map-get($colors, assertive);
  }
}

.c-form__link {
  color: map-get($colors, assertive);

  &:hover {
    color: map-get($colors, balanced);
  }
}

.c-form__aside {
  @include paragraph;
  color: map-get($colors, assertive);
  float: right;
  margin-top: 10px;

  svg path {
    fill: map-get($colors, assertive);
  }
}

/**
 * Panel
 */

.c-form__panel {
  border: 1px solid map-get($colors, stable-lighter);
  padding: 17px 20px;
  border-radius: $border-radius;
}

/**
 * Remove text
 */

.c-form__remove-text {
  @include heading4;
  display: block;
  text-align: right;
  color: map-get($colors, assertive);
  margin-top: -10px;

  &:hover {
    color: color.adjust(map-get($colors, assertive), $lightness: -10%);
  }
}

.c-form__code {
  font-size: 28px;
  font-weight: 700;
  font-variant-numeric: tabular-nums;
  color: map-get($colors, stable-dark);
  background-color: map-get($colors, stable-lighter);
  border-radius: 6px;
  padding: 20px;
  letter-spacing: 10px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.c-form__label--lined {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px;
  font-weight: 700;
  &::before,
  &::after {
    content: '';
    background-color: map-get($colors, stable-light);
    top: 51%;
    width: 40%;
    height: 1px;
    display: inline-block;
  }
  & label {
    flex-grow: 2;
    white-space: nowrap;
    padding: 0 10px;
    margin: 0;
    color: map-get($colors, stable-dark);
  }
}
