/**
 * Segments component
 */

.c-segments-nav {
  border: 1px solid map-get($colors, stable-light);
  border-radius: $border-radius;
  display: inline-flex;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  vertical-align: middle;
  font-size: map-get($fonts, s);
  list-style: none;

  @media #{$small-only} {
    margin-bottom: 15px;
    width: 100%;
  }
}

/**
 * Item element
 */

.c-segments-nav__item {
  border-right: 1px solid map-get($colors, stable-light);
  &.c-segments-nav__item--background {
    background-color: map-get($colors, stable-lightest);
  }

  @media #{$small-only} {
    width: 33%;
  }

  &:first-child {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    margin-left: 0;
  }

  &:last-child {
    border-right: none;
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

.c-segments-nav__item--textual {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: $input-height - 2px;
  min-width: 64px;
  color: map-get($colors, dark);
}

/**
 * Link element
 */

.c-segments-nav__link {
  @include transition(
    background-color 0.3s $smooth-ease,
    color 0.3s $smooth-ease
  );
  @include paragraph;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 42px;
  height: $input-height - 2px;

  @media #{$small-only} {
    width: 100%;
  }

  &:hover {
    background-color: map-get($colors, light);
    color: map-get($colors, dark);

    path {
      @include transition(fill 0.3s $smooth-ease);
      fill: map-get($colors, dark);
    }
  }

  &:active {
    color: map-get($colors, assertive);
  }
}

/**
 * Active item
 */

.c-segments-nav__item.active .c-segments-nav__link {
  background-color: map-get($colors, light);
  color: map-get($colors, dark);
  pointer-events: none;

  path {
    fill: map-get($colors, dark);
  }
}

/**
 * Search item
 */

.c-segments-nav--search {
  position: relative;
  overflow: visible;
}

/**
 * Search item
 */

.c-segments-nav__input {
  margin: 0;
  background-color: map-get($colors, stable-lighter);
  cursor: pointer;
  height: 40px;
  width: 42px;
  padding-left: 32px;
  border-radius: $border-radius - 1px;
  transition: width 0.25s;
}

.c-segments-nav--search .c-segments-nav__input {
  background-image: url('../../images/icon-search-alt.svg');
  background-repeat: no-repeat;
  background-position: 14px 50%;
}

.c-segments-nav__input:focus,
.c-segments-nav__input--active {
  background-image: url('../../images/icon-search.svg');
  width: 190px;
  cursor: text;
  background-color: map-get($colors, light);
  transition: width 0.25s;
}
