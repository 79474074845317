body.c-launcher {
  p {
    line-height: 24px;
    font-size: 16px;
  }
  @include breakpoint(small only) {
    h3 {
      font-size: 16px;
    }
  }
}

.c-launcher {
  letter-spacing: -0.7px;
  color: rgb(27, 28, 34);
}
.c-launcher__tabbar-container {
  flex-direction: column;
  @include breakpoint(large up) {
    flex-direction: row;
  }
}

.indiana-scroll-container--dragging {
  cursor: grabbing !important;
}

.c-launcher__tabbar {
  font-weight: bold;
  padding-top: 20px;
  background-color: map-get($colors, stable-lighter);
  overflow-x: auto;
  & .tabs {
    background: transparent;
    border: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  @include breakpoint(small) {
    flex-direction: column;
    border-bottom: 1px solid #ddd;
    & .pre-text {
      margin-bottom: 5px;
      width: 100%;
    }
    & .tabs {
      border-radius: 10px;
    }
    & .tabs-title {
      margin-bottom: 5px;
      width: 100%;
      & a:hover {
        border-radius: 10px;
      }
      & a:focus {
        outline: none;
      }
      & a:active {
        background-color: #fff;
      }
    }
  }
  @include breakpoint(large up) {
    border-bottom: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    & .pre-text {
      margin-right: 20px;
      margin-bottom: 0;
      width: auto;
    }
    & .tabs-title {
      width: auto;
      margin-bottom: 0;
      & a:hover {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }
      & a:focus {
        outline: none;
      }
    }
  }
}

.no-scrollbars {
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
.c-launcher__tabbar-tabs {
  @include breakpoint(large up) {
    display: inline-flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    width: 100%;
    overflow-x: visible;
  }
}
.corners-overlay {
  @include breakpoint(large up) {
    position: relative;
    flex-wrap: nowrap;
    & ::before,
    ::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      width: 12px;
      height: 12px;
      z-index: 1;
    }
    & ::before {
      left: -12px;
      border-bottom-right-radius: 12px;
      border: 1px solid white;
      box-shadow: 2px 2px 0px 2px white;
      border-top: 0;
      border-left: 0;
    }
    & ::after {
      right: -12px;
      border-bottom-left-radius: 12px;
      border: 1px solid white;
      box-shadow: -2px 2px 0px 2px white;
      border-top: 0;
      border-right: 0;
    }
  }
}
.c-launcher__tabs-content {
  border: none;
}
.c-launcher__tabbar--active {
  background-color: white;
  outline: transparent;
  display: inline-block;
  border-radius: 10px;
  @include breakpoint(large up) {
    margin-bottom: 0;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}
.c-container--launcher .row {
  max-width: 65rem;
}

.c-container--launcher .row .medium-centered {
  @media #{$medium-up} {
    margin-left: 0;
  }
}
.c-launcher__sign-in--button {
  font-size: 18px;
  font-weight: bold;
  height: 60px;
  color: white;
  margin-bottom: 0;
  transition: all 300ms ease-in-out;
  &:hover {
    filter: brightness(85%);
  }
}

.c-launcher__header {
  width: 100%;
  height: 120px;
  border-bottom: 1px solid #ccc;
  background-color: #fff;
  margin-bottom: 30px;
}

.c-launcher__header--container {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid map-get($colors, stable-light);
}

.c-launcher__header--inner {
  height: 100%;
  width: 100%;

  max-width: 1400px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.c-launcher__header--button {
  margin-right: 30px;
  font-weight: 600;
  color: rgb(27, 28, 34) !important;
  border-color: rgba(27, 28, 34, 0.1) !important;
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  height: 42px;
  letter-spacing: -0.7px;
  vertical-align: middle;
  &:hover {
    border-color: rgb(27, 28, 34) !important;
  }
}
.c-launcher__header--right-container {
  display: flex;
  align-items: center;
}
.c-launcher__header--button__flagicon {
  @include breakpoint(medium) {
    margin-right: 6px;
  }
}

.c-launcher__nav--container {
  position: sticky;
  background-color: #fff;
  top: -2px;
  border-bottom: 1px solid #ddd;
  align-content: center;
  height: 64px;
  z-index: 1;
  @include breakpoint(small only) {
    justify-content: space-between;
  }
}
.c-launcher__nav {
  max-width: 1400px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}

.c-launcher__nav--menu-container {
  display: inline-flex;
  justify-content: space-between;
  flex-grow: 1;
  margin-right: 50px;
}
.c-launcher__nav--back-to-top {
  margin: auto 0;
  & span {
    margin-right: 10px;
    font-size: 12px;
    font-weight: 300;
  }
  @include breakpoint(small only) {
    display: inline-grid !important;
    place-items: center;
    & span {
      margin-right: 0px !important;
    }
  }
  @include breakpoint(medium up) {
    margin-left: auto;
  }
  &:hover {
    color: map-get($colors, stable-dark) !important;
  }

  color: map-get($colors, stable-light);
  margin-right: 0px !important;
  justify-content: center;
  text-align: center;
}
.c-launcher__nav--list-item {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  font-size: 16px;
  font-weight: 600;
  list-style: none;
  @include breakpoint(medium) {
    padding: 10px;
    margin-right: 72px;
  }
  transition: color 300ms ease-in-out;
  cursor: pointer;
  &:hover,
  &.active {
    transition: color 300ms ease-in-out;
    color: map-get($colors, assertive);
  }
}
.c-launcher__link {
  color: rgba(27, 28, 34, 0.5);
  font-weight: 600;
  transition: color 0.3s ease-in-out;
  &:hover {
    transition: color 0.3s ease-in-out;
    color: map-get($colors, assertive);
  }
}
.c-launcher__company--container {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  @include breakpoint(small only) {
    margin-top: 30px;
  }
}
.c-launcher__company--image--container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
}
.c-launcher__company--video {
  background-color: black;
  display: block;
  width: 100%;
  margin-bottom: 20px;
  @include aspect-ratio(2, 1);
  @include breakpoint(small only) {
    margin-top: 20px;
    margin-bottom: 0px;
  }
  border-radius: 5px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  & video {
    border-radius: 5px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    position: absolute;
    top: 0;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }
}
.c-launcher__company--image {
  background-position: center;
  background-size: cover;
  display: block;
  width: 100%;
  @include aspect-ratio(2, 1);
  @include breakpoint(small only) {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  border-radius: 5px;
  &.no-border-top {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
  &.no-border-bottom {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}
.c-launcher__company-icon {
  display: inline-table;
  width: 60px;
  height: 60px;
  padding: 10px;
  border-radius: 12px;
  border: 1px solid rgba(27, 28, 34, 0.1);
  margin-bottom: 20px;
  transition: background-color 300ms ease-in-out;
  background-color: #fff;
  background-position: center;
  background-size: cover;
}
.c-launcher__company-icon--round {
  background-size: 44px;
  background-repeat: no-repeat;
  border-radius: 50%;
  background-color: #fff;
  border: none;
  margin-bottom: 30px;
}

.c-launcher__company-icon--large-round {
  background-size: 52px;
  background-repeat: no-repeat;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin-right: 20px;
  margin-bottom: 0 !important;
}
.c-launcher__company-info {
  background-color: rgba(27, 28, 34, 0.05);
  border-radius: 5px;
  height: fit-content;
  padding: 40px;
}
.c-launcher__company-info--link {
  cursor: pointer;
  color: black;
  text-decoration: underline;
  &:hover {
    color: black;
    text-decoration: underline;
  }
}
.c-launcher__logo--container {
  display: flex;
  direction: row;
  align-items: center;
}
.c-launcher__logo--image {
  width: 65px;
  margin-right: 10px;
}
.c-launcher__logo--text {
  margin: 0;
  font-size: 22px;
  letter-spacing: -1.1px;
  font-weight: 700;
}

.c-launcher__page--container {
  max-width: 1400px !important;
  margin: 0 auto;
  @include breakpoint(small only) {
    margin-bottom: 90px;
  }

  @include breakpoint(medium) {
    margin-top: 60px;
    padding-bottom: 120px;
  }
  @include breakpoint(large) {
    margin-top: 90px;
    padding-bottom: 120px;
  }
  &.no-margin {
    @include breakpoint(small only) {
      margin-bottom: 5px;
    }
    margin-top: 0;
  }
  &--with-bg {
    opacity: 1;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: -40px; // ensure no margins on the right
      left: 0;
      opacity: 0.1;
      transition: opacity 1s ease-in-out;
      z-index: -1;
      background-image: url('../../images/logo-o.svg');
      background-repeat: no-repeat;
      background-size: 400px;
      @include breakpoint(small) {
        background-position: bottom 50% left calc(200px + 50%);
      }
      @include breakpoint(medium) {
        background-position: bottom 70% left calc(200px + 50%);
      }
      @include breakpoint(large) {
        background-position: bottom 70% right calc(450px + 15%);
      }
    }
  }
}

.c-launcher__about--heading {
  display: flex;
  margin-bottom: 40px;
  font-size: 28px;
  color: rgb(27, 28, 34);
  font-weight: 700;
  letter-spacing: -1.4px;
}

.c-launcher__page--banner {
  font-size: 42px;
  line-height: 54px;
  @include breakpoint(medium up) {
    font-size: 54px;
    line-height: 66px;
  }
  font-weight: 700;
  font-style: normal;
  letter-spacing: -2.7px;
}
.c-launcher__page--heading {
  display: flex;
  padding-top: 100px;
  margin-bottom: 40px;
  font-size: 28px;
  color: rgb(27, 28, 34);
  font-weight: 700;
  letter-spacing: -1.4px;
  justify-content: center;
}
.c-launcher__page--subtext {
  margin-top: 30px;
  margin-bottom: 40px;
  color: rgba(27, 28, 34, 0.5);
}
.c-launcher__page--exclusive {
  color: rgba(27, 28, 34, 0.5);
  margin-top: 20px;
  font-size: 14px;
}

.c-launcher__explanation--container {
  display: inline-flex;
  justify-content: space-evenly;
  width: 100%;
}
.c-launcher__explanation-list {
  min-height: 100px;
  margin-bottom: 30px;
}
.c-launcher__explanation-list--content {
  @include breakpoint(medium) {
    max-width: 250px;
  }
}
.c-launcher__explanation-list--prefix {
  display: grid;
  margin-right: 10px;
  margin-bottom: 20px;
  place-content: center;
  width: 24px;
  height: 24px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  background-color: map-get($colors, assertive);
  border-radius: 50%;
}

.c-launcher__FAQ--container {
  max-width: 1400px;
  margin: 0 auto;
  margin-top: 50px;
}
.c-launcher__FAQ--box {
  @include breakpoint(medium) {
    padding-left: 50px;
    padding-right: 50px;
  }
  margin-bottom: 30px;
}
.c-launcher__FAQ--heading {
  font-weight: 700;
  font-size: 16px;
}
.c-launcher__footer {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
}
.c-launcher__footer--inner {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.c-launcher__footer--subtext {
  font-size: 28px;
  color: map-get($colors, stable-dark);
}
.c-launcher__footer--social {
  color: rgba(27, 28, 34, 0.5);
  background-color: rgba(27, 28, 34, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: inline-grid;
  place-items: center;
  margin-right: 10px;
  &:hover {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    color: rgba(27, 28, 34, 0.8);
    background-color: rgba(27, 28, 34, 0.2);
  }
}

.c-launcher__footer--copyright {
  font-size: 14px;
  color: rgba(27, 28, 34, 0.5);
  margin-top: 20px;
}
.c-launcher__mobile-link--container {
  @include breakpoint(large) {
    margin-top: 50px;
  }
}
.c-launcher__mobile-link--small-container {
  @include breakpoint(medium) {
    &:nth-of-type(odd) {
      padding-left: 0 !important;
      padding-right: 5px;
    }
    &:nth-of-type(even) {
      padding-left: 5px;
      padding-right: 0 !important;
    }
  }
}
.c-launcher__mobile-link--small {
  display: flex;
  color: white;
  background-color: rgb(27, 28, 34);
  align-items: flex-start;
  margin-bottom: 10px;
  height: 72px;
  border-radius: 5px;
  padding: 16px;
  text-align: left;
  font-size: 16px;
  transition: background-color 300ms ease-in-out;
  &:hover,
  &:active {
    transition: background-color 300ms ease-in-out;
    color: white !important;
    background-color: rgb(129, 129, 129);
  }
}
.c-launcher__mobile-link--subtext {
  font-size: 12px !important;
}
.c-launcher__mobile-link {
  text-align: left;
  display: flex;
  color: white;
  background-color: rgb(27, 28, 34);
  margin-bottom: 10px;
  @include breakpoint(medium) {
    width: 270px;
  }
  height: 72px;
  border-radius: 5px;
  padding: 16px;
  transition: background-color 300ms ease-in-out;
  &:hover,
  &:active {
    transition: background-color 300ms ease-in-out;
    color: white !important;
    background-color: #333333;
  }
}
.c-launcher__mobile-link--arrow {
  position: absolute;
  right: 0;
  bottom: 0;
  @include breakpoint(medium only) {
    transform: rotate(90deg) scaleY(-1);
    top: -80px;
    left: -90px;
  }
  @include breakpoint(large) {
    right: 20px;
    top: 0px;
  }
}
.c-launcher__mobile-link--icon {
  width: 36px;
  margin: -2px 0 2px;
  margin-right: 20px;
}
.c-launcher__mobile-link--heading {
  font-weight: 700;
  margin-bottom: 3px;
}
.c-launcher__logo {
  background-image: url('../../images/logo-launcher.svg');
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
  height: 60px;
  width: 60px;
  margin: 30px 20px 30px 0px;
  display: block;
}

.c-launcher__scenario-carousel--container {
  position: relative;
  overflow-x: auto;
  transition: height 300ms ease;
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  // @include breakpoint(medium up) {
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100px;
    z-index: 2;
    background-color: hotpink;
  }
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100px;
    z-index: 12;
    background-color: orangered;
  }
  // }
}

.c-launcher__scenario-carousel--inner {
  display: flex;
  flex-direction: row;

  & .wrapper {
    max-width: 1400px !important;
    margin: 0 auto;
    display: flex;
    @include breakpoint(small only) {
      overflow-x: scroll;
      scroll-snap-type: x proximity;
    }
    overflow-x: visible;

    & .wrapper--inner {
      display: flex;
      @include padding;
      &:hover {
        cursor: grab;
      }
    }
  }

  @include breakpoint(small only) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.c-launcher__scenario-carousel--card {
  scroll-snap-align: start;
  display: inline-flex;
  flex-direction: column;
  width: 500px;
  margin-bottom: 40px;
  max-width: 500px;
  padding-right: 40px;
  @include breakpoint(small only) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @include breakpoint(medium up) {
    &:last-child {
      padding-right: 0;
      width: 460px;
    }
  }
  @include breakpoint(small only) {
    width: 90vw;
    max-width: 100%;
  }
  transition: all 300ms ease-in-out;
}
.c-launcher__scenario-carousel--nav-icon {
  &.first {
    margin-left: 50px;
    margin-right: 10px;
  }
  &:hover {
    transition: background-color 300ms ease-in-out;
    background-color: rgba(27, 28, 34, 0.25);
  }
  cursor: pointer;
  border-radius: 50%;
  background-color: rgba(27, 28, 34, 0.1);
  width: 42px;
  height: 42px;
  font-size: 18px;
  display: grid;
  place-items: center;
}
.c-launcher__scenario-carousel--card--image {
  background-position: center;
  background-size: cover;
  display: block;
  width: 100%;
  border-radius: 5px;
  @include aspect-ratio(2, 1);
}

.c-launcher__menu {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.c-launcher__menu .c-launcher__title {
  margin-left: 0px;
  margin-bottom: 4px;
}

.c-launcher__scenario {
  margin: 0px auto;

  @media #{$medium-up} {
    max-width: 100%;
    width: 460px;
  }
}

.c-launcher__heading {
  @include heading1;
  font-weight: 500;
  margin-top: 30px;
  margin-bottom: 20px;

  @media #{$medium-up} {
    font-size: 28px;
    line-height: 1.2;
  }
}

.c-launcher__content {
  position: relative;
  padding: 30px 30px 10px;
}

.c-launcher__content--simple {
  position: relative;
  padding: 30px 30px 10px 30px;
}

.c-launcher__content--finished {
  position: relative;
  padding: 30px 30px 10px 30px;

  &:after {
    content: '';
    width: 40px;
    height: 40px;
    border-radius: 40px;
    border: 1px solid map-get($colors, stable);
    position: absolute;
    right: 45px;
    margin-right: -20px;
    top: 50%;
    margin-top: -20px;
    background-color: map-get($colors, energized);
    background-image: url('../../images/checkmark-light.svg');
    background-repeat: no-repeat;
    background-position: center;
    border-color: map-get($colors, energized);
  }
}

.c-launcher__title {
  @include heading2;
  font-weight: 700;
  margin-bottom: 20px;
  margin-left: 34px;
  padding-top: 2px;

  @media #{$medium-up} {
    font-size: 18px;
  }
}

.c-launcher__content--simple .c-launcher__title {
  margin-left: 0px;
}

.c-launcher__content--finished .c-launcher__title {
  margin-right: 50px;
}

.c-launcher__number,
.c-launcher__list-item {
  font-size: 14px;
  font-weight: 700;
  padding: 3px 7px;
  text-align: center;
  color: map-get($colors, light);
  margin-right: 10px;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  left: 30px;
  top: 30px;
}

.c-launcher__number {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background-color: map-get($colors, assertive);
}

.c-launcher__content--finished .c-launcher__number {
  background-color: map-get($colors, stable-dark);
}
.c-launcher__sign-in--code {
  font-size: 54px;
  font-weight: bold;
  display: inline-block;
  padding: 20px 8px;
  border-radius: 15px;
  margin-bottom: 1em;
  letter-spacing: 22px;
  text-indent: 22px;
  max-width: 100vh;
  background-color: map-get($colors, stable-light);
  color: map-get($colors, stable-dark);
  &.code-flex {
    width: 100%;
    margin-bottom: 0;
    text-align: center;
  }
  @include breakpoint(small only) {
    width: 100%;
    display: inline-flex;
    text-indent: unset;
    justify-content: space-around;
    letter-spacing: normal;
  }
}

.c-launcher__code,
.c-launcher__stars {
  font-size: 28px;
  font-weight: 700;
  font-variant-numeric: tabular-nums;
  color: map-get($colors, stable-dark);
  background-color: map-get($colors, stable-lighter);
  border-radius: 6px;
  padding: 20px;
  letter-spacing: 0px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.c-launcher__code {
  letter-spacing: 10px;
}

.c-launcher__stars .star {
  background: url('../../images/star.svg');
  width: 30px;
  height: 28px;
  display: inline-block;
}

.c-launcher__stars .star-full {
  background: url('../../images/star-full.svg');
}

.o-button--launcher {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: 700;
}

.o-button--dark {
  background-color: #000;
  color: #fff;
  border-color: #000;
  font-size: 14px;

  &:hover {
    background-color: #666;
    border-color: #666;
    color: #fff;
  }

  &:active {
    background-color: #cccccc;
    border-color: #999999;
    color: #999999;
  }
}

/**
 * Device select
 */
.device__select {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 30px;
}

.device__choice {
  border: 2px solid #eee;
  border-radius: 6px;
  flex-grow: 1;
  flex-basis: 0;
  padding: 20px 10px;
  cursor: pointer;
}

.device__choice:hover {
  background-color: #eee;
}

.device__choice:nth-child(2n + 2) {
  margin-left: 10px;
}

.device__choice--active {
  border: 2px solid #e7461a;
}

.device__choice--icons {
  margin-bottom: 10px;
}

.device__choice--icons img {
  fill: #ccc;
}

.device__choice--title {
  font-weight: bold;
}

.device__choice--active .device__choice--title {
  color: #e7461a;
}

.device__choice--subtitle {
  color: #999;
}
