@import url('react-datepicker/dist/react-datepicker.css');
.react-datepicker {
  font-family: inherit;
  border-color: transparent;
  font-size: .875rem;
  width: 100%;
  &__header {
    text-align: left;
    background-color: transparent;
    border-bottom: 0;
  }
  &__month-container{
    width: 100%;
  }
  &__day-name {
    color: map-get($colors, stable-dark);
  }
  &__current-month {
    padding-left: 8px;
    font-weight: 500;
  }
  &__week {
    display: flex;
    justify-content: space-between;
  }
  &__month {
    margin: 0;
  }
  &__navigation--next {
    right: -6px;
  }
  &__navigation--previous {
    left: auto;
    right: 19px;
  }
  &__navigation-icon::before {
    border-color: #000;
    border-width: 2px 2px 0 0;
  }
  &__day--outside-month{
    color: map-get($colors, stable-dark);
  }
  &__day--in-range, &__day--selected, &__day--in-selecting-range:not(&__day--in-range), &__day--in-range:hover {
    background-color: rgba(map-get($colors, assertive), 0.1);
    color: inherit;
    border-radius: 0;
  }
  &__day--range-start, &__day--selecting-range-start {
    background-color: rgba(map-get($colors, assertive), 0.2);
    color: inherit;
    border-radius: 0.3rem 0 0 0.3rem !important;
  }
  &__day--range-end, &__day--selecting-range-end {
    border-radius: 0 0.3rem 0.3rem 0 !important;
    background-color: map-get($colors, assertive) !important;
    color: white !important;
  }
  &__day {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0.166rem;
    padding-right: 0.166rem;
    width: 100%;
  }
  &__current-month {
    margin-bottom: 5px;
  }
  &__day-names{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}