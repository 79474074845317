@use 'sass:color';

.o-bullet {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: map-get($colors, dark);
  margin: 0 5px;
}

.o-bullet--small {
  width: 1.647mm;
  height: 1.647mm;
}

.o-bullet--stable-dark {
  background: map-get($colors, stable-dark);
}
.o-bullet--stable-light {
  background: map-get($colors, stable-light);
}

.o-bullet--royal {
  background: map-get($colors, royal);
}

.o-bullet--assertive {
  background: map-get($colors, assertive);
}

.o-bullet--energized {
  background: map-get($colors, energized);
}
.o-bullet--energized-dark {
  background-color: color.adjust(map-get($colors, energized), $lightness: -15%);
}
.o-bullet--balanced {
  background: map-get($colors, balanced);
}

.o-bullet--success {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background-color: map-get($colors, energized);
  background-image: url('../../images/checkmark-light.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.o-bullet--error {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background-color: map-get($colors, assertive);
  background-image: url('../../images/cross-light.svg');
  background-repeat: no-repeat;
  background-position: center;
}
