.o-sortable-container {
  overflow: hidden;

  .drag-handle {
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }

  &:has(*.dragging) {
    position: relative;

    * {
      user-select: none;
    }
  }

  &>*.dragging {
    position: absolute !important;
    width: 100%;
    left: 0;
    z-index: 10;
    border-radius: 5px;
    background-color: #fff;
  }

  .o-sortable__drag-handle {
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }

  &>*.o-sortable__row--placeholder {
    position: relative;
    border-bottom-color: transparent;

    &:before {
      content: '';
      position: absolute;
      height: 50px;
      width: 100%;
      inset: 0;
      background-color: map-get($colors, stable-light);
    }
  }

  &>*.o-sortable__row--dragover {
    position: relative;
    border-bottom-color: transparent;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      bottom: 0;
      border-bottom: 2px solid map-get($colors, assertive);
    }

    &.o-sortable__row--dragover-top::after {
      border-top: 2px solid map-get($colors, assertive);
      border-bottom: 0;
    }
  }
}