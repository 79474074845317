#scenario-editor {
  --main-bg-color: #f8f8f8;

  #flow-container {
    display: flex;
    width: 100vw;
    height: calc(100vh - 122px);
    position: relative;
    z-index: 5;

    .o-scene {
      background-color: map-get($colors, light);
      width: 270px;
      min-height: 60px;
      border: 1px solid map-get($colors, stable-light);
      outline: 1px solid transparent;
      border-radius: 5px;
      box-shadow: 0px 5px 10px 0px #0000001a;
      position: relative;
      box-sizing: border-box;
      text-align: center;

      &:hover {
        border-color: map-get($colors, stable-dark);
        outline: 1px solid map-get($colors, stable-dark);
      }

      &.o-scene--ending {
        width: 92px;
        min-height: 32px;
        background-color: map-get($colors, dark-light);
        box-shadow: none;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        margin-left: 80px;
        border-radius: 16px;
        --point-color: map-get($colors, stable-dark);

        &::before {
          content: '';
          display: block;
          width: 20px;
          height: 2px;
          position: absolute;
          background: var(--point-color);
          left: -20px;
          z-index: 1;
        }

        &::after {
          position: absolute;
          width: calc(100% + 4px);
          height: calc(100% + 4px);
          content: '';
          border: 2px solid white;
          border-radius: 16px;
          left: -2px;
          top: -2px;
          z-index: 2;
        }

        .o-scene__title {
          color: map-get($colors, light);
          font-size: 14px;
          padding: 0;
          border-bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 0;

          svg {
            margin-right: 6px;
          }
        }

        .react-flow__handle-left {
          top: 50%;
        }
      }

      &.o-scene--new {
        border: 2px solid map-get($colors, dark-light) !important;
        padding: 8px;
        min-height: 0;

        &>input {
          width: 100%;
          padding: 10px;
          background-color: map-get($colors, stable-lighter);
          border-radius: 5px;
          margin: 0;
          font-size: 14px;
          font-weight: 700;
        }

        .react-flow__handle-left {
          top: 50%;
        }
      }

      .o-scene__title {
        font-size: 16px;
        font-weight: bold;
        padding: 20px;
        color: map-get($colors, dark-light);
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0;
      }
    }

    .o-scene__target-handle-container {
      position: absolute;
      height: 100%;
      width: 28px;
      top: 0;
      left: -14px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .o-scene__handle {
      width: 12px;
      height: 12px;
      z-index: 10;
      background: map-get($colors, stable-dark);
      color: map-get($colors, light);
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid map-get($colors, light);
      box-sizing: content-box;
      margin-left: -4px;

      &.o-scene__handle--target,
      &.o-scene__handle--source {
        font-size: 13px;

        &:not(.o-scene__handle--connected) {
          &::before {
            content: url('data:image/svg+xml; utf8, <svg stroke="currentColor" fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" height="1em" width="1em"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z"/></svg>');
            width: 12px;
            height: 12px;
            color: #fff;
            position: relative;
            top: -2px;
            left: -2px;
          }
        }
      }

      &.o-scene__handle--target {
        pointer-events: all;
        cursor: pointer;
        position: relative;
        top: 0;
        left: 0;
        width: 24px;
        height: 24px;
        margin-left: 0 !important;
        margin-top: 10px;
        transform: none;

        &:first-child {
          margin-top: 0;
        }

        &::before {
          content: url('data:image/svg+xml; utf8, <svg stroke="currentColor" fill="white" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path></svg>');
          width: 12px;
          height: 12px;
          color: #fff;
          position: relative;
          top: -2px;
          left: -2px;
        }
      }

      &.o-scene__handle--source {
        margin-top: -11px;
        top: auto;

        &:not(.o-scene__handle--connected) {
          width: 24px;
          height: 24px;
          margin-right: -6px !important;

          &::before {
            content: url('data:image/svg+xml; utf8, <svg stroke="currentColor" fill="white" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path></svg>');
            width: 12px;
            height: 12px;
            color: #fff;
            position: relative;
            top: -2px;
            left: -2px;
          }
        }
      }

      &.o-scene__handle--royal {
        background: map-get($colors, royal);
      }

      &.o-scene__handle--assertive {
        background: map-get($colors, assertive);
      }

      &.o-scene__handle--energized {
        background: map-get($colors, energized);
      }

      &.o-scene__handle--balanced {
        background: map-get($colors, balanced);
      }

      &.o-scene__handle--selected {
        background: map-get($colors, dark-light);
      }
    }

    .o-scene__handle--main {
      width: 100%;
      height: 100%;
      position: absolute;
      inset: 0;
      opacity: 0;
      transform: none;
      z-index: -1;
    }

    #flow-diagram {
      position: relative;
      text-align: center;
      width: 100%;
      height: 100%;

      >.react-flow {
        &>footer {
          position: absolute;
          bottom: 30px;
          color: map-get($colors, stable-dark);
          font-size: 14px;
          font-weight: 700;
          display: inline-block;
          left: calc(50% - 135px);
          z-index: 10;
          user-select: none;
        }
      }
    }

    &>aside {
      width: 540px;
      min-width: 540px;
      height: 100%;
      max-height: 100%;
      overflow: auto;
      background-color: map-get($colors, light);
      border-left: 1px solid #ddd;
      position: static;

      .sidebar__title {
        font-size: 18px !important;
        color: map-get($colors, dark-light) !important;
        font-weight: bold;
        width: 100%;
        outline: none;
      }

      &>header {
        position: sticky;
        top: 0;
        background: map-get($colors, light);
        z-index: 10;
      }

      .element-card--thumbnail {
        position: relative;
        overflow: hidden;

        &::before {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          inset: 0;
          background-color: rgba(map-get($colors, dark), 0.25);
          z-index: 1;
          opacity: 0;
          transition: all .2s;
        }

        .icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: none;
          color: map-get($colors, light);
          z-index: 2;
        }

        &:hover {
          .icon {
            display: block;
          }

          &::before {
            opacity: 1;
          }
        }
      }
    }
  }

  .o-scene__elements {
    text-align: left;

    >div {
      margin-top: 15px;
      border-bottom: 1px solid map-get($colors, stable-light);
      font-size: 12px;

      &:last-child {
        border-bottom: 0;
      }
    }

    .o-scene__elements--icon {
      max-width: 24px;
      display: flex;
    }

    .o-scene__elements--randomizer-icon {
      display: inline-block;
      position: relative;
      bottom: 4.5px;
      width: 10px;
      height: 10px;
      border-left: 1px solid map-get($colors, stable-dark);
      border-bottom: 1px solid map-get($colors, stable-dark);
      margin-right: 5px;
    }
  }

  .o-label {
    width: auto;
    height: 22px;
    min-width: 18px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    padding: 5px;

    svg {
      margin: 0;
    }
  }

  .tiptap:has(> p.is-editor-empty) {
    height: calc(100% - 20px);
    width: calc(100% - 20px);
    position: absolute;
  }

  [data-placeholder]:empty::before,
  .tiptap p.is-editor-empty:first-child:before {
    content: attr(data-placeholder);
    color: map-get($colors, stable-dark);
    font-weight: 500;
    font-size: 14px;
    cursor: text;
    height: 0;
    pointer-events: none;
    float: left;
  }

  .slide p{
    font-size: 15.4px;
    line-height: 23px;
    min-height: 23px;
    color: map-get($colors, dark-light);
    margin-bottom: 0;
  }
}

.react-flow {
  &__handle-right {
    right: -8px;
  }

  &__controls {
    display: flex;
    border-radius: 5px;
    border: 1px solid map-get($colors, stable-light);
    box-shadow: none;
    overflow: hidden;
  }

  &__controls-button {
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    box-sizing: border-box;
    color: map-get($colors, stable-dark);
    border-right: 1px solid map-get($colors, stable-light);
    border-radius: 0;

    &:last-child {
      border-right: 0;
    }

    svg {
      max-width: none;
      width: 16px;
      max-height: none;
    }
  }

  .react-flow__node.selected>.o-scene:not(.o-scene--new) {
    border-color: map-get($colors, assertive) !important;
    outline: 1px solid map-get($colors, assertive) !important;
  }

  .react-flow__handle-left {
    top: 80px;
  }

  .react-flow__handle.connectionindicator {
    cursor: pointer;
  }

  .react-flow__handle-connecting~.o-scene {
    border: 1px solid map-get($colors, dark-light) !important;
    outline: 1px solid map-get($colors, dark-light) !important;
  }

  .react-flow__edgeupdater-target {
    cursor: pointer;
    transform: translateX(24px);
  }

  .react-flow__node.selected {
    z-index: 999 !important;
  }

  .react-flow__nodesselection.nopan {
    .react-flow__nodesselection-rect {
      display: none;
    }
  }
}

.react-flow__controls-video-editor {
  position: absolute;
  z-index: 10;
  inset: 0;
  height: 1px;

  .react-flow__controls {
    border-color: transparent;
  }

  button {
    transition: all 0.2s;
    background-color: rgba(map-get($colors, dark-light), 0.8);
    color: map-get($colors, light);
    border-right-color: transparent;
    border-bottom-color: transparent;

    &:hover {
      background-color: map-get($colors, dark-light);
    }
  }
}

.react-flow__panel {
  margin: 20px;
}

.c-video__editor--elements--card {
  .c-video__editor--elements--settings-container {
    display: none;
  }

  &.active .c-video__editor--elements--settings-container {
    display: block;
  }
}

aside.video-editor-sidebar {
  .c-video__editor--elements--card {
    &:hover {
      outline: 1px solid map-get($colors, stable-dark);
      border-color: map-get($colors, stable-dark);
    }
  }
}

.c-video__editor--hotspot--settings {
  top: 90px;
  background: #fff;
  z-index: 2;
  position: sticky;
  margin-bottom: 1px;
}

.c-video__editor--bottom-controls {
  position: relative;
  z-index: 1000;
}

.range__input--thumb {
  width: 24px;
  height: 24px;
}

.c-video__input--north-offset {
  width: 64px;
  height: 32px;
  border: 1px solid map-get($colors, stable-light);
  text-align: right;
  font-weight: bold;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: map-get($colors, stable-lighter);
  color: map-get($colors, stable-dark);
  transition: all .2s;

  &:hover {
    background-color: #f8f8f8;
  }
}


.filming--search-button:focus+.o-dropdown__search--outer {
  svg {
    opacity: 1;
  }
}

.filming--search-button {
  &>svg {
    opacity: 0;
    transition: all .2s;
  }

  &:hover>svg {
    opacity: 1;
  }
}

@media print {
  @page {
    size: A4;
  }

  body {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}

#export-container {
  position: fixed;
  width: 100vw;
  background-color: map-get($colors, stable-lightest);
  inset: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;

  @media print {
    position: static;
  }

  &>header {
    border-bottom: 1px solid map-get($colors, stable-light);
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: map-get($colors, light);
    padding: 30px;
    page-break-before: avoid;
    page-break-after: avoid;

    h1 {
      font-weight: bold;
      font-size: 18px;
    }
  }

  .export {
    width: 100vw;
    position: relative;

    >aside {
      width: 340px;
      background-color: map-get($colors, light);
      padding: 10px 40px 40px;
      border-right: 1px solid map-get($colors, stable-light);
      position: sticky;
      top: 0;
      float: left;
      height: 100%;

      &>form {
        &>label {
          margin-bottom: 15px;
        }

        &>section {
          margin-bottom: 45px;

          &>div {
            margin-bottom: 15px;

            input {
              margin-bottom: 0;
            }

            label {
              cursor: pointer;
              font-size: 14px;
              font-weight: 500;
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .output-container {
      width: calc(100% - 340px);
      display: flex;
      justify-content: center;
      padding: 40px;
      max-height: calc(100vh - 80px);
      overflow: auto;

      .o-table {
        .o-table__header--nowrap:last-child {
          padding-right: 5px;
        }

        .o-table__data--custom {
          white-space: initial !important;
        }
      }

      @media print {
        width: 100vw;
        padding: 0;
        overflow: visible;
        max-height: none;
      }
    }

    --paperWidth: 210mm;

    @function getPaperSize($entry) {
      // convert px tp mm for A4 paper based on 800px width in the figma design
      @return calc((#{$entry} / 800) * var(--paperWidth));
    }

    #export-outline {
      width: var(--paperWidth);
      min-height: 297mm;
      height: 100%;
      background-color: white;
      border: 1px solid map-get($colors, stable-light);
      font-size: getPaperSize(14);
      padding: getPaperSize(60);

      @media print {
        border: 0;
        padding-top: 0;
      }

      .o-label {
        font-size: getPaperSize(10) !important;
        padding: getPaperSize(4);
        height: getPaperSize(17);
        font-weight: 700;
      }

      >.export-outline--header {
        font-size: getPaperSize(22);
        font-weight: 700;
        display: flex;
        align-items: center;
        page-break-before: avoid;
        page-break-after: avoid;

        img {
          width: getPaperSize(55);
          margin-right: getPaperSize(10);
        }
      }

      h2 {
        font-size: getPaperSize(24);
        font-weight: 700;
        margin-top: getPaperSize(40);
      }

      >.pin-location {
        margin-top: getPaperSize(40);
      }

      >section {
        margin-top: getPaperSize(40);
        border-top: getPaperSize(1) solid map-get($colors, dark-light);
        padding-top: getPaperSize(20);
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        >aside {
          min-width: getPaperSize(158);
          width: getPaperSize(158);
          margin-right: getPaperSize(20);

          h3 {
            font-size: getPaperSize(12);
            font-weight: 700;
          }

          #stars svg path {
            fill: gold !important;
          }

          .export-outline--note-box {
            border: getPaperSize(1) solid map-get($colors, stable-light);
            border-radius: getPaperSize(5);
            padding: getPaperSize(13);
            color: map-get($colors, stable-dark);
            margin-top: getPaperSize(10);

            &>div {
              font-size: getPaperSize(10);
              width: getPaperSize(45);
            }
          }
        }

        >div {
          width: 100%;
          overflow: auto;

          .export-outline--border-row {
            font-size: getPaperSize(12);
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: getPaperSize(10);

            &:last-child {
              margin-bottom: 0;
            }



            &>div {
              background-color: #fff;
              position: relative;
              z-index: 2;
              white-space: nowrap;

              &:first-child {
                color: map-get($colors, stable-dark);
              }

              &:nth-child(2) {
                position: relative;
                width: 100%;
                height: 1px;

                &::before {
                  content: '';
                  position: absolute;
                  left: getPaperSize(10);
                  top: 50%;
                  height: 1px;
                  width: calc(100% - getPaperSize(20));
                  border-bottom: 1px solid map-get($colors, stable-light);
                }
              }

              &:last-child {
                font-weight: 700;
                color: map-get($colors, dark-light);
              }
            }
          }

          .o-table {
            font-size: getPaperSize(10);

            .o-table__header {
              color: map-get($colors, stable-dark);
              font-size: inherit;
            }

            .o-table__data {
              color: map-get($colors, dark-light) !important;
              font-size: inherit;
            }

            .o-table__row {
              height: getPaperSize(30);
            }
          }

          .export-outline--desc {
            font-size: getPaperSize(10);
            margin-top: getPaperSize(20);
            word-wrap: break-word;
          }

          .export-outline--elements {
            display: flex;
            align-items: stretch;
            border: getPaperSize(1) solid map-get($colors, stable-light);
            border-radius: getPaperSize(5);
            margin-top: getPaperSize(20);

            &>aside {
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              border-right: getPaperSize(1) solid map-get($colors, stable-light);
              padding-top: getPaperSize(10);
              padding-bottom: getPaperSize(10);

              &>div {
                padding-left: getPaperSize(10);
                padding-right: getPaperSize(10);

                &:not(:last-child) {
                  position: relative;

                  &::after {
                    content: '';
                    width: getPaperSize(12);
                    height: 1px;
                    right: 0;
                    border-bottom: getPaperSize(1) solid map-get($colors, stable-light);
                    position: absolute;
                    bottom: getPaperSize(-5);
                  }
                }
              }
            }

            .o-scene__elements {
              width: 100%;

              >div {
                margin-top: getPaperSize(12.5);
                border-bottom-width: getPaperSize(1);
                font-size: getPaperSize(12);
              }

              .o-scene__elements--icon {
                max-width: getPaperSize(24);
              }

              .o-scene__elements--randomizer-icon {
                bottom: getPaperSize(4.5);
                width: getPaperSize(10);
                height: getPaperSize(10);
                border-left: getPaperSize(1);
                border-bottom: getPaperSize(1);
                margin-right: getPaperSize(5);
              }

              .text-normal {
                font-size: getPaperSize(10) !important;
              }

              .mr-0-5 {
                margin-right: getPaperSize(5) !important;
              }

              .ml-1 {
                margin-left: getPaperSize(10) !important;
              }

              .pb-1 {
                padding-bottom: getPaperSize(10) !important;
              }

              .pl-1-5 {
                padding-left: getPaperSize(15) !important;
              }

              .pr-1-5 {
                padding-right: getPaperSize(15) !important;
              }

              .pb-1-5 {
                padding-bottom: getPaperSize(15) !important;
              }

              .pl-2 {
                padding-left: getPaperSize(20) !important;
              }

              .ml-2-5 {
                margin-left: getPaperSize(25) !important;
              }

              .pr-2-5 {
                padding-right: getPaperSize(25) !important;
              }
            }
          }

        }

      }
    }
  }
}

#scene-history {
  font-size: 14px;

  .activity {
    padding-bottom: 20px;
    position: relative;
    display: flex;
    align-items: flex-start;
    width: 100%;

    header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h3 {
        font-weight: bold;
        font-size: 14px;
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 15px;
      height: 100%;
      width: 1px;
      border-left: 1px solid map-get($colors, stable-light);
    }

    &:last-child::before {
      border-left: 0;
    }

    .avatar {
      border-radius: 50%;
      position: relative;
      z-index: 2;
    }

    .details {
      margin-left: 10px;
      width: 100%;

      time {
        color: map-get($colors, stable-dark);
      }
    }
  }
}

.has-html p {
  margin: 0;
  color: inherit;
  line-height: inherit;
}