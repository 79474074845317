.scroll-wrapper {
  position: relative;
  flex: 1;
}
.scroll-container {
  @include breakpoint(large up) {
    width: 100%;
    overflow-y: auto;
    flex-direction: column;
  }
}
.scrollable {
  flex-grow: 1;
}
.sidebar-tabs {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.sidebar {
  @include breakpoint(large up) {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}
