.o-modal--header-h3 {
  padding: 20px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center !important;
  & > h3 {
    margin-bottom: 0px;
  }
}
.o-modal--header {
  padding: 20px;

  & > h2 {
    padding: 10px;
    cursor: default;
    &.editing {
      background-color: map-get($colors, stable-light);
      border-radius: 5px;
      outline: blue;
    }
  }
}
.o-modal--header--hoverable {
  & > h2:hover:not(.editing):not(.cancelled) {
    transition: background-color 300ms ease-in-out;
    padding: 10px;
    border-radius: 5px;
    background-color: map-get($colors, stable-light);
  }
}
.o-modal--custom {
  overflow-y: visible !important;
  @include breakpoint(medium only) {
    top: 2em !important;
    margin-bottom: 5%;
  }
  border: none;
  max-width: none !important;
  width: auto;
}
.o-modal--top {
  top: 10% !important;
}
.o-modal--closable {
  position: relative;
}

.o-modal--video {
  margin-bottom: 10%;
  position: relative;
}

.o-modal__video--info {
  @include breakpoint(large only) {
    border-bottom: 1px solid (map-get($colors, stable));
  }
  @include breakpoint(xlarge only) {
    border-bottom: 1px solid (map-get($colors, stable));
  }
}
.o-modal__video--column {
  @include breakpoint(large) {
    border-left: 1px solid (map-get($colors, stable));
  }
}
.o-modal__video--column--xxlarge {
  @include breakpoint(xxlarge) {
    max-width: 500px;
    border-left: 1px solid map-get($colors, stable);
  }
}
.o-modal__video--column--content {
  padding: 30px;
  padding-top: 10px;
}
.o-modal__video--sidebar--max-width {
  @include breakpoint(large) {
    width: 300px !important;
  }
}
.o-modal__video--scenario {
  margin-top: 30px;
  & > h4 {
    margin-bottom: 20px;
  }
}
.o-modal__video--list {
  margin: 0;
  & > li {
    &:not(:first-child) {
      border-top: 1px solid map-get($colors, stable-light);
    }
    display: flex;
    align-items: center;
    cursor: default;
    font-size: 14px;
    list-style: none;
    height: 50px;
    color: map-get($colors, dark-light);
    transition: color 300ms ease-in-out;
    &.scene-added {
      background-color: rgba(232, 198, 10, 0.1);
    }
    & .scene-info {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0 20px;
    }
    & .delete-icon {
      padding-right: 10px;
      color: map-get($colors, stable-light);
      transition: color 300ms ease-in-out;
    }
    &:hover {
      & .delete-icon {
        visibility: visible;
        cursor: pointer;
        color: map-get($colors, stable-dark);

        &:hover {
          transition: color 300ms ease-in-out;
          color: map-get($colors, assertive);
        }
      }
    }
  }
  & .li-search {
    display: block;
    padding: 0;
    &:hover {
      background-color: white !important;
    }
  }
}
.o-modal__video--add-scene {
  color: map-get($colors, stable-dark);
  font-weight: bold;
  display: flex;
  cursor: pointer;
  align-items: center;
}
.o-modal__video--footer {
  margin-bottom: 4px;
}
.reveal {
  border: none;
}
.o-modal__scenario--image {
  height: 300px;
  &.with-ratio-4-3 {
    height: 382px;
  }
  position: relative;
  display: grid;
  place-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  &--small {
    height: 150px;
    border-radius: 5px;
  }
}
.o-modal--close-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  position: absolute;
  top: 35px;
  right: 35px;
  color: map-get($colors, stable-dark);
  cursor: pointer;
}
.o-modal--content {
  padding: 0px 30px;
  position: relative;
  &.video-element {
    padding: 0 !important;
    & .video-element--container {
      display: block;
      width: 100%;
      &.empty {
        padding-top: calc(100% * (1 / 2)); // aspect ratio of 2:1
        overflow: hidden;
        position: relative;
      }
      & .empty--inner-container {
        border-top: 1px solid map-get($colors, stable);
        border-bottom: 1px solid map-get($colors, stable);
        position: absolute;
        display: flex;
        line-height: 1 !important;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }
    }
  }
  @include breakpoint(xlarge down) {
    &:not(.empty).border:after {
      content: '';
      position: absolute;
      left: 2em;
      right: 2em;
      border-bottom: 1px solid map-get($colors, stable);
    }
  }
}
.o-modal--content:has(:is(.react-colorful, .reactEasyCrop_Container)) {
  padding: 0;
  overflow: hidden;
}
.reveal:has(:is(.react-colorful, .reactEasyCrop_Container)) {
  border: 0;
}
.reveal-container:has(.reveal-overlay) > .reveal-overlay {
  position: absolute;
}

.o-modal--subtitle {
  font-size: 14px;
  letter-spacing: -0.7px;
  color: map-get($colors, stable-dark);
  margin-bottom: 0px;
}
.o-modal--footer {
  display: flex;
  justify-content: flex-end;
  padding: 30px;
  padding-top: 20px;
  &:empty {
    padding-top: 0px;
  }
  & button {
    margin-bottom: 0;
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}
.o-modal--footer--text {
  display: inline-flex;
  align-items: center;
  margin-bottom: 0;
  margin-right: 20px;
  width: 100%;
}
.o-modal__navigation {
  position: absolute;
  width: 42px;

  top: 160px;
  height: 150px;
  color: white;
  display: grid;
  place-items: center;
  cursor: pointer;
  z-index: 2;
  transition: opacity 0.3s ease-in-out; // effect on 0.3 millisecond
  opacity: 0;
  &:hover {
    transition: opacity 0.3s ease-in-out; // effect on 0.3 millisecond
    opacity: 1;
  }

  @include breakpoint(medium up) {
    top: 60;
    height: 200px;
  }
  @include breakpoint(large up) {
    opacity: 1;
    top: 100px;
    height: 250px;
  }
  & .icon {
    border-radius: 20%;
    cursor: pointer;
    height: 42px;
    width: 42px;
    font-size: 20px;
    display: grid;
    place-items: center;

    background-color: rgba(0, 0, 0, 0.9);
    @include breakpoint(large up) {
      opacity: 1;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
  &.left {
    left: 10px;
    @include breakpoint(large up) {
      left: -62px;
    }
  }
  &.right {
    right: 10px;
    @include breakpoint(large up) {
      right: -62px;
    }
  }
}
.scene-link {
  transition: color 300ms ease-in-out;
  &:hover {
    text-decoration: underline;
  }
}
.scene-icon--default {
  width: auto;
  padding-right: 5px;
  float: left;
  transition: background-color 300ms ease-in-out;
  font-size: 14px;
  & > * {
    padding: 0px 7px 0px 6px;
    display: grid;
    place-items: center;
    min-height: 22px;
    min-width: 22px;
    width: auto;
    background-color: map-get($colors, stable-dark);
    color: map-get($colors, light);
    border-radius: 3px;
  }
  &.hoverable:hover > * {
    transition: background-color 300ms ease-in-out;
    background-color: map-get($colors, dark-light);
  }
}
.scene-icon {
  width: 40px;
  float: left;
  font-size: 14px;
  margin-right: 10px;
  & > * {
    padding: 0px 7px 0px 6px;
    display: grid;
    place-items: center;
    height: 22px;
    width: fit-content;
    background-color: map-get($colors, stable-dark);
    color: map-get($colors, light);
    border-radius: 5px;
  }
}

.scene-confirm {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.scene-video-icon {
  display: grid;
  place-items: center;
  font-size: 14px;
  &::before {
    content: url('../../images/icon-video.svg');
  }
  float: right;
  padding: 0 7px;
  color: white;
  border-radius: 3px;
  height: 24px;
  background-color: map-get($colors, royal);
  &:hover {
    cursor: default;
    background-color: color.adjust(map-get($colors, royal), $lightness: -15%);
    transition: background-color 300ms ease-in-out;
  }
}

.o-modal__column--main {
  @include breakpoint(xlarge up) {
    border-right: 1px solid map-get($colors, stable-light);
  }
}
.o-modal__column--scenes {
  margin-top: 20px;
  padding: 0 30px;
}
.o-modal__column--information {
  margin-top: 10px;
  padding: 0 30px;
}
.o-modal__launcher {
  max-width: 600px;
  padding: 60px;
  text-align: center;
}
.o-modal__launcher--heading {
  font-weight: 700;
}
.o-modal__launcher--heading-h3 {
  margin-top: 30px;
}
.o-modal__launcher--subtext {
  color: #999;
}

@keyframes fade-in-text {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-out-text {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.fade-in-text {
  animation-name: fade-in-text;
  animation-duration: 1000ms;
}
.fade-out-text {
  animation-name: fade-out-text;
  animation-duration: 400ms;
}
.o-modal__launcher--input-container {
  margin-top: 40px;
  height: 52px;
  & input {
    height: 100%;
    margin-right: 10px;
    font-size: 16px;
    padding-left: 18px;
    background-color: #eee;
    color: map-get($colors, stable-dark);
    &::placeholder {
      color: #999;
    }
  }
  & button {
    height: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }
}
