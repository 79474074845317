.c-modal:not(.c-modal--fluid) {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  position: relative;
}

.c-modal__fluid-wrapper {
  padding: 25px 30px;
}

.c-modal__content {
  @media #{$small-only} {
    width: 100%;
  }
}

.c-modal__close {
  position: absolute;
  top: 30px;
  right: 30px;
  height: 50px;
  width: 30px;
  background: url('../../images/modal-close.svg') no-repeat 50% 50%;
  text-indent: -999em;
  outline: none;
}

/**
 * Wide modifier
 */

.c-modal--wide {
  align-items: flex-start;
}

.c-modal--wide .c-modal__content {
  padding: $gutter*2 0;
  max-width: 1170px;
  width: 100%;
}

/* Chrome adds outlines to selected elements */
#modal {
  outline: none !important;
}
