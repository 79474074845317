/**
 * Select object
 */

.o-select {
  background-image: url('../../images/chevron-down.svg');
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: auto;
  background-color: map-get($colors, stable-lighter);
  border: none;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  padding: $gutter*0.33 $gutter $gutter*0.33 $gutter*0.5;
  border-radius: $border-radius;
  min-height: $input-height;
  width: 100%;
  letter-spacing: $letter-spacing;
  font-size: $input-font-size;
  font-weight: 500;

  &:focus,
  &.show-active {
    background-color: map-get($colors, stable-lighter);
    border: none;
    box-shadow: none;
  }
}

.o-select--button,
.o-select--button:hover {
  color: map-get($colors, dark);
}

select.o-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 0;
}
