/**
 * Alignment
 */

.u-align-center {
  text-align: center;
}

.u-align-right {
  text-align: right;
}

.u-align-top {
  vertical-align: top;
}

.u-pull-right {
  float: right;
}

.u-pull-left {
  float: left;
}

.u-no-wrap {
  white-space: nowrap;
}

.u-truncate {
  vertical-align: middle;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.u-capitalize {
  text-transform: capitalize;
}


.u-uppercase-first::first-letter {
  text-transform: uppercase;
}

.u-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 30px;
}

.u-ib {
  display: inline-block !important;
}

/**
 * Colors
 */

.u-color-assertive {
  color: map-get($colors, assertive);
}

.u-color-royal {
  color: map-get($colors, royal);
}

.u-color-stable-light {
  color: map-get($colors, stable-light);
}

.u-color-stable {
  color: map-get($colors, stable);
}

.u-color-stable-dark {
  color: map-get($colors, stable-dark);
}

.u-color-dark {
  color: map-get($colors, dark);

  svg g {
    fill: map-get($colors, dark);
  }
}

.u-bg-light {
  background-color: map-get($colors, light) !important;
}

.u-bg-dark {
  background-color: map-get($colors, dark) !important;
}

.u-expand {
  width: 100%;
}

/**
 * Block link
 */

.u-block-link {
  display: inline-flex;
  font-size: map-get($fonts, xs);
  text-decoration: underline;
  color: map-get($colors, stable-dark);
  height: 40px; // same height as button
  justify-content: center;
  align-items: center;
  vertical-align: top;
  margin: 0 10px;

  &:hover {
    color: map-get($colors, assertive);
  }
}

/**
 * Border
 */

.u-border-stable {
  border: 1px solid map-get($colors, stable);
}

/**
 * Style
 */

.u-bold {
  font-weight: 700;
}

/**
 * Cursor
 */

.u-clickable {
  cursor: pointer;
}
