/**
 * Avatar object
 */

.o-avatar__item {
  display: flex;
  height: 100%;
  border-left: 1px solid map-get($colors, stable-light);
  justify-content: center;
  align-content: center;
  padding-left: 15px;
  text-indent: -9999px;
}
.o-avatar {
  display: block;
  align-self: center;
  background-position: center;
  background-size: cover;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  padding-left: 15px;
  white-space: nowrap;
}

.o-avatar--large {
  height: 130px;
  width: 130px;
}

.o-avatar--help {
  width: 0px !important;
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
  padding: 0 33px;
}

.o-avatar--notification {
  width: 0px !important;
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
  padding: 0 33px;
  text-indent: 0;
}

.o-avatar--info {
  background-size: auto;
  background-color: map-get($colors, light);
  background-repeat: no-repeat;
}

.o-avatar--not-finished {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    right: -1px;
    top: -2px;
    width: 12px;
    height: 12px;
    background-color: map-get($colors, energized);
    border: 1px solid map-get($colors, light);
    border-radius: 12px;
  }
}
