// prefix helpers

@mixin keyframes( $animation-name ) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation( $str ) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

@mixin transition($values...) {
  $transitions: ();
  @each $declaration in $values {
    $prop: nth($declaration, 1);
    $prop-opts: ();
    $length: length($declaration);
    @for $i from 2 through $length {
      $prop-opts: append($prop-opts, nth($declaration, $i));
    }
    $trans: ($prop, $prop-opts);
    $transitions: append($transitions, $trans, comma);
  }
  -webkit-transition: trans-prefix($transitions, webkit);
  -moz-transition: trans-prefix($transitions, moz);
  -o-transition: trans-prefix($transitions, o);
  transition: $values;
}

@mixin transform( $args... ) {
  -webkit-transform: $args;
  -moz-transform: $args;
  -ms-transform: $args;
  -o-transform: $args;
  transform: $args;
}
