/**
 * Container component
 */

.c-container {
  @media #{$medium-up} {
    display: flex;
    min-height: 100vh;
  }
}

.c-container--vertical {
  flex-direction: column;
}

/**
 * Content element
 */

.c-container__content {
  position: relative;

  @media #{$small-only} {
    padding: 40px 20px;
  }

  @media #{$medium-up} {
    display: flex;
    flex-grow: 1;
  }
}

.c-container--horizontal .c-container__content {
  @media #{$medium-up} {
    align-items: center;
    justify-content: center;
  }
}

/**
 * Aside element
 */

.c-container__aside {
  @media #{$small-only} {
    margin-top: 40px;
  }

  @media #{$medium-up} {
    align-items: center;
    display: flex;
    position: absolute;
    top: 40px;
    right: 15px;
  }

  @media #{$large-up} {
    right: 60px;
  }
}

.c-container__aside-content {
  @include paragraph;
  display: block;
  margin: 0 0 10px 0;

  @media #{$medium-up} {
    display: inline;
    margin: 0 10px 0 0;
  }

  @media #{$large-up} {
    margin: 0 25px 0 0;
  }
}
