@use 'sass:color';

/**
 * Button object
 */
.o-button {
  @include transition(background-color 0.3s $smooth-ease);
  font-family: $font-stack;
  font-weight: 500;
  display: inline-flex;
  background-color: map-get($colors, assertive);
  border: 1px solid map-get($colors, assertive);
  border-radius: $border-radius;
  color: map-get($colors, light);
  font-size: map-get($fonts, m);
  min-height: $input-height;
  line-height: 1.1;
  padding: 10px 15px;
  text-decoration: none;
  outline: 0;
  min-width: 120px;
  vertical-align: top;
  letter-spacing: $letter-spacing;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;

  @media #{$small-only} {
    width: 100%;
  }

  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }

  &:hover:not(.o-button--no-hover),
  &:active:not(.o-button--no-active) {
    color: map-get($colors, light);
    text-decoration: none;
  }

  &:focus {
    border-color: map-get($colors, dark);
    color: map-get($colors, light);
  }

  &:hover:not(.o-button--no-hover) {
    background-color: color.adjust(map-get($colors, assertive), $lightness: -10%);
    border-color: color.adjust(map-get($colors, assertive), $lightness: -10%);
  }

  &:active:not(.o-button--no-active) {
    background-color: color.adjust(map-get($colors, assertive), $lightness: -25%);
    border-color: color.adjust(map-get($colors, assertive), $lightness: -25%);
  }

  &.o-button--no-transition {
    transition: none;
  }
}

.o-button__video {
  margin-right: 10px;
}

.o-button--square-small {
  min-width: 0;
  min-height: 0;
  padding: 0;
  width: 32px;
  height: 32px;
}

.o-button-group .o-button--square-small {
  width: 42px;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.o-button--secondary {
  background-color: transparent;
  border-color: map-get($colors, stable-light);
  color: map-get($colors, stable-dark);
  font-size: map-get($fonts, s);

  &[disabled] {
    background-color: transparent;
    opacity: 0.5;
  }

  &:hover:not(.o-button--no-hover) {
    background-color: map-get($colors, stable-light);
    border-color: map-get($colors, stable-dark);
    color: map-get($colors, stable-dark);
  }

  &:focus {
    color: map-get($colors, stable-dark);
  }

  &:active:not(.o-button--no-active),
  &.active {
    background-color: color.adjust(map-get($colors, assertive), $lightness: 45%);
    border-color: map-get($colors, assertive);
    color: map-get($colors, assertive);
  }
}

.o-button--success {
  background-color: map-get($colors, success);
  border-color: map-get($colors, success);

  &[disabled] {
    opacity: 0.5;
  }

  &:hover:not(.o-button--no-hover) {
    background-color: map-get($colors, success);
    border-color: map-get($colors, success);
  }

  &:focus {
    border-color: map-get($colors, success);
  }

  &:active:not(.o-button--no-active),
  &.active {
    background-color: color.adjust(map-get($colors, success), $lightness: 15%);
    border-color: map-get($colors, success);
  }
}

.o-button--royal {
  background-color: map-get($colors, royal);
  border-color: map-get($colors, royal);

  &[disabled] {
    opacity: 0.5;
  }

  &:hover:not(.o-button--no-hover) {
    background-color: map-get($colors, royal);
    border-color: map-get($colors, royal);
  }

  &:focus {
    border-color: map-get($colors, royal);
  }

  &:active:not(.o-button--no-active),
  &.active {
    background-color: color.adjust(map-get($colors, royal), $lightness: 15%);
    border-color: map-get($colors, royal);
  }
}


.o-button--delete {
  background-color: transparent;
  border-color: map-get($colors, assertive);
  color: map-get($colors, assertive);
  font-size: map-get($fonts, s);

  &[disabled] {
    background-color: transparent;
    opacity: 0.5;
  }

  &:hover:not(.o-button--no-hover) {
    background-color: color.adjust(map-get($colors, assertive), $lightness: 47%);
    border-color: map-get($colors, assertive);
    color: map-get($colors, assertive);
  }

  &:focus {
    color: map-get($colors, assertive);
  }

  &:active:not(.o-button--no-active) {
    background-color: map-get($colors, stable);
    border-color: map-get($colors, assertive);
    color: map-get($colors, assertive);
  }
}

.o-button--auto-width {
  min-width: 0 !important;
  width: auto;
}

.o-button--auto-height {
  min-height: 0 !important;
  height: auto;
}

/**
 * Hollow button
 */

.o-button--loading:before {
  content: '';
  background-image: url('../../images/load.gif');
  background-position: 66% 45%;
  background-size: 70px 35px;
  display: inline-block;
  width: 0;
  height: 18px;
  opacity: 0;
}

[disabled].o-button--loading:before {
  width: 35px;
  opacity: 1;
  transition:
    width 0.25s $smooth-ease,
    opacity 0.25s $smooth-ease;
}

// disable the button after
[disabled].o-button--loading+.o-button--disable-when-loading {
  pointer-events: none;
  opacity: 0.5;
}

/**
 * Hollow button
 */

.o-button--hollow {
  background-color: transparent;
  border-color: map-get($colors, stable-light);
  color: map-get($colors, stable-dark);

  &:hover:not(.o-button--no-hover) {
    background-color: map-get($colors, stable-lighter);
    border-color: map-get($colors, stable-light);
    color: map-get($colors, stable-dark);
  }

  &:focus {
    color: map-get($colors, stable-dark);
  }
}

// Button group
.o-button-group {
  @include breakpoint(small only) {
    margin-bottom: 10px;
  }

  margin-bottom: 0px !important;
  float: right;

  &>button:first-child,
  &>a:first-child {
    &:not(:last-child) {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }

    &:not(:hover) {
      border-left-color: map-get($colors, stable-light) !important;
    }

    &:has(+ button),
    &:has(+ a) {
      border-right-color: map-get($colors, stable-light) !important;
    }
  }

  &>*:first-child {

    &:not(:last-child)>button,
    &:not(:last-child)>a {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }

    &>button,
    &>a {
      border-left-color: map-get($colors, stable-light) !important;
    }

    &:has(+ *>button),
    &:has(+ *>a) {
      border-right-color: map-get($colors, stable-light) !important;
    }
  }

  &>*:last-child:not(:first-child)>button,
  &>*:last-child:not(:first-child)>a,
  &>button:last-child:not(:first-child),
  &>a:last-child:not(:first-child),
  .o-button-group--last {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;

    &:not(.active):not(:hover):not(:has(+ .dropdown-pane:hover)) {
      border-left-color: transparent !important;
    }
  }

  &>button {
    &:not(:last-child) {
      position: relative;
    }

    &:hover,
    &.active {
      position: relative;
      z-index: 2;
    }
  }

  & button,
  & a {
    &:focus {
      outline: none;
    }
  }

}

/**
 * Dark Light button
 */

.o-button--light {
  @include transition(all 0.2s $smooth-ease);
  background-color: map-get($colors, light) !important;
  border-color: map-get($colors, stable-light) !important;
  color: map-get($colors, stable-dark);

  &:hover:not(.o-button--no-hover):not(.active) {
    background-color: map-get($colors, light) !important;
    border-color: map-get($colors, dark-light) !important;
    color: map-get($colors, dark-light);


    svg g,
    svg path {
      fill: map-get($colors, dark-light) !important;
      @include transition(fill 0.3s $smooth-ease);
    }
  }


  &:focus,
  :active {
    color: map-get($colors, dark-light);
  }
}

// Add border-left-color for adjacent buttons inside .o-button-group
.o-button-group .o-button--light:hover:not(.o-button--no-hover)+.o-button--light:not(:hover):not(:has(+ .dropdown-pane:hover)) {
  border-left-color: map-get($colors, dark-light) !important;
}

.o-button-group>button.o-button--light.active+.o-button--light:not(.active):not(:hover):not(:has(+ .dropdown-pane:hover)) {
  border-left-color: map-get($colors, assertive) !important;
}

.o-button--light:has(+ .dropdown-pane:hover),
.o-button-group:has(+ .dropdown-pane:hover)>.o-button--light {
  border-color: map-get($colors, dark-light) !important;
  color: map-get($colors, dark-light);
  border-left-width: 1px !important;

  svg g,
  svg path {
    fill: map-get($colors, dark-light) !important;
  }
}

/**
 * Same clickable area as normal button, but shown as normal link
 */

.o-button--blank {
  background-color: transparent;
  border-color: transparent;
  min-width: 0;
  color: map-get($colors, dark);

  &:hover:not(.o-button--no-hover) {
    background-color: transparent;
    border-color: transparent;
  }

  svg {
    vertical-align: middle;
  }

  svg g,
  svg path {
    fill: map-get($colors, stable-dark);
    @include transition(fill 0.3s $smooth-ease);
  }

  &:hover:not(.o-button--no-hover) svg g,
  &:hover:not(.o-button--no-hover) svg path {
    fill: map-get($colors, dark);
  }
}

.o-button--flat {
  background-color: transparent;
  border-color: transparent;
  padding: 0;
  min-width: 0;
  color: map-get($colors, assertive);
  font-size: rem-calc(14);
  margin: 0;

  &[disabled],
  &:hover:not(.o-button--no-hover),
  &:active:not(.o-button--no-active),
  &:focus {
    background-color: transparent;
    border-color: transparent;
    color: color.adjust(map-get($colors, assertive), $lightness: -10%);
  }
}


.o-button__submit--hidden {
  display: none;
}

.o-button__copy--container {
  position: relative;
  margin-left: 0.5rem;

  button {
    display: none !important;
    margin-bottom: 0;
    height: auto;
    padding: 0.5rem;
    align-items: center;
    justify-content: center;
  }

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    opacity: 0;
    margin: 0;
    cursor: pointer;

    &~button {
      align-items: center;
    }

    &:hover~button.button.secondary {
      color: map-get($colors, dark-light) !important;
      border-color: map-get($colors, dark-light);
    }

    &:not(:checked)~button.button.secondary {
      display: flex !important;
    }

    &:checked~button.button.success {
      display: flex !important;
    }
  }
}