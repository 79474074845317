.tiptap {
  outline: none !important;

  p {
    margin: 0;
    color: inherit;
    line-height: inherit;
  }

  h2 {
    line-height: 28px;
    margin-bottom: 5px;
    font-weight: 700;
  }
}


.bubble-menu .button-group {
  background: map-get($colors, light);
  border-radius: 5px;
  .button {
    margin-bottom: 0;
  }
}