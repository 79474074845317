/**

To get something like this (f.e. for team teasers, team member teasers)

+-------+
|       |
|       |   TITLE ITEM
|  img  |   Lorem ipsum dolor
|       |   sit amet.
|       |
+-------+

<div class="o-flag">
  <div class="o-flag__fix">
    <img src="" alt="This element will maintain its width" />
  </div>
  <div class="o-flag__flex">
    <p>This element will grow to fill the remaining space</p>
  </div>
</div>

*/

.o-flag {
  display: flex;
  align-items: center;
}

.o-flag--top {
  align-items: flex-start;
}

.o-flag--bottom {
  align-items: flex-end;
}

.o-flag--end {
  justify-content: flex-end;
}

.o-flag__flex,
.o-flag__fix {
  display: initial;
}

.o-flag__flex {
  min-width: 0;
  flex: 1 1 auto;
}

.o-flag__flex--shrink {
  flex-shrink: 1;
}
