/**
 * Text-input object
 */

input.o-text-input,
select.o-text-input,
textarea.o-text-input {
  background-color: map-get($colors, stable-lighter);
  border: none;
  border-radius: $border-radius;
  min-height: $input-height;
  padding-left: $form-spacing;
  padding-right: $form-spacing;
  width: 100%;
  letter-spacing: $letter-spacing;
  font-weight: 500;

  &:focus {
    background-color: map-get($colors, stable-lighter);
    border: none;
    box-shadow: none;
  }
}

input::placeholder,
textarea::placeholder {
  color: map-get($colors, stable-dark);
}

input.o-text-input--s {
  width: 250px;
}

.o-search-input--prefix {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 42px;
  height: 100%;
}

.o-search {
  height: 42px;
  margin-bottom: 0px !important;

  & input {
    font-size: 14px;
    background-color: rgba(0, 0, 0, 0) !important;
    height: 100%;
    padding-left: 0px;
    padding-right: 0px;
    letter-spacing: -0.7px;
    border: none;
  }
}
.o-search-input {
  margin-bottom: 10px !important;
  color: map-get($colors, stable-dark);
  display: inline-flex;
  height: 42px;
  width: fit-content;
  max-width: 100%;
  border-radius: $border-radius;
  background-color: white !important;
  border: 1px solid map-get($colors, stable-light);
  margin-right: 10px;
  transition: all 0.3s ease-in-out;

  @include breakpoint(medium) {
    margin-bottom: 0px !important;
    float: left;
  }
  &:focus-within {
    border: 1px solid map-get($colors, dark-light);
    color: map-get($colors, dark-light);
  }
  &:hover {
    border: 1px solid map-get($colors, dark-light);
    color: map-get($colors, dark-light);
  }
}
.o-search-input--container {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 250px;
  overflow: hidden;
  transition: width 0.3s ease-in-out;
}
.o-search-input--inline-suffix {
  width: 42px;
  display: grid;
  place-items: center;
  font-size: 14px;
  overflow: hidden;
  color: map-get($colors, stable-dark);
  transition: color 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    color: map-get($colors, dark-light);
  }
}
input.no-arrows {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
}
.o-inline-input {
  & input {
    height: auto;
    width: auto;
    background-color: #fff;
    color: map-get($colors, stable-dark);
    border: none;
    font-size: 14px;
    padding: 0;
    margin: 0;
    &:hover,
    &:focus {
      background-color: #fff;
    }
  }

  & input::-webkit-outer-spin-button,
  & input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  & input[type='number'] {
    -moz-appearance: textfield;
  }
}
.o-input-group-label {
  background-color: white;
  border: 1px solid #eee !important;
  color: map-get($colors, stable-dark);
}
