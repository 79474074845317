/**
 * Textarea object
 */

textarea.o-textarea {
  background-color: map-get($colors, stable-lighter);
  border: none;
  border-radius: $border-radius;
  min-height: 80px;
  padding: $form-spacing;
  width: 100%;
  letter-spacing: $letter-spacing;
  font-weight: 500;

  &:focus {
    background-color: map-get($colors, stable-light);
    border: none;
    box-shadow: none;
  }
}
