/**
 * Spacing
 */
 
$gutter: map-get($grid-column-gutter, medium);

@each $size in $breakpoint-classes {

  @include breakpoint($size) {

    .u-#{$size}-no-m{ margin: 0 !important; }
    .u-#{$size}-no-ml{ margin-left: 0 !important; }
    .u-#{$size}-no-mr{ margin-right: 0 !important; }
    .u-#{$size}-no-mt{ margin-top: 0 !important; }
    .u-#{$size}-no-mb{ margin-bottom: 0 !important; }

    .u-#{$size}-mts { margin-top: $gutter*0.16 !important; }
    .u-#{$size}-mrs { margin-right: $gutter*0.16 !important; }
    .u-#{$size}-mbs { margin-bottom: $gutter*0.16 !important; }
    .u-#{$size}-mls { margin-left: $gutter*0.16 !important; }
    .u-#{$size}-mas { margin: $gutter*0.16 !important; }

    .u-#{$size}-mtm { margin-top: $gutter*0.33 !important; }
    .u-#{$size}-mrm { margin-right: $gutter*0.33 !important; }
    .u-#{$size}-mbm { margin-bottom: $gutter*0.33 !important; }
    .u-#{$size}-mlm { margin-left: $gutter*0.33 !important; }
    .u-#{$size}-mam { margin: $gutter*0.33 !important; }

    .u-#{$size}-mtl { margin-top: $gutter*0.5  !important; }
    .u-#{$size}-mrl { margin-right: $gutter*0.5  !important; }
    .u-#{$size}-mbl { margin-bottom: $gutter*0.5  !important; }
    .u-#{$size}-mll { margin-left: $gutter*0.5  !important; }
    .u-#{$size}-mal { margin: $gutter*0.5  !important; }

    .u-#{$size}-mtxl { margin-top: $gutter*0.66 !important; }
    .u-#{$size}-mrxl { margin-right: $gutter*0.66 !important; }
    .u-#{$size}-mbxl { margin-bottom: $gutter*0.66 !important; }
    .u-#{$size}-mlxl { margin-left: $gutter*0.66 !important; }
    .u-#{$size}-maxl { margin: $gutter*0.66 !important; }

    .u-#{$size}-mtxxl { margin-top: $gutter !important; }
    .u-#{$size}-mrxxl { margin-right: $gutter !important; }
    .u-#{$size}-mbxxl { margin-bottom: $gutter !important; }
    .u-#{$size}-mlxxl { margin-left: $gutter !important; }
    .u-#{$size}-maxxl { margin: $gutter !important; }

    .u-#{$size}-no-p{ padding: 0 !important; }
    .u-#{$size}-no-pt{ padding-top: 0 !important; }
    .u-#{$size}-no-pr{ padding-right: 0 !important; }
    .u-#{$size}-no-pb{ padding-bottom: 0 !important; }
    .u-#{$size}-no-pl{ padding-left: 0 !important; }

    .u-#{$size}-pts { padding-top: $gutter*0.16 !important; }
    .u-#{$size}-prs { padding-right: $gutter*0.16 !important; }
    .u-#{$size}-pbs { padding-bottom: $gutter*0.16 !important; }
    .u-#{$size}-pls { padding-left: $gutter*0.16 !important; }
    .u-#{$size}-pas { padding: $gutter*0.16 !important; }

    .u-#{$size}-ptm { padding-top: $gutter*0.33 !important; }
    .u-#{$size}-prm { padding-right: $gutter*0.33 !important; }
    .u-#{$size}-pbm { padding-bottom: $gutter*0.33 !important; }
    .u-#{$size}-plm { padding-left: $gutter*0.33 !important; }
    .u-#{$size}-pam { padding: $gutter*0.33 !important; }

    .u-#{$size}-ptl { padding-top: $gutter*0.5 !important; }
    .u-#{$size}-prl { padding-right: $gutter*0.5 !important; }
    .u-#{$size}-pbl { padding-bottom: $gutter*0.5 !important; }
    .u-#{$size}-pll { padding-left: $gutter*0.5 !important; }
    .u-#{$size}-pal { padding: $gutter*0.5 !important; }

    .u-#{$size}-ptxl { padding-top: $gutter*0.66 !important; }
    .u-#{$size}-prxl { padding-right: $gutter*0.66 !important; }
    .u-#{$size}-pbxl { padding-bottom: $gutter*0.66 !important; }
    .u-#{$size}-plxl { padding-left: $gutter*0.66 !important; }
    .u-#{$size}-paxl { padding: $gutter*0.66 !important; }

    .u-#{$size}-ptxxl { padding-top: $gutter !important; }
    .u-#{$size}-prxxl { padding-right: $gutter !important; }
    .u-#{$size}-pbxxl { padding-bottom: $gutter !important; }
    .u-#{$size}-plxxl { padding-left: $gutter !important; }
    .u-#{$size}-paxxl { padding: $gutter !important; }

  }

}
