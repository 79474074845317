/**
 * Dropdown object
 */

$dropdown-item-padding: 10px 20px;

.o-dropdown {
  @include box-shadow(0px 5px 10px 0px rgba(0, 0, 0, 0.1));
  @include noselect;
  background-color: map-get($colors, light);
  border: 1px solid map-get($colors, stable);
  border-radius: $border-radius;
  list-style: none;
  margin: 0;
  padding: 10px 0;
  position: relative;
  text-align: left;

  &:not(.o-dropdown--no-arrow):before {
    content: url('../../images/dropdown-arrow.svg');
    position: absolute;
    top: -17px;
    left: 25px;
  }
}

.o-dropdown--left:not(.o-dropdown--no-arrow):before {
  left: 25px;
}

.o-dropdown--right:not(.o-dropdown--no-arrow):before {
  left: auto;
  right: 10px;
}

.o-dropdown__item--open-dropdown>.o-dropdown {
  visibility: hidden;
  transition: visibility 0s;
  transition-delay: 0.5s;
}

.o-dropdown__item--open-dropdown:hover>.o-dropdown {
  visibility: visible;
  transition: visibility 0s;
  transition-delay: 0s;
}

.o-dropdown--closed {
  visibility: hidden;
  transition: visibility 0s;
  transition-delay: 0.5s;
}

.o-dropdown--open {
  visibility: visible;
  transition: visibility 0s;
  transition-delay: 0s;
}

/**
 * Item element
 */

.o-dropdown__item {
  padding: 8px 20px;
}

.o-dropdown__item--inactive {
  pointer-events: none;
}

.o-dropdown__item--label {
  @include paragraph;
  color: map-get($colors, stable);
  padding: $dropdown-item-padding;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.o-dropdown__item--shuffle {
  &:before {
    content: url('../../images/icon-shuffle.svg');
    margin-right: 10px;
    position: relative;
    top: 3px;
  }
}

.o-dropdown__item--label-multi-line {
  @include paragraph;
  color: map-get($colors, stable);
  padding: $dropdown-item-padding;
}

/**
 * Link element
 */

.o-dropdown__link {
  @include paragraph;
  @include transition(background-color 0.3s $smooth-ease);
  background-color: transparent;
  display: block;
  padding: $dropdown-item-padding;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  svg {
    fill: map-get($colors, stable-dark);
    margin-right: 5px;
  }
}

.o-dropdown__link--label {
  color: map-get($colors, stable);
}

.o-dropdown__item:hover>a.o-dropdown__link,
.o-dropdown__item--hover>.o-dropdown__link,
.o-dropdown__item:hover>span.o-dropdown__link {
  background-color: map-get($colors, stable-lighter);
  color: map-get($colors, stable-dark);
  cursor: pointer;
  text-decoration: none;
}

.o-dropdown__item--active .o-dropdown__link,
.o-dropdown__item--active:hover>a.o-dropdown__link,
.o-dropdown__item--active:hover>span.o-dropdown__link,
.o-dropdown__link--active {
  color: map-get($colors, dark);

  svg {
    fill: map-get($colors, dark);
  }
}

.o-dropdown__item--active .o-dropdown .o-dropdown__link {
  color: map-get($colors, stable-dark);
}

.o-dropdown__item .o-dropdown .o-dropdown__link--label {
  color: map-get($colors, stable);
}

.o-dropdown__link--inactive {
  color: map-get($colors, stable);

  &:hover {
    background-color: transparent;
    color: map-get($colors, stable);
    cursor: default;
  }
}

.o-dropdown__link--flag {
  svg {
    margin-bottom: -5px;
  }
}

/**
 * Link aside element
 */

.o-dropdown__link-aside {
  color: map-get($colors, dark);
  float: right;

  &:after {
    content: url('../../images/chevron-right.svg');
    margin-left: 10px;
    position: relative;
    top: 3px;
  }
}

.o-dropdown__link-aside--assertive {
  color: map-get($colors, assertive);
}

/**
 * More aside element
 */
.o-dropdown__link-more {
  color: map-get($colors, dark);
  float: right;

  &:after {
    content: url('../../images/icon-dots.svg');
    margin-left: 10px;
    position: relative;
    top: 0px;
  }
}

/**
 * Divider element
 */

.o-dropdown__divider {
  background-color: map-get($colors, stable);
  display: block;
  height: 1px;
  margin: 10px 0;
  width: 100%;
}

/**
 * Dropdowns in dropdowns
 * Relies heavily on Foundation's dropdown
 * 1 - move arrow to the left/right side only if dropdown opens to the left/right
 * 2 - add some spacing between dropdowns
 */

.o-dropdown .o-dropdown {
  position: absolute;
  border: 1px solid map-get($colors, stable);
}

/* 1 */

.right>.o-dropdown:before,
.opens-right>.o-dropdown:before {
  left: -20px;
  top: 10px;
  transform: rotate(-90deg);
}

.left>.o-dropdown:before,
.opens-left>.o-dropdown:before {
  left: auto;
  right: -19px;
  top: 10px;
  transform: rotate(90deg);
}

/* 2 */

$dropdown-in-dropdown--spacing: 5px;

.o-dropdown .opens-right .o-dropdown {
  left: calc(100% + #{$dropdown-in-dropdown--spacing});
}

.o-dropdown .opens-left .o-dropdown {
  right: calc(100% + #{$dropdown-in-dropdown--spacing});
}

/**
 * Search element
 */

.o-dropdown--search {
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  width: 230px;
}

.o-dropdown--search::before {
  top: -15px;
}

.o-dropdown--search:empty {
  display: none !important;
}

.o-dropdown__custom {
  @include breakpoint(small only) {
    margin-bottom: 10px;
  }
}

.o-dropdown__custom--hover {
  & li.is-active>button {
    transition: all 0.3s ease-in-out;
    border: 1px solid map-get($colors, dark) !important;
    color: map-get($colors, dark-light) !important;

    &>* {
      color: map-get($colors, dark-light) !important;
    }
  }
}

.o-dropdown__custom--scroll>* {
  height: 250px;
  overflow-y: auto;
  width: 100%;
  margin-bottom: 2rem;
}

.o-dropdown__search--outer {
  display: block;
  width: 100%;
  position: relative;
}

.o-dropdown__search--container {
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 10px;
  width: 540px;
  max-height: 290px;
  margin-bottom: 2rem;
  background-color: map-get($colors, light);
  border-radius: 5px;
  border: 1px solid map-get($colors, stable);
  z-index: 1007;

  &.arrow-left,
  &.arrow-bottom {
    left: 8px;

    &:before {
      content: url('../../images/dropdown-arrow.svg');
      position: absolute;
      top: -17px;
      left: 25px;
    }
  }

  &.arrow-right {
    right: 8px;

    &:before {
      content: url('../../images/dropdown-arrow.svg');
      position: absolute;
      top: -14px;
      left: auto;
      right: 11px;
    }
  }
}

ul.o-dropdown__list .o-dropdown__search--container.arrow-left:before {
  top: 12px;
  left: -20px;
  transform: rotate(-90deg);
}

.o-dropdown__search--input {
  display: block;
  padding: 5px 0px;
  width: 100%;
  color: black;
  position: sticky;
  border-bottom: 1px solid map-get($colors, stable);

  & .o-search-input {
    width: 100% !important;
  }
}

.o-dropdown__search--input--prefix {
  color: black;
}

.o-dropdown__search--empty--container {
  display: grid;
  place-items: center;
  height: 100%;
}

.o-dropdown__search--list {
  padding: 10px 0px;
  overflow-y: auto;
  height: calc(100% - 55px);

  & .loading__container {
    height: 100% !important;
  }
}

.o-dropdown__search--list--item {
  display: flex;
  align-items: center;
  padding: 8px 24px;
  position: relative;

  &:hover {
    background-color: rgba(231, 70, 26, 0.05);
    outline: none;
  }

  &:focus,
  &.focus {
    background-color: rgba(231, 70, 26, 0.15);
    outline: none;
  }

  &:not(:first-child) {
    padding-top: 10px;
  }

  &>div {
    width: 100%;
  }

  & .button-only {
    visibility: hidden;
    font-weight: bold;
    font-size: 14px;
  }

  &:hover .button-only {
    visibility: visible;
  }
}

.o-dropdown__list {
  position: relative;
  background-color: #fff;
  top: 4px;
  left: -15px;
  border: 1px solid map-get($colors, stable);
  list-style: none !important;
  margin: 0;
  margin-bottom: 20px;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0px 5px 10px 0px rgb(0 0 0 / 10%);
}

ul.o-dropdown .o-dropdown__list {
  left: 0;
}

.o-dropdown__arrow {
  content: url('../../images/dropdown-arrow.svg');
  position: absolute;
  top: -14px;
  left: 25px;
}

.o-dropdown__list-item {
  cursor: pointer;
  font-size: 14px;
  padding: 8px 20px;
  transition: all 100ms ease-in-out;

  &.disabled {
    color: #93969f;
    pointer-events: none;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1) !important;
    }
  }

  &.bg-success {
    transition: all 100ms ease-in-out;

    &:hover {
      background-color: rgba(112, 200, 184, 0.15) !important;
    }
  }
}

li.o-dropdown__list-item:hover {
  background-color: rgba(231, 70, 26, 0.15);
}

.o-dropdown__list-item:has(> .o-dropdown__link),
.o-dropdown__item:has(.o-dropdown__link) {
  padding: 0;
}

.o-dropdown__search--footer {
  padding: 10px 20px;
  display: flex;
  align-items: center;
}