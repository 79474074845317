/**
 * panel component
 */

.c-panel {
  @include box-shadow(0px 3px 0px 0px rgba(0, 0, 0, 0.08));
  @include clearfix;
  background-color: map-get($colors, light);
  border: 1px solid map-get($colors, stable);
  border-radius: $border-radius;
  display: block;
  padding: $gutter;
  position: relative;
}

.c-panel--hollow {
  @include box-shadow(none);
  background-color: transparent;
}

/**
 * Content element
 */

.c-panel__content {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  table {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.c-panel__content--assertive {
  a {
    color: map-get($colors, assertive);
    &:hover {
      color: map-get($colors, balanced);
    }
  }
}

/**
 * Panel element
 */

.c-panel__content--image {
  position: relative;
  width: 100%;
  & img {
    width: 100%;
    height: 400px;
  }
  & .o-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: RGBA(38, 39, 44, 0.8);
    color: white;
    font-size: 16px;
    padding: 12px 24px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    &:hover {
      background-color: RGBA(38, 39, 44, 1);
    }
  }
}
.c-panel__panel {
  @include paragraph;
  background-color: map-get($colors, stable-lighter);
  border-radius: 6px;
  color: map-get($colors, dark);
  margin-top: 20px;
  padding: 20px;
}

/**
 * inline title element
 */

.c-panel__inline-title {
  display: inline;
}

.c-panel__inline-title svg {
  margin-right: 3px;
  position: relative;
  top: 1px;
}

.c-panel__inline-title small {
  @include paragraph;
}

.c-panel__inline-title + a.o-button {
  margin: 0 0 -16px 20px;
  position: relative;
  top: -8px;
}

/**
 * Action list element
 */

.c-panel__action-list {
  list-style: none;
  margin: 0;
  padding-left: 0;
  position: absolute;
  top: $gutter;
  right: $gutter;
}

.c-panel__action-list-item {
  margin-top: -1em;
  margin-right: -1em;
}

/**
 * Aside element
 */

.c-panel__aside {
  @include paragraph;
  color: map-get($colors, stable-dark);
  text-align: right;
  position: absolute;
  right: $gutter;
  top: $gutter;

  svg path {
    fill: map-get($colors, stable-dark);
  }
}

.c-panel__aside--assertive {
  @include paragraph;
  color: map-get($colors, assertive);
  text-align: right;
  position: absolute;
  right: $gutter;
  top: $gutter;

  svg path {
    fill: map-get($colors, assertive);
  }
}

/**
 * Color
 */

.c-panel__color {
  @include aspect-ratio(1, 1);
  border: 1px solid map-get($colors, stable);
  position: relative;
}

.c-panel__color-content {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  width: 100%;
  text-align: center;
}

.c-panel__small-right {
  float: right;
  margin-right: 40px;
  margin-top: -5px;
}
